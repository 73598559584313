<!-- <ion-header class="start-page">
  <ion-toolbar>
    <button ion-button menuToggle>
      <ion-icon name="menu"></ion-icon>
    </button>
    <ion-title>{{ "pages.translation.verification" | translate }}</ion-title>
    <ion-title>{{ "pages.translation.verification" | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="start-page">
  <div class="login-contect">
    <p class="reset-p-margin"><img src="assets/imgs/image_tick.png" class="ms-tick"/>{{
      "pages.translation.verificationInstruction" | translate }}</p>

    <p class="btn-login-restpw_in">{{ "pages.translation.verificationNoEmail" | translate }}</p>

    <button (click)="resendPassword()" class="btn-login-restpw">{{ "pages.translation.verificationResentEmail" |
      translate }}
    </button>

  </div>


</ion-content> -->


<!-- <ion-header no-border>
    <ion-toolbar color="background-color">
        <ion-buttons slot="start">
            <ion-button appThrottleClick (throttleClick)="backButtonClick()" class="back-btn AQA-back-btn">
                <ion-icon name="arrow-back"></ion-icon>
                <p ion-text color="dark">{{"global.previous" | translate}}</p>
            </ion-button>
        </ion-buttons>
        <ion-title></ion-title>
    </ion-toolbar>
</ion-header> -->

<ion-header class="order-header" *ngIf="!hideHeader">
    <ion-toolbar>
        <ion-buttons slot="start">
            <custom-back-button (backButtonClick)="backButtonClick()"></custom-back-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content>

    <div *ngIf="isForgotPassword && !sent" class="with-segment animated fadeInRights">

        <p class="page-title">{{"pages.reset-password.receive-message" | translate}}</p>
        <!--    <p *ngIf="hasCrmId" class="page-title">{{"pages.reset-password.receive-email-message" | translate}}</p>-->

        <div class="cus-segment-wrapper">
            <ion-button fill="clear" disable-hover (click)="selectMode('email')"
                        [ngClass]="{'isActive': mode == 'email'}">
                {{"pages.reset-password.email" | translate}}
            </ion-button>


            <ion-button fill="clear" disable-hover (click)="selectMode('phone')"
                        [ngClass]="{'isActive': mode == 'phone'}">
                <ion-icon name="ios-phone-portrait"></ion-icon>
                {{"pages.reset-password.phone" | translate}}
            </ion-button>


        </div>

        <ng-container *ngIf="mode == 'phone'">
            <div class="form-box phone-input-box">
                <ion-item class="phone-box single-item std-input-item">
                    <ng-container *ngIf="countryCodes.length > 0; else elseTemplate">
                        <ion-select [(ngModel)]="countryCallingCode" name="countryCallingCode"
                            class="countryCode" (click)="changeCountryCode()">
                            <ion-select-option *ngFor="let country of countryCodes" [value]="country.code">
                                {{country.code}}
                            </ion-select-option>
                        </ion-select>
                    </ng-container>
                    <ng-template #elseTemplate>
                        <!-- 防止数据未到ion-select-option出空value -->
                    </ng-template>
                    <ion-input class="std-input" type="tel" clearInput="true" [(ngModel)]="phone">
                    </ion-input>

                    <!-- <ion-input class="std-input" type="tel" clearInput="true" [(ngModel)]="countryCallingCode"
                               placeholder="{{showCountryCallingCode()}}"></ion-input>
                    <ion-input class="std-input" type="tel" clearInput="true" [(ngModel)]="phone"></ion-input> -->
                </ion-item>
            </div>
        </ng-container>

        <ng-container *ngIf="mode == 'email'">
            <!-- <p class="page-title">{{"pages.reset-password.email-receive-code-message" | translate}}</p> -->
            <div class="form-box">
                <ion-item class="single-item std-input-item">
                    <ion-label color="medium" position="stacked"></ion-label>
                    <ion-input class="std-input" type="email" clearInput="true" [(ngModel)]="email"></ion-input>
                </ion-item>
            </div>
        </ng-container>
        <div class="btn-box">
            <ion-button type="submit" expand="block"
                        (click)="requestResetCode()">{{"pages.reset-password.submit" |
                translate}}</ion-button>
        </div>
    </div>

    <!-- verification -->
    <div *ngIf="isForgotPassword && !submited && sent" class="animated fadeInRights">
        <p *ngIf="mode == 'phone'" class="page-title">{{"pages.reset-password.input-code-message-one" | translate}}
            +{{countryCallingCode}} {{phone}}{{"pages.reset-password.input-code-message-two"
                | translate}}</p>
        <p *ngIf="mode == 'email'" class="page-title">{{"pages.reset-password.input-code-message-one" | translate}}
            {{email}}{{"pages.reset-password.input-code-message-two"
                | translate}}</p>


        <div class="form-box">
            <ion-item class="std-input-item">
                <ion-label color="medium" position="stacked"></ion-label>
                <ion-input class="std-input" type="tel" clearInput="true" [(ngModel)]="token"></ion-input>
            </ion-item>
        </div>

        <div *ngIf="countDown > 0 && mode == 'phone'"
             class="resend-text">{{"pages.reset-password.count-down-message-one" |
            translate}}{{countDown}}{{"pages.reset-password.count-down-message-two"
            | translate}}</div>
        <div *ngIf="countDown <= 0 || mode == 'email'" (click)="clickReSend()"
             class="can-resend-text">{{"pages.reset-password.re-send-code-message"
            | translate}}</div>


        <div class="btn-box mt4">
            <ion-button type="submit"  expand="block"
                        (click)="verify()">{{"pages.reset-password.submit" | translate}}</ion-button>
        </div>
    </div>

    <div *ngIf="!isForgotPassword || (submited && sent)" class="animated fadeInRights">

        <p class="page-title">{{"pages.reset-password.reset-password-message" | translate}}</p>

        <form [formGroup]="resetPasswordForm" novalidate class="form-box">
            <div class="form-box">
                <ion-item class="std-input-item" *ngIf="!isForgotPassword" text-wrap>
                    <!-- <ion-label stacked>{{"pages.registration.password" | translate }}</ion-label> -->
                    <ion-label position="stacked">{{"pages.reset-password.old-password" | translate}}
                        <span class="error-msg c-ali"></span>
                    </ion-label>
                    <ion-input class="std-input" formControlName="Old_Password" type="password"></ion-input>
                </ion-item>
                <ion-item text-wrap  class="std-input-item">
                    <!-- <ion-label stacked>{{"pages.registration.password" | translate }}</ion-label> -->
                    <ion-label position="stacked">{{"pages.reset-password.password" | translate}}
                        <span class="error-msg c-ali"></span>
                    </ion-label>
                    <ion-input class="std-input" formControlName="Password" type="password"></ion-input>
                </ion-item>
                <ion-item text-wrap class="std-input-item">
                    <!-- <ion-label stacked>{{"pages.registration.password" | translate }}</ion-label> -->
                    <ion-label position="stacked">{{"pages.reset-password.confirm-password" | translate}}
                        <span class="error-msg c-ali"></span>
                    </ion-label>
                    <ion-input class="std-input" formControlName="Confirm_Password" type="password"></ion-input>
                </ion-item>
            </div>
        </form>


        <div class="btn-box mt4">
            <ion-button expand="block" type="submit" (click)="clickResetPassword()">{{"pages.reset-password.submit" |
                translate}}</ion-button>
        </div>
    </div>


</ion-content>


<!-- 
<div class="login-contect">

  <p class="reset-p-margin">{{ "pages.translation.verificationHint" | translate : { number: phoneNumber} }}</p>


  <ion-item>
    <ion-label stacked>{{ "pages.translation.verification_pinCode" | translate }}</ion-label>
    <ion-input type="tel" clearInput="true"></ion-input>
  </ion-item>

  <button primary expand="block" (click)="submit()" class="btn-login-signin margin-40">{{ "pages.translation.ok" | translate }}</button>


  <button (click)="resendPassword()" class="btn-login-restpw">{{ "pages.translation.verificationResentEmail" | translate }}
  </button>

</div> -->
