/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./select-store-filter.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/forms";
import * as i5 from "@angular/common";
import * as i6 from "./select-store-filter.component";
var styles_SelectStoreFilterComponent = [i0.styles];
var RenderType_SelectStoreFilterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SelectStoreFilterComponent, data: {} });
export { RenderType_SelectStoreFilterComponent as RenderType_SelectStoreFilterComponent };
function View_SelectStoreFilterComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "ion-item", [], null, null, null, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(1, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "ion-label", [["class", "filter-name"]], null, null, null, i2.View_IonLabel_0, i2.RenderType_IonLabel)), i1.ɵdid(3, 49152, null, 0, i3.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(4, 0, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, 0, 3, "ion-checkbox", [["class", "filter-check"], ["slot", "end"]], null, [[null, "ionChange"], [null, "ionBlur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ionBlur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8)._handleBlurEvent($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("ionChange" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8)._handleIonChange($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("ionChange" === en)) {
        var pd_2 = (_co.addKeywords($event, _v.context.$implicit) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_IonCheckbox_0, i2.RenderType_IonCheckbox)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.BooleanValueAccessor]), i1.ɵdid(7, 49152, null, 0, i3.IonCheckbox, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { checked: [0, "checked"], value: [1, "value"] }, null), i1.ɵdid(8, 16384, null, 0, i3.BooleanValueAccessor, [i1.ElementRef], null, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.checked; var currVal_2 = _v.context.$implicit.name; _ck(_v, 7, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_0); }); }
function View_SelectStoreFilterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "ion-list-header", [["class", "type-label"]], null, null, null, i2.View_IonListHeader_0, i2.RenderType_IonListHeader)), i1.ɵdid(2, 49152, null, 0, i3.IonListHeader, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 2, "ion-label", [], null, null, null, i2.View_IonLabel_0, i2.RenderType_IonLabel)), i1.ɵdid(4, 49152, null, 0, i3.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(5, 0, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 3, "ion-list", [["class", "type-list"]], null, null, null, i2.View_IonList_0, i2.RenderType_IonList)), i1.ɵdid(7, 49152, null, 0, i3.IonList, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SelectStoreFilterComponent_2)), i1.ɵdid(9, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit["subs"]; _ck(_v, 9, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 5, 0, currVal_0); }); }
export function View_SelectStoreFilterComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { ionOutlet: 1 }), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i5.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "height": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 9, "div", [["class", "filter-wrapper fix-bottom widthMax"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "list-header-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "list-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "ion-button", [["fill", "clear"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(8, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { fill: [0, "fill"] }, null), (_l()(), i1.ɵeld(9, 0, null, 0, 1, "ion-icon", [["color", "dark"], ["name", "ios-arrow-down"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(10, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], name: [1, "name"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "contact-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectStoreFilterComponent_1)), i1.ɵdid(13, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, "100vh"); _ck(_v, 2, 0, currVal_0); var currVal_1 = "clear"; _ck(_v, 8, 0, currVal_1); var currVal_2 = "dark"; var currVal_3 = "ios-arrow-down"; _ck(_v, 10, 0, currVal_2, currVal_3); var currVal_4 = _co.filterGroups; _ck(_v, 13, 0, currVal_4); }, null); }
export function View_SelectStoreFilterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-select-store-filter", [], null, null, null, View_SelectStoreFilterComponent_0, RenderType_SelectStoreFilterComponent)), i1.ɵdid(1, 245760, null, 0, i6.SelectStoreFilterComponent, [i1.Injector, i3.NavParams], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SelectStoreFilterComponentNgFactory = i1.ɵccf("app-select-store-filter", i6.SelectStoreFilterComponent, View_SelectStoreFilterComponent_Host_0, {}, {}, []);
export { SelectStoreFilterComponentNgFactory as SelectStoreFilterComponentNgFactory };
