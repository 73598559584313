var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { PathLocationStrategy } from '@angular/common';
var MyLocationStrategy = /** @class */ (function (_super) {
    __extends(MyLocationStrategy, _super);
    function MyLocationStrategy() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MyLocationStrategy.prototype.pushState = function (state, title, url, queryParams) {
        _super.prototype.pushState.call(this, state, title, url, queryParams);
    };
    return MyLocationStrategy;
}(PathLocationStrategy));
export { MyLocationStrategy };
