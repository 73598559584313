/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./join-or-logout-modal.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i4 from "@ionic/angular";
import * as i5 from "@angular/common";
import * as i6 from "@angular/forms";
import * as i7 from "./join-or-logout-modal.page";
import * as i8 from "../../core/services/member.service";
import * as i9 from "../../core/services/store.service";
var styles_JoinOrLogoutModal = [i0.styles];
var RenderType_JoinOrLogoutModal = i1.ɵcrt({ encapsulation: 0, styles: styles_JoinOrLogoutModal, data: {} });
export { RenderType_JoinOrLogoutModal as RenderType_JoinOrLogoutModal };
function View_JoinOrLogoutModal_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "has-tnc"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "h3", [["class", "tnc-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.justShowTC();
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(5, null, ["", "."])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("dialog.guest-login.upon-joining-tips")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("pages.login.loginTC")); _ck(_v, 5, 0, currVal_1); }); }
export function View_JoinOrLogoutModal_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { ionOutlet: 1 }), (_l()(), i1.ɵeld(1, 0, null, null, 35, "ion-content", [["class", "content-signup-or-acc"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismissClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_IonContent_0, i3.RenderType_IonContent)), i1.ɵdid(2, 49152, null, 0, i4.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), i1.ɵdid(3, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "isModalPopup": 0 }), (_l()(), i1.ɵeld(5, 0, null, 0, 31, "div", [["class", "signup-acc-height"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 30, "div", [["class", "div-signup-or-acc"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 14, "div", [["class", "div-login-signup"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.joinMembership();
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "ion-icon", [["class", "icon-login-signup"], ["src", "assets/images/join_login.svg"]], null, null, null, i3.View_IonIcon_0, i3.RenderType_IonIcon)), i1.ɵdid(9, 49152, null, 0, i4.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { src: [0, "src"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 8, "div", [["class", "div-join-store"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "h6", [], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(14, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(15, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JoinOrLogoutModal_1)), i1.ɵdid(18, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(19, 0, null, null, 2, "div", [["class", "icon-arrow-rig"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 1, "ion-icon", [["name", "ios-arrow-forward"]], null, null, null, i3.View_IonIcon_0, i3.RenderType_IonIcon)), i1.ɵdid(21, 49152, null, 0, i4.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(22, 0, null, null, 10, "div", [["class", "checkbox-row"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 6, "ion-checkbox", [["mode", "md"], ["name", "marketing"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "ionBlur"], [null, "ionChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ionBlur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 24)._handleBlurEvent($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("ionChange" === en)) {
        var pd_1 = (i1.ɵnov(_v, 24)._handleIonChange($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.marketing = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i3.View_IonCheckbox_0, i3.RenderType_IonCheckbox)), i1.ɵdid(24, 16384, null, 0, i4.BooleanValueAccessor, [i1.ElementRef], null, null), i1.ɵprd(1024, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.BooleanValueAccessor]), i1.ɵdid(26, 671744, null, 0, i6.NgModel, [[8, null], [8, null], [8, null], [6, i6.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i6.NgControl, null, [i6.NgModel]), i1.ɵdid(28, 16384, null, 0, i6.NgControlStatus, [[4, i6.NgControl]], null, null), i1.ɵdid(29, 49152, null, 0, i4.IonCheckbox, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { mode: [0, "mode"], name: [1, "name"] }, null), (_l()(), i1.ɵeld(30, 0, null, null, 2, "h6", [], null, null, null, null, null)), (_l()(), i1.ɵted(31, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(33, 0, null, null, 3, "div", [["class", "div-cont-guest"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.logoutClicked();
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(34, 0, null, null, 2, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(35, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "content-signup-or-acc"; var currVal_1 = _ck(_v, 4, 0, true); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = "assets/images/join_login.svg"; _ck(_v, 9, 0, currVal_2); var currVal_5 = _co.hasTAndC; _ck(_v, 18, 0, currVal_5); var currVal_6 = "ios-arrow-forward"; _ck(_v, 21, 0, currVal_6); var currVal_14 = "marketing"; var currVal_15 = _co.marketing; _ck(_v, 26, 0, currVal_14, currVal_15); var currVal_16 = "md"; var currVal_17 = "marketing"; _ck(_v, 29, 0, currVal_16, currVal_17); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("dialog.guest-login.has-logged-in")); _ck(_v, 12, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 15, 0, i1.ɵnov(_v, 16).transform("dialog.guest-login.join-this-store")); _ck(_v, 15, 0, currVal_4); var currVal_7 = i1.ɵnov(_v, 28).ngClassUntouched; var currVal_8 = i1.ɵnov(_v, 28).ngClassTouched; var currVal_9 = i1.ɵnov(_v, 28).ngClassPristine; var currVal_10 = i1.ɵnov(_v, 28).ngClassDirty; var currVal_11 = i1.ɵnov(_v, 28).ngClassValid; var currVal_12 = i1.ɵnov(_v, 28).ngClassInvalid; var currVal_13 = i1.ɵnov(_v, 28).ngClassPending; _ck(_v, 23, 0, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13); var currVal_18 = i1.ɵunv(_v, 31, 0, i1.ɵnov(_v, 32).transform("pages.terms-conditions.receive-brand-tnc2", _co.TACKeyObj)); _ck(_v, 31, 0, currVal_18); var currVal_19 = i1.ɵunv(_v, 35, 0, i1.ɵnov(_v, 36).transform("dialog.guest-login.logout")); _ck(_v, 35, 0, currVal_19); }); }
export function View_JoinOrLogoutModal_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-join-or-logout-modal", [], null, null, null, View_JoinOrLogoutModal_0, RenderType_JoinOrLogoutModal)), i1.ɵdid(1, 245760, null, 0, i7.JoinOrLogoutModal, [i1.Injector, i8.MemberService, i9.StoreService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var JoinOrLogoutModalNgFactory = i1.ɵccf("app-join-or-logout-modal", i7.JoinOrLogoutModal, View_JoinOrLogoutModal_Host_0, {}, {}, []);
export { JoinOrLogoutModalNgFactory as JoinOrLogoutModalNgFactory };
