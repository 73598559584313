<!-- <ion-header>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-button appThrottleClick (throttleClick)="dismissClicked()" class="back-btn">
        
        </ion-buttons>
        <ion-title>
            {{mainItem.name}}
        </ion-title>
    </ion-toolbar>
</ion-header> -->
<div class="combo-wrapper">
    <!-- <div class="list-header-wrapper">


    </div> -->


    <!-- <ion-icon name="close" class="btn-close" appThrottleClick (throttleClick)="dismissClicked()"></ion-icon> -->



    <!-- header -->
    <div class="combo-info-contain">
        <div class="item-info">
            <ion-button fill="clear" (click)="dismissClicked()" size="small">
                <ion-icon slot="icon-only" src="assets/images/v3_close.svg"></ion-icon>
            </ion-button>

            <div class="item-info-box" [ngClass]="{'withImg': mainItem.getImage('default', 400, true)}">

                <div class="col-item-info">
                    <h1 class="title-text">{{mainItem?.name}}</h1>
                    <h2 *ngIf="mainItem?.desc" class="desc-text">{{mainItem.desc}}</h2>
                    <span class="itemSpan"></span>
                    <div class="div-tags">
                        <ng-container *ngIf="mainItem?.tags.length > 0">
                            <ng-container *ngFor="let tagsImg of mainItem.tags">
                                <div class="img-box img-tag">
                                    <img *ngIf="tagsImage[tagsImg]" src="{{tagsImage[tagsImg]}}"
                                        [ngClass]="{'single' : mainItem.tags.length == 1}">
                                </div>
                            </ng-container>
                        </ng-container>

                    </div>
                    <div class="price-panel">
                        <h3 class="price-text" *ngIf="settings && !settings.hidePrice">
                            {{orderManager?.store?.currencySign}}
                            {{mainItem|itemPrice}}

                        </h3>
                        <!-- <ng-container *ngIf="isAutoPickupHasModifier">
                            <div class="selected-item-chip-box">
                                <span class="customize" (click)="modifierClicked();$event.stopPropagation();"
                                    [ngClass]="{'completed': isAutoPickupModifierCompleted}">
                                    Customize
                                </span>
                                <span *ngIf="!isAutoPickupModifierCompleted" class="dot-modifier heartBeat animated infinite slow"></span>
                            </div>
                        </ng-container> -->
                    </div>

                </div>
                <div class="col-img" (click)="showImage(mainItem)"
                    *ngIf="mainItem.getImage('default', 360, true) || getDefaultImage(true, this.store.brand, 500)">
                    <div class="img-box">
                        <img class="head-img"
                            src="{{mainItem.getImage('default', 360, true) || getDefaultImage(true, this.store.brand, 500)}}">
                    </div>
                    <!-- <span class="icon-shadow"></span> -->
                    <!-- <ion-icon name="move"></ion-icon> -->
                </div>

            </div>
        </div>
        <!-- Main Content -->

        <ng-container *ngIf="nonAutoPickupGroups">
            <div tappable *ngFor="let group of nonAutoPickupGroups ;let i = index;trackBy:checkDifference"
                class="item-cat-wrapper">
                <div class="item-cat-box" [ngClass]="{'group-completed': isGroupCompleted(group)}"
                    (click)="expand(group.id);$event.stopPropagation();">
                    <div class="item-cat-info-box">
                        <div class="region-title">
                            <div *ngIf="!settings?.hideIgName" class="sub-item-title">
                                <p>{{group?.name}}</p>
                            </div>
                            <div class="select-n-required">
                                <span class="request-tag-box"
                                    [ngClass]="{completed: isGroupCompleted(group),missing: !isGroupCompleted(group) && missing }">

                                    <ng-container *ngIf="group?.optional">
                                        {{'global.please-select-max' | translate}}</ng-container>
                                    <ng-container *ngIf="!group?.optional">
                                        {{'global.please-select' | translate}}</ng-container>
                                    <span class="request-num-text">
                                        <ng-container *ngIf="group.min != group.max"><strong>{{group.min}}</strong>
                                            -
                                        </ng-container>
                                        <strong>{{group.max}}</strong>
                                    </span>{{'global.items' | translate}}
                                    <!-- <ion-icon name="arrow-up" *ngIf="isGroupExpand[group.id]"></ion-icon>
                                        <ion-icon name="arrow-down" *ngIf="!isGroupExpand[group.id]"></ion-icon> -->
                                </span>
                                <h4 class="text-required" *ngIf="!group?.optional && group.min != 0"
                                    [ngClass]="{completed: isGroupCompleted(group),missing: !isGroupCompleted(group) && missing }">
                                    *{{"pages.modifier-item-select.require" | translate}}
                                </h4>


                            </div>

                        </div>
                        <div class="modi-expand-collapse">
                            <div class="selected-item-chip-box fadeInRight animated"
                                [ngClass]="{'modifier-noCompleted ai-anime headShake': !this.isRequiredModifierSelect(i)}"
                                *ngIf="isCurrentGroupHasModifier(group) && !(builder?.noNeedSelected(groups?.indexOf(group) + 1, false))">
                                <!-- <span *ngFor="let name of getSelectedItemNamesArr(group)"
                                        class="selected-item-chip animated zoomIn">
                                        {{name}}
                                        Modifier </span>-->
                                <div class="customize" (click)="showModifierPopup(group);$event.stopPropagation();"
                                    [ngClass]="{'completed': this.isRequiredModifierSelect(i)}">
                                    {{"pages.item-select.modifier" | translate}}
                                    <span *ngIf="!this.isRequiredModifierSelect(i)"
                                        class="dot-modifier ai-anime headShake"></span>
                                </div>
                            </div>
                            <div class="expand-collapse">
                                <ion-icon name="arrow-up"
                                    *ngIf="isGroupExpand[group.id] && systemConfigService.comboPageGroupExpand">
                                </ion-icon>
                                <ion-icon name="arrow-down"
                                    *ngIf="!isGroupExpand[group.id] && systemConfigService.comboPageGroupExpand">
                                </ion-icon>
                            </div>

                        </div>



                        <!--    <div slot="end" class="right-pan" *ngIf="!group?.optional && group.min != 0"> -->



                    </div>

                </div>
                <!-- 展示每一项的配置要求 -->
                <ng-container *ngIf="isGroupExpand[group.id] || !systemConfigService.comboPageGroupExpand">
                    <ion-list *ngIf="itemGroupMap[group.id]" class="set-select-item">

                        <!-- noNeed option -->
                        <ion-item *ngIf="group?.optional" (click)="clickedNoNeed(group, i+1)"
                            class="noNeed-button item-list-box"
                            [ngClass]="{'noneed-selected': isNoNeedSelected(group)}">


                            <ion-icon class="radio-icon"
                                name="{{isNoNeedSelected(group) ? 'checkmark-circle' : 'radio-button-off'}}"
                                slot="start">
                            </ion-icon>

                            <ion-label class="name-label">
                                {{"pages.item-select.no-need" | translate}}
                            </ion-label>



                        </ion-item>

                        <ion-item *ngFor="let item of itemGroupMap[group.id];trackBy:checkDifference"
                            appThrottleClick (throttleClick)="addClicked(item,group);"
                            [ngClass]="{'selected-item' : (getGroupMode(group) == 'single' && has(item, group)), 'xxxxx': isGroupCompleted(group), 'sub-item-selected': has(item, group)}"
                            class="item-list-box">

                            <div class="mark-seleted">
                            </div>

                            <!-- sold out box -->
                            <div *ngIf="isSoldout(item)" class="sold-out-box">
                                <p>{{"pages.item-grid.soldout" | translate}}</p>
                            </div>



                            <ion-icon *ngIf="getGroupMode(group) == 'single'" class="ai-anime"
                                name="{{has(item, group) ? 'checkmark-circle' : 'radio-button-off'}}"
                                [class.checked]="has(item, group)" [class.unchecked]="!has(item, group)" slot="start">
                            </ion-icon>

                            <!-- <div *ngIf="has(item, group)">
                                                
                                                                                    </div> -->

                            <!-- isGroupCompleted(group) -->

                            <ion-icon *ngIf="getGroupMode(group) == 'quantity'" class="ai-anime"
                                name="{{has(item, group) ? 'add-circle' : 'add-circle-outline'}}"
                                [class.checked]="has(item, group)" [class.unchecked]="!has(item, group)" slot="start">
                            </ion-icon>


                            <!-- <ion-label *ngIf="getGroupMode(group) == 'quantity'">
                                                    <ion-button class="qty-btn add-qty-btn not-selected" color="medium" fill="clear"
                                                        [ngClass]="{'btn-added': has(item, group), 'is-completed':isCompleted() }">
                                                        <ion-icon class="add-icon" name="add-circle-outline"
                                                            color="{{ has(item, group)?'primary':'medium'}}" size="default">
                                                        </ion-icon>
                                                    </ion-button>
                                                    <ion-button class="qty-btn add-qty-btn selected" color="medium" fill="clear"
                                                        [ngClass]="{'btn-added': has(item, group), 'is-completed':isCompleted() }">
                                                        <ion-icon class="add-icon" name="add-circle"
                                                            color="{{ has(item, group)?'primary':'medium'}}" size="default">
                                                        </ion-icon>
                                                    </ion-button>
                        
                                                </ion-label> -->

                            <!-- sub-item-info -->
                            <ion-label class="name-label">
                                {{item.name}}

                            </ion-label>
                            <ion-label class="price-label">
                                +{{orderManager?.store?.currencySign}}
                                {{item | itemPrice }}
                            </ion-label>

                            <!-- alterQty -->
                            <ion-label *ngIf="getGroupMode(group) == 'quantity'" slot="end" class="minus-qty-btn"
                                [ngClass]="{'btn-added': has(item, group), 'bold-text': has(item, group)}">
                                <ng-container *ngIf="group.max > 1">

                                    x{{getQuantity(item, group) || 0}}
                                </ng-container>

                            </ion-label>

                            <ion-icon *ngIf="getGroupMode(group) == 'quantity'" name="remove-circle-outline"
                                color="medium" (click)="minusClicked(item, group);$event.stopPropagation();" slot="end">
                            </ion-icon>



                        </ion-item>

                        <ng-container *ngFor="let title of titles[group.id];let i = index;trackBy:checkDifference"
                            (click)="titleGroupClicked(title,i,group.id)">

                            <div class="parent-list-box">
                                <p *ngIf="title == 'hot-drink' || title == 'cold-drink'|| title == 'special-drink'">
                                    {{'drinks.' + title | translate}}</p>
                                <p *ngIf="!(title == 'hot-drink' || title == 'cold-drink'|| title == 'special-drink')">
                                    {{title}}</p>
                            </div>
                            <!-- <div class="child-list-wrapper isExpand" id="child-{{group.id}}-{{i}}"> -->
                            <div class="child-list-wrapper" id="child-{{group.id}}-{{i}}">
                                <ion-item
                                    *ngFor="let sameTitleItem of sameTitleItems[group.id][title];trackBy:checkDifference"
                                    (click)="$event.stopPropagation();addClicked(sameTitleItem,group)"
                                    [ngClass]="{'selected-item' : has(sameTitleItem,group)}"
                                    class="item-list-box child-list">
                                    <div *ngIf="isSoldout(sameTitleItem)" class="sold-out-box">
                                        <p>{{"pages.item-grid.soldout" | translate}}</p>
                                    </div>




                                    <!-- <div *ngIf="getGroupMode(group) == 'quantity'">
                                                                        <ion-button class="qty-btn add-qty-btn not-selected" color="medium"
                                                                            fill="clear"
                                                                            [ngClass]="{'btn-added': has(sameTitleItem,group), 'is-completed':isCompleted() }">
                                                                            <ion-icon class="add-icon" name="add-circle-outline"
                                                                                color="{{ has(sameTitleItem,group)?'primary':'medium'}}"
                                                                                size="default">
                                                                            </ion-icon>
                                                                        </ion-button>
                                                                        <ion-button class="qty-btn add-qty-btn selected" color="medium"
                                                                            fill="clear"
                                                                            [ngClass]="{'btn-added': has(sameTitleItem,group), 'is-completed':isCompleted() }">
                                                                            <ion-icon class="add-icon" name="add-circle"
                                                                                color="{{ has(sameTitleItem,group)?'primary':'medium'}}"
                                                                                size="default">
                                                                            </ion-icon>
                                                                        </ion-button>
                        
                                                                    </div> -->


                                    <ion-icon *ngIf="getGroupMode(group) == 'quantity'"
                                        name="{{has(sameTitleItem,group) ? 'add-circle' : 'add-circle-outline'}}"
                                        [class.checked]="has(sameTitleItem,group)"
                                        [class.unchecked]="!has(sameTitleItem,group)" slot="start">
                                    </ion-icon>
                                    <!-- sub-item-info -->



                                    <ion-label class="name-label">
                                        {{sameTitleItem.name}}
                                    </ion-label>
                                    <ion-label class="price-label">
                                        +{{orderManager?.store?.currencySign}}
                                        {{sameTitleItem|itemPrice}}

                                    </ion-label>


                                    <!-- alterQty -->
                                    <ion-label *ngIf="getGroupMode(group) == 'quantity'" slot="end"
                                        class="minus-qty-btn"
                                        [ngClass]="{'btn-added': has(sameTitleItem,group), 'bold-text': has(sameTitleItem,group)}">
                                        <ng-container *ngIf="group.max > 1">
                                            x{{getQuantity(sameTitleItem, group) || 0}}
                                        </ng-container>
                                    </ion-label>
                                    <ion-icon name="remove-circle-outline" *ngIf="getGroupMode(group) == 'quantity'"
                                        (click)="minusClicked(sameTitleItem, group);$event.stopPropagation();"
                                        slot="end" color="medium">
                                    </ion-icon>

                                    <ion-icon *ngIf="getGroupMode(group) == 'single'"
                                        name="{{has(sameTitleItem,group) ? 'checkmark-circle' : 'radio-button-off'}}"
                                        [class.checked]="has(sameTitleItem,group)"
                                        [class.unchecked]="!has(sameTitleItem,group)" slot="start">
                                    </ion-icon>


                                    <!--   <div (click)="showModifierPopup(group);$event.stopPropagation()"
                                                                    *ngIf="has(sameTitleItem, group) && isCurrentGroupHasModifier(group) && !(builder?.noNeedSelected(groups?.indexOf(group) + 1, false))"
                                                                    class="select-lower">
                                                                    <div class="modify-left">
                                                                        <h2>{{"pages.cart.special-request" | translate}}</h2>
                                                                        <p>{{getSelectedItemNamesModifier(group)}}</p>
                                                                    </div>
                                                                    <div class="modify-edit">
                                                                        <span>{{"pages.cart.edit" | translate}}</span>
                                                                    </div>
                                                                </div> -->

                                </ion-item>
                            </div>

                        </ng-container>

                    </ion-list>
                </ng-container>

            </div>

        </ng-container>
    </div>
    <div id="footer-box" class="abs-footer">
        <div class="select-panel">
            <!-- <div class="item-selected-tap">{{"pages.set-select.serve-with" | translate}}</div> -->
            <p class="item-selected-tap">
                <ng-container *ngFor="let item of builder?.items;let i = index;">
                    <!-- need to prefect the display conditions of span-el -->
                    <span *ngIf="i>0&&item"
                        class="item-selected-tap-tips">{{getModifierItemDisplay(orderManager.store.menu.imap, item)}}
                        <i *ngIf="i<builder?.items.length-1">,</i>
                    </span>
                </ng-container>
                <!-- Change to Dry Aged Patty x1, Add Beef Patty x2, Add Chicken x1 -->
            </p>

            <ng-container *ngIf="isAutoPickupHasModifier">

                <div class="selected-item-chip-box"
                    [ngClass]="{'animated ai-anime headShake': !isAutoPickupModifierCompleted }">
                    <div class="customize" (click)="modifierClicked();$event.stopPropagation();"
                        [ngClass]="{'completed': isAutoPickupModifierCompleted}">
                        {{"pages.item-select.customize" | translate}}
                        <span *ngIf="!isAutoPickupModifierCompleted" class="dot-modifier ai-anime headShake"></span>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="footer-main-container">


            <!-- left -->
            <ng-container *ngIf="isCompleted() && isAutoPickupModifierCompleted">

                <div class="footer-left">
                    <!-- total -->

                    <div class="text-left">

                        <div class="dupli-qty">


                            <ion-button color="medium" fill="clear" size="small" (click)="totalQtyMinusClicked()"
                                class="ai-anime">
                                <ion-icon class="add-icon" name="remove-circle-outline">
                                </ion-icon>
                            </ion-button>

                            <h4>{{totalQty}}</h4>

                            <ion-button color="primary" fill="clear" size="small" (click)="totalQtyAddClicked()"
                                class="ai-anime">
                                <ion-icon class="add-icon" name="add-circle">
                                </ion-icon>
                            </ion-button>

                        </div>

                        <!-- <div class="btn-price-tag">
                    <span>{{"pages.cart.total" | translate}}:</span>
                    <span>{{orderManager?.store?.currencySign}}{{total}}</span>
                </div> -->
                    </div>

                </div>
            </ng-container>
            <!-- right -->
            <div (click)="addToCartClicked()"
                class="footer-right inline-ft-btn key-button saveOrder-button item-grid-checkout-btn"
                [ngClass]="{'completed ai-anime headShake': isCompleted() && isAutoPickupModifierCompleted}">
                {{"pages.item-grid.add" | translate}}
                <ng-container *ngIf="settings && !settings.hidePrice">
                    {{total*totalQty | priceDisplay}}
                </ng-container>

            </div>
        </div>

    </div>
</div>