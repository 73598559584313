<ion-content class="dine-counter">

    <div class="top-container bg-remove">
        <!--bg-remove:remove the item list background-->
        <div class="rec-header"></div>

        <!--<div class="sp-req-header">
            <div class="header-counter">
                <span class="header-line-01"></span>
                <span class="header-line-02"></span>
                <h1> Recommendation </h1>
            </div>
        </div>-->

        <div class="content-container">
            <h4 class="sp-sub-title">
                <h1> {{recommendList?.name? recommendList?.name : 'pages.unlockPopup.title' | translate}} </h1>
                <span></span>
                <p *ngIf="max">{{displayTitle}}</p>
            </h4>


            <div class="sp-req-content" [ngClass]="{'same-as-list-mode': true}">

                <!-- oldlayout -->
                <ng-container *ngIf="false">
                    <div class="sp-req-grid" *ngIf="!showImage && max == 1">

                        <div col-12 class="sp-req-item" *ngFor="let item of addItems; let i = index"
                            [hidden]="item.parentId && !isParent(item)">
                            <div class="btn-sp-req" [ngClass]="{'stay' :qtyTag[i] > 0}" appThrottleClick
                                (throttleClick)="addClicked(i)">
                                <ion-icon name="md-checkmark" class="sp-req-tick"></ion-icon>
                                <p>{{item.name}}</p>
                                <span class="sp-req-price">{{store.sign}}{{item.price}}</span>
                            </div>
                        </div>

                    </div>

                    <div class="sp-req-grid" *ngIf="!showImage && max > 1">

                        <div col-12 class="sp-req-item" *ngFor="let item of addItems; let i = index"
                            [hidden]="item.parentId && !isParent(item)">
                            <div class="btn-sp-req stay" appThrottleClick (throttleClick)="addClicked(i)">

                                <p>{{item.name}}</p>
                                <span class="sp-req-price">{{store.sign}}{{item.price}}</span>
                                <div class="sp-req-qty-panel">
                                    <div class="btn-qty">
                                        <ion-icon name="md-remove" class="btn-qty-remove"
                                            [ngClass]="{'stay': qtyTag[i] > 0}"
                                            (click)="removeClicked(item,i);$event.stopPropagation();"></ion-icon>
                                    </div>
                                    <span class="sp-req-qty">{{qtyTag[i]}}</span>
                                    <div class="btn-qty">
                                        <ion-icon name="md-add" class="btn-qty-add stay" appThrottleClick
                                            (throttleClick)="addClicked(i);"></ion-icon>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                    <ion-grid *ngIf="showImage">
                        <ion-row>

                            <ion-col col-12 class="item-col listing-item luxe-with-image"
                                [ngClass]="{'out-of-stock': isSoldout(item), 'big-item': addItems.length <= 2}"
                                *ngFor="let item of addItems; let i = index"
                                [hidden]="item.parentId && !isParent(item)">

                                <div appThrottleClick (throttleClick)="addClicked(i)">

                                    <div class="listing-item-img">

                                        <div class="img-box"
                                            [ngStyle]="{'background-image': 'url(' + getImage(item, 'default', 300) + ')'}">
                                            <span class="image-overlay"></span>
                                            <div class="ofs-lab">{{'pages.item-grid.soldout' | translate}}</div>
                                        </div>

                                        <ng-container>
                                            <div class="item-qt-counter" *ngIf="qtyTag[i] > 0">
                                                <span class="item-qt"
                                                    [ngClass]="{'bounceIn animated animated-s-counter': animateQtyMap['#'+item.id] == true }">
                                                    <p>{{qtyTag[i]}}</p>
                                                </span>
                                            </div>
                                        </ng-container>

                                    </div>



                                    <div class="listing-item-counter with-img"
                                        [ngClass]="{'minus': qtyTag[i] == 0, 'add': qtyTag[i] > 0}">
                                        <div *ngIf="!isParent(item)" class="card-title">{{item.name}}</div>
                                        <div *ngIf="isParent(item)" class="card-title">
                                            {{item.title? item.title : item.name}}</div>



                                        <div class="item-grid-price">
                                            <button ion-button clear
                                                class="icon-item-icon icon-item-remove disable-hover"
                                                (click)="removeClicked(item,i);$event.stopPropagation();">
                                                <ion-icon name="remove"></ion-icon>
                                            </button>
                                            <span>{{store.sign}}{{item.price}}</span>
                                            <ion-icon class="price-arrow"
                                                name="{{!isParent(item)? 'add' : 'ios-arrow-forward'}}">
                                            </ion-icon>
                                        </div>

                                    </div>


                                </div>


                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </ng-container>

                <!-- newlayout -->
                <div *ngFor="let item of addItems;let i = index" class="eachCard list-mode">
                    <div *ngIf="item" appThrottleClick (throttleClick)="addClicked(i)"
                        [ngClass]="{'AQA-setItem': isSet(item), 'AQA-aLaCarteItem': !isSet(item),'not-showLogo': !this.systemConfigService.showLogo || !getItemImage(item)}"
                        class="item-wrapper animated fadeInUps item-card" ng-switch-when="category">

                        <!-- no image mode element -->
                        <ng-container
                            *ngIf="!this.systemConfigService.showLogo || !getItemImage(item); else elseTemplate ">
                            <ion-icon _ngcontent-c6="" class="no-combo-add-icon no-logo-mode ios hydrated" name="md-add"
                                ng-reflect-name="md-add" role="img" aria-label="add"></ion-icon>

                            <!-- variations parent 也要show quantity -->
                            <div class="qty-panel no-logo-mode" *ngIf="getItemQuanlity(item, i) > 0">
                                <!-- !item?.variations -->

                                <ion-icon appThrottleClick (throttleClick)="removeClicked(item,i)" name="md-remove"
                                    class="btn-qty-remove AQA-minus-button"></ion-icon>

                                <div class="qtn-with-item">
                                    <p class="AQA-item-qty">{{getItemQuanlity(item, i)}}</p>
                                </div>

                                <ion-icon name="md-add" class="btn-qty-add AQA-add-button"></ion-icon>

                            </div>

                            <div class="sold-out-box AQA-soldOut no-logo-mode" *ngIf="isSoldout(item)">
                                <span>{{"pages.item-grid.soldout" | translate}}</span>
                            </div>

                            <div *ngIf="item.tags.length > 0" class="tag-image-box no-logo-mode">
                                <div class="tag-images-wrapper">
                                    <ng-container *ngFor="let tagsImg of item.tags">
                                        <img *ngIf="tagsImage[tagsImg]" src="{{tagsImage[tagsImg]}}"
                                            [ngClass]="{'single' : item.tags.length == 1}">

                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>

                        <!-- image mode element -->
                        <ng-template #elseTemplate>
                            <div class="img-square center-cropped" style="background: white;">

                                <div *ngIf="item.tags.length > 0" class="tag-image-box">
                                    <div class="tag-images-wrapper">
                                        <ng-container *ngFor="let tagsImg of item.tags">
                                            <img *ngIf="tagsImage[tagsImg]" src="{{tagsImage[tagsImg]}}"
                                                [ngClass]="{'single' : item.tags.length == 1}">

                                        </ng-container>
                                    </div>
                                </div>
                                <!-- Item tag -->
                                <!-- <div *ngIf="item.tags.length > 0" class="tag-image-box"></div> -->
                                <div class="item-img-box img-box" [ngStyle]="{'background-image': getTempUrl(item) }">
                                    <ion-icon name="md-add" class="combo-option" *ngIf="item.minPrice"></ion-icon>
                                    <ion-icon name="md-add" class="no-combo-add-icon" *ngIf="!item.minPrice"></ion-icon>
                                    <ng-container *ngIf="getTempUrl(item) == 'url(null)'">
                                        <p class="no-img-text">{{item.name}}</p>
                                    </ng-container>
                                </div>


                                <div class="sold-out-box AQA-soldOut" *ngIf="isSoldout(item)">
                                    <span>{{"pages.item-grid.soldout" | translate}}</span>
                                </div>
                                <span>{{getItemQuanlity(item, i)}}</span>
                                <!-- <div class="qty-panel" *ngIf="getItemQuanlity(item, i) > 0 && !item?.variations"> -->
                                <!-- variations parent 也要show quantity -->
                                <div class="qty-panel" *ngIf="getItemQuanlity(item, i) > 0">
                                    <ion-icon appThrottleClick (throttleClick)="removeClicked(item,i);" name="md-remove"
                                        class="btn-qty-remove AQA-minus-button"></ion-icon>

                                    <div class="qtn-with-item">
                                        <p class="AQA-item-qty">{{getItemQuanlity(item, i)}}</p>
                                    </div>

                                    <ion-icon name="md-add" class="btn-qty-add AQA-add-button"></ion-icon>

                                </div>
                            </div>
                        </ng-template>


                        <div class="item-info"
                            [ngClass]="{ 'isEn' : translateService.currentLang === 'en','selected-item': getItemQuanlity(item, i) > 0}">

                            <div class="item-title">
                                {{ this.isParent(item) ? (item?.title):(item?.name) }}
                            </div>
                            <span class="title-underline"></span>
                            <p *ngIf="item?.desc && item?.desc.length > 0" class="item-desc line-clamp AQA-itemDesc">
                                {{item.desc}}
                            </p>

                            <div class="item-price" *ngIf="!settings?.hideZeroItemPrice || item.price > 0">
                                <span *ngIf="!item.minPrice" class="itemPrice-text">
                                    {{item|itemPrice|priceDisplay : true : ' ' : orderManager?.store?.currencySign}}
                                </span>
                                <span *ngIf="item.minPrice" class="itemPrice-text">
                                    {{item|itemPrice|priceDisplay : true : ' ' : orderManager?.store?.currencySign}}
                                    {{"global.up" | translate}}</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

    </div>

</ion-content>

<ion-footer>

    <ion-button expand="full" size="default" class="" [class.ifSelect]="round(newTotal) <= 0 && !this.isGift"
        (click)="okClicked()">
        {{'buttons.confirm' | translate}} (+{{store.sign}}{{round(newTotal)}})</ion-button>
</ion-footer>