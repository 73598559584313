var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, Injector, ChangeDetectorRef } from '@angular/core';
import { MobileBasePage } from '../../core/base/mobile-base-page';
import { getCategoryPage } from 'src/app/shared/utilities/layout-helper';
import { UUID } from 'angular2-uuid';
import { StoreService } from 'src/app/core/services/store.service';
var AddressDialog = /** @class */ (function (_super) {
    __extends(AddressDialog, _super);
    function AddressDialog(injector, cd, storeService) {
        var _this = _super.call(this, injector) || this;
        _this.injector = injector;
        _this.cd = cd;
        _this.storeService = storeService;
        // addressArr = [];
        _this.addressArr = [];
        _this.pickupAddressGroups = [];
        _this.pickupArr = [];
        _this.isRemove = false;
        _this.segmentOption = 'pickup';
        _this.displayName = '';
        _this.hideLogin = false;
        _this.hideDeliveryTab = false;
        _this.storeLocation = _this.orderManager.store.location;
        _this.isRoute = _this.getNavParams('isRoute') || false;
        _this.confirmCb = _this.getNavParams('confirmCb');
        _this.goToMenu = _this.getNavParams('goToMenu');
        _this.fromPage = _this.getNavParams('fromPage');
        _this.addAddressCb = _this.getNavParams('addAddressCb');
        _this.showModeSelect = _this.getNavParams('showModeSelect');
        _this.isChangeDeliveryMode = _this.getNavParams('isChangeDeliveryMode');
        _this.hideLogin = _this.getNavParams('hideLogin');
        _this.getAddresses();
        console.log('this.ordermanager', _this.orderManager);
        _this.hideDeliveryTab = _this.systemConfigService.hideDeliveryTab;
        console.log('ly: -> address-dialog.component.ts -> constructor() -> hideDeliveryTab:', _this.hideDeliveryTab);
        if (_this.orderManager.mode === 'delivery') {
            _this.segmentOption = 'delivery';
        }
        return _this;
    }
    AddressDialog.prototype.ngOnInit = function () {
        // todo 暂时pickup地址为store location
        var member = this.memberService.getMember();
        if (member && member['method'] !== 'device') {
            this.displayName = '';
        }
        else if (member) {
            this.displayName = '';
        }
        else {
            this.displayName = '';
        }
        this.addressManagerService.initPickupArr();
        var _a = this.addressManagerService, currentAddress = _a.currentAddress, pickupAddress = _a.pickupAddress, addressArr = _a.addressArr, pickupArr = _a.pickupArr;
        // this.addressArr = addressArr;
        if (pickupArr) {
            this.pickupAddressGroups = pickupArr;
        }
        if (currentAddress) {
            this.currentAddressId = currentAddress.id;
        }
        else if (pickupAddress) {
            this.pickupAddressId = pickupAddress.id;
        }
        this.addressManagerService.isAddAddress = false;
    };
    AddressDialog.prototype.ionViewDidEnter = function () {
        // this.addressArr = this.addressManagerService.addressArr;
        if (this.pickupAddressGroups.length === 1 && this.orderManager.mode === 'takeaway') {
            this.pickupAddressId = this.pickupAddressGroups[0].id;
            this.okClicked();
        }
    };
    AddressDialog.prototype.getAddresses = function () {
        this.addressArr = this.addressManagerService.getAddresses(this.orderManager.store);
        console.log('this.addressArr after resort', this.addressArr);
    };
    AddressDialog.prototype.addNewAdress = function () {
        var _this = this;
        console.log('addNewAdress cliking', this.addAddressCb);
        var parmas = { store: this.orderManager.store };
        if (this.addAddressCb)
            parmas['addAddressCb'] = this.addAddressCb;
        parmas['getAddress'] = function () { return _this.getAddresses(); };
        this.pushByName('AddAddressPage', parmas, {}).then(function () {
        });
    };
    AddressDialog.prototype.closeClicked = function () {
        var _this = this;
        if (this.isRoute) {
            // 路由导航 from the delivery-login registe successly
            if (this.isRemove) {
                this.isRemove = false;
            }
            else {
                if (this.fromPage === 'memberReward') {
                    this.backButtonClick();
                    return;
                }
                this.popToRoot();
            }
        }
        else {
            // modal
            if (!this.isRemove) {
                this.modalController.dismiss({ close: true }).then(function () {
                    // callback
                    if (_this.addressManagerService.pickupAddress) {
                        _this.addressManagerService.setPickup(true);
                    }
                    else
                        _this.addressManagerService.setPickup(false);
                    // this.checkAndForceAdd();
                });
            }
            else {
                this.isRemove = false;
            }
        }
    };
    AddressDialog.prototype.pushToNext = function (offer) {
        var _this = this;
        this.menuManager.clearAvailableMap();
        // TODO: use this as default layout?
        // call general start order function here
        // i.e set up order manager, settings, etc.
        // this.initOrder();
        this.pushAndRemovePage(getCategoryPage(this.systemConfigService, this.orderManager.store.menu.catLayout), {
            // this.pushAndRemovePage('TopMenuCategoryListPage', {
            store: this.orderManager.store,
            storeId: this.orderManager.store.id
        }, null, function () {
            if (offer) {
                var isForceOldLayout = _this.getIsForceOldLayout();
                var page = 'ItemGridPage';
                if (!isForceOldLayout) {
                    page = 'ItemGridScrollPage';
                }
                _this.clickReward(offer, _this.orderManager.store, page);
            }
        });
    };
    AddressDialog.prototype.deliveryComfirm = function () {
        var _this = this;
        var changed = true;
        if (this.pickupAddressId) {
            console.log('pickup');
            var pickupAddress = this.addressManagerService.pickupAddress; //
            if (pickupAddress && pickupAddress.id === this.pickupAddressId) {
                console.log('pickup-select not change');
                changed = false;
            }
            // else {
            var newPickupAddress = this.addressManagerService.findPickupAddress(this.pickupAddressId);
            this.addressManagerService.setPickupAddress(newPickupAddress);
            this.addressManagerService.setPickup(true);
            // }
            if (this.isRoute) {
                // todo
                // 1. if currentAddress is valid push to cat-list-page with login
                this.popToRoot().then(function () {
                    // this.checkPhone(() => {
                    //   this.goToMenu && this.pushToNext();
                    // })
                    _this.goToMenu && _this.pushToNext();
                });
                // 2. if invalid push to cat-list-page with not login
            }
            else {
                // comfire btn click save the address is change
                this.addressManagerService.setIsChanged(changed);
                this.modalController.dismiss().then((function () {
                    // this.checkAndForceAdd();
                    // this.checkPhone(() => {
                    //   this.goToMenu && this.pushToNext();
                    // })
                    _this.goToMenu && _this.pushToNext();
                }));
            }
        }
        else {
            var currentAddress = this.addressManagerService.currentAddress;
            if (currentAddress && currentAddress.id === this.currentAddressId && this.addressManagerService.sameAddressCouriersData) {
                console.log('currentAddress-select not change');
                changed = false;
                this.handlingCouriers(this.addressManagerService.sameAddressCouriersData, changed);
            }
            else {
                var address = this.addressManagerService.findAddress(this.currentAddressId);
                // save the ordering-time address
                if (address) {
                    this.addressManagerService.pickupAddress = null;
                    this.addressManagerService.saveCurrentAddress(address);
                    this.addressManagerService.isDefault = false;
                    this.addressManagerService.setPickup(false);
                }
                else {
                    console.log('not find the current addressId of Address');
                    return;
                }
                if (this.currentAddressId) {
                    this.loading(true);
                    this.storeService.getCouriers(this.orderManager.store.id, this.orderManager.store.country, this.currentAddressId).subscribe(function (data) {
                        _this.loading(false);
                        console.log('AddressDialog getCouriers', data);
                        _this.addressManagerService.sameAddressCouriersData = data;
                        _this.handlingCouriers(data, changed);
                    }, function (err) {
                        _this.loading(false);
                        _this.showError(err);
                    });
                }
            }
        }
    };
    AddressDialog.prototype.handlingCouriers = function (data, changed) {
        var _this = this;
        if (data && data[0] && data[0]['couriers'] && data[0]['couriers'].length > 0) {
            var rules = data[0]['couriers'];
            this.addressManagerService.setPriceRulesOfCurrentAddress(rules);
            if (this.isRoute) {
                // todo
                // 1. if currentAddress is valid push to cat-list-page with login
                this.popToRoot().then(function () {
                    _this.goToMenu && _this.pushToNext();
                });
                // 2. if invalid push to cat-list-page with not login
            }
            else {
                // comfire btn click save the address is change
                this.addressManagerService.setIsChanged(changed);
                this.modalController.dismiss({}).then(function () {
                    _this.goToMenu && _this.pushToNext();
                });
            }
        }
        else {
            // 
            this.showAlert('', this.t.instant('pages.cart.not-delivered'));
            this.addressManagerService.currentAddress = null;
        }
    };
    AddressDialog.prototype.checkPhone = function (callback) {
        console.log('AddressDialog check phone exist');
        var userMes = this.memberService.getMember();
        if (!userMes.phone) {
            this.pushByName('PhoneVerification', {
                store: this.orderManager.store,
                deliveryLoginCb: function () {
                    callback();
                }
            });
            return;
        }
        else {
            if (callback) {
                callback();
            }
        }
    };
    AddressDialog.prototype.guestLogin = function () {
        var _this = this;
        this.loading(true);
        this.memberService.clearSession();
        var s = UUID.UUID().toString();
        this.memberApiService.guestLogin(s, this.orderManager.store.brandId).subscribe(function (member) {
            _this.loading(false);
            _this.memberService.putMember(member);
            _this.confirmHandle();
        }, function (err) {
            _this.loading(false);
            _this.showError(err);
        });
    };
    AddressDialog.prototype.isAddressNull = function () {
        return !(!!this.addressManagerService.currentAddress);
    };
    AddressDialog.prototype.okClicked = function () {
        var member = this.configService.getLocal('aigens.member');
        if (!member) {
            // guestlogin first
            // this.guestLogin();
        }
        else {
            this.confirmHandle();
        }
    };
    AddressDialog.prototype.confirmHandle = function () {
        var _this = this;
        // 判断delivery mode有没有改变
        if ((this.addressManagerService.currentAddress && this.pickupAddressId)
            || (this.addressManagerService.pickupAddress && this.currentAddressId)) {
            if (this.orderManager.getOrder() && this.orderManager.getOrder().orderitems.length > 0) {
                this.showAlert('', this.t.instant('dialog.address-dialog.change-mode-clear-order'), [{
                        text: this.t.instant('dialog.address-dialog.no'),
                        handler: function () {
                            console.log('cancel');
                        }
                    }, {
                        text: this.t.instant('dialog.address-dialog.yes'),
                        handler: function () {
                            _this.isChangeDeliveryMode && _this.isChangeDeliveryMode(true);
                            _this.orderManager.clearOrderItems();
                            _this.deliveryComfirm();
                        }
                    }]);
            }
            else {
                this.isChangeDeliveryMode && this.isChangeDeliveryMode(true);
                this.deliveryComfirm();
            }
        }
        else {
            this.deliveryComfirm();
        }
    };
    AddressDialog.prototype.segmentChanged = function (ev) {
        console.log('ev', ev);
        console.log(ev['detail']);
        this.segmentOption = ev['detail']['value'];
    };
    // check the addressArr length and force to add new address
    AddressDialog.prototype.checkAndForceAdd = function () {
        var _this = this;
        if (this.addressArr.length > 0 || this.addressManagerService.isPickup)
            return;
        if (this.fromPage === 'ByodSplashPage')
            return;
        console.log('current member not address to delivery');
        var alert = this.alertController.create({
            header: 'No available address',
            message: 'Please add an address / Select Pickup',
            backdropDismiss: false,
            buttons: [{
                    text: this.t.instant('buttons.ok'),
                    handler: function () {
                        _this.popUpAdressModal({
                            goToMenu: false,
                            isRoute: false
                        });
                    }
                }]
        });
        alert.then(function (data) { return data.present(); });
    };
    AddressDialog.prototype.popUpAdressModal = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var modal;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalController.create({
                            component: AddressDialog,
                            componentProps: params,
                            showBackdrop: true,
                            backdropDismiss: false,
                            cssClass: ''
                        })];
                    case 1:
                        modal = _a.sent();
                        modal.onDidDismiss().then(function (data) {
                        });
                        modal.present().then(function () {
                            //  cb()
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    AddressDialog.prototype.checkComfirmAvai = function () {
        var _this = this;
        if (this.currentAddressId) {
            var address = this.addressArr.find(function (add) { return add.id === _this.currentAddressId; });
            return address ? address['beyond'] : false;
        }
        else if (this.pickupAddressId) {
            return true;
        }
        return false;
    };
    // removeClicked(address) {
    //   console.log('removeClicked', address);
    //   let addressId = address['id'] || null;
    //   this.popUpRemoveAlert(addressId);
    // }
    AddressDialog.prototype.setCurrentAddressIdNull = function () {
        this.currentAddressId = null;
    };
    AddressDialog.prototype.setPickupAddressIdNull = function () {
        console.log('setPickupAddressNull');
        this.pickupAddressId = null;
    };
    AddressDialog.prototype.postRemoveAddress = function (addressId) {
        var _this = this;
        this.loading(true);
        if (addressId)
            this.addressManagerService.deleteAddress(addressId).subscribe(function (result) {
                console.log('deleteAddress() result:', result);
                _this.loading(false);
                _this.addressArr = _this.addressManagerService.addressArr;
                _this.orderSessionService.setLocalAddress(_this.addressArr);
                _this.configService.setLocal('addressArrOfStoreList', _this.addressArr);
                _this.isRemove = false;
                if (_this.addressManagerService.currentAddress && addressId === _this.addressManagerService.currentAddress.id) {
                    console.log('remove the selecting address');
                    _this.addressManagerService.setIsChanged(true);
                    _this.currentAddressId = null;
                    _this.addressManagerService.currentAddress = null;
                    _this.addressManagerService.currentCourier = null;
                }
            }, function (err) {
                _this.loading(false);
                return _this.showAlert(err.status, err['error']);
            });
    };
    AddressDialog.prototype.removeHandle = function () {
        console.log('removeHandle clicking');
        this.isRemove = true;
        this.cd.detectChanges();
    };
    AddressDialog.prototype.deleteHandle = function () {
        if (this.currentAddressId) {
            this.popUpRemoveAlert(this.currentAddressId);
        }
    };
    AddressDialog.prototype.trackAddressById = function (index, address) {
        return address && address.id;
    };
    AddressDialog.prototype.popUpRemoveAlert = function (addressId) {
        return __awaiter(this, void 0, void 0, function () {
            var alert;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertController.create({
                            // header: 'Alert',
                            // subHeader: 'Subtitle',
                            message: 'Are you sure you want to remove this address?',
                            backdropDismiss: false,
                            buttons: [
                                {
                                    text: 'Keep',
                                    handler: function () {
                                        console.log('Keep clicked');
                                    }
                                }, {
                                    text: 'Remove',
                                    handler: function () {
                                        console.log('Remove clicked');
                                        _this.postRemoveAddress(addressId);
                                    }
                                }
                            ]
                        })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AddressDialog.prototype.goToLogin = function () {
        var member = this.memberService.getMember();
        var orderSession = this.orderSessionService.getLocalOrderSession();
        if (member && orderSession && orderSession.member && member['method'] !== 'device') {
            if (this.systemConfigService.hideStampPage) {
                this.pushByName('ProfilePage');
            }
            else {
                this.pushByName('MemberRewardPage');
            }
        }
        else {
            console.log('mode ###', this.orderManager.mode);
            var mode = this.orderManager.mode;
            var loginPageName = 'LoginPage';
            if (mode === 'delivery') {
                loginPageName = 'DeliveryLoginPage';
            }
            // this.pushByName('LoginPage', {
            // new login logic
            this.pushByName(loginPageName, {
                store: this.orderManager.store,
                fromPage: 'ByodSplashPage',
                isRoute: true,
                logincb: function () {
                    // this.originNextClicked();
                }
            });
        }
    };
    AddressDialog.prototype.getIsForceOldLayout = function () {
        // return true;
        return this.systemConfigService['systemConfig'] && this.systemConfigService['systemConfig']['data'] && this.systemConfigService['systemConfig']['data']['forceOldLayout'];
    };
    return AddressDialog;
}(MobileBasePage));
export { AddressDialog };
