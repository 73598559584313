var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ModalController } from '@ionic/angular';
import { PopupDialog } from '../../dialogs/popup/popup';
import { ConfigService } from './config.service';
import { NavigationService } from './navigation.service';
import { ActivatedRoute } from '@angular/router';
import { MemberService } from './member.service';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "./member.service";
import * as i3 from "./config.service";
import * as i4 from "./navigation.service";
import * as i5 from "@angular/router";
var LayoutService = /** @class */ (function () {
    function LayoutService(modalController, memberService, configService, navigationService, route) {
        this.modalController = modalController;
        this.memberService = memberService;
        this.configService = configService;
        this.navigationService = navigationService;
        this.route = route;
        this.isFirstEnterByodSplash = true;
        this.isSelectedLanguage = false;
        // storage.get('theme').then(cssText => {  // <--- GET SAVED THEME
        //     this.setGlobalCSS(cssText);
        // });
    }
    LayoutService.prototype.showPopup = function (store, popupLocation, dismissCallback) {
        return __awaiter(this, void 0, void 0, function () {
            var ads, popupKey, canShow, isMember, adsToShow, input, modal;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!store || !store.brand || !store.brand.ads) {
                            console.warn('input store has no popup', store);
                            return [2 /*return*/];
                        }
                        ads = store.brand['ads'];
                        popupKey = store.id + popupLocation;
                        canShow = this.checkAdTodayCanShow(popupKey);
                        isMember = !!this.memberService.getMember();
                        adsToShow = ads.filter(function (ad) {
                            if (ad.type !== 'popup' && ad.type !== 'slider')
                                return false;
                            if (!ad.images['default'] && (!ad.desc || !ad.desc.startsWith('http')))
                                return false;
                            if (ad.users && ad.users.length > 0) {
                                if (ad.users.indexOf('guest') === -1 && !isMember)
                                    return false;
                                if (ad.users.indexOf('member') === -1 && isMember)
                                    return false;
                            }
                            if ((ad['stage'] !== popupLocation) && (ad.stages && ad.stages.length > 0 && ad.stages.indexOf(popupLocation) === -1))
                                return false;
                            return canShow || ad['repeats'];
                        });
                        if (!(adsToShow.length > 0)) return [3 /*break*/, 2];
                        input = { store: store, ads: adsToShow };
                        return [4 /*yield*/, this.modalController.create({
                                component: PopupDialog,
                                componentProps: input,
                                showBackdrop: true,
                                backdropDismiss: true,
                                cssClass: 'no-bgColor'
                            })];
                    case 1:
                        modal = _a.sent();
                        modal.onDidDismiss().then(function (data) { return dismissCallback(data); });
                        modal.present().then(function () {
                            // save popup end time
                            _this.saveTodayEndTime(popupKey);
                        });
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    /**********  for , 根據key,判斷是否在今天內顯示過 start  ********/
    /// key = storeid  + ad.stage
    LayoutService.prototype.saveTodayEndTime = function (key) {
        if (!key)
            return;
        var endTime = new Date().setHours(23, 59, 59, 0);
        // this.configService.putPreference(key, endTime);
        this.configService.setCookie(key, String(endTime));
    };
    LayoutService.prototype.checkAdTodayCanShow = function (key) {
        if (!key)
            return;
        var nowTime = new Date().getTime();
        var value = this.configService.getCookie(key) || '0';
        return nowTime > Number(value);
    };
    LayoutService.ngInjectableDef = i0.defineInjectable({ factory: function LayoutService_Factory() { return new LayoutService(i0.inject(i1.ModalController), i0.inject(i2.MemberService), i0.inject(i3.ConfigService), i0.inject(i4.NavigationService), i0.inject(i5.ActivatedRoute)); }, token: LayoutService, providedIn: "root" });
    return LayoutService;
}());
export { LayoutService };
