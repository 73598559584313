var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { BaseService } from '../base/base-service';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { MemberService } from './member.service';
import { AQuery } from '../base/aquery';
import { Data, Member, OrderManager, Offer } from 'aigens-ng-core';
import { map, share } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./config.service";
import * as i3 from "./member.service";
import * as i4 from "aigens-ng-core/dist/manager/order-manager";
var MemberApiService = /** @class */ (function (_super) {
    __extends(MemberApiService, _super);
    function MemberApiService(http, configs, memberService, orderManager) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.configs = configs;
        _this.memberService = memberService;
        _this.orderManager = orderManager;
        _this.aq = new AQuery(http, configs);
        return _this;
    }
    MemberApiService.prototype.sessionLogin = function (brandId) {
        var url = '/api/v1/store/member/me.json';
        var aq = this.aq;
        aq.url = url;
        aq.shouldAuth = true;
        aq.method = 'get';
        var params = {};
        params['brandId'] = brandId;
        aq.params = params;
        // debugger;
        // let headers = {'Authorization' : `Bearer ${token}`};
        // aq.setHeaders(headers);
        return aq.getJson().pipe(map(function (jo) {
            console.log('jadd login jo:', jo);
            return jo['data'];
        }));
    };
    MemberApiService.prototype.loginWithQrcode = function (type, code, brandId) {
        var url = '/api/v1/store/login.json?type=' + type;
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.auth(true);
        var body = {};
        // let params = {
        //   addressId: address['id'],
        //   phone: this.memberService.member && this.memberService.member['phone'],
        //   storeId: store['id'],
        //   type: 'delivery'
        // };
        // if (oi) params['orderitems'] = oi;
        // if (courierId) params['courierId'] = courierId;
        var params = {};
        params['brandId'] = brandId;
        params['code'] = code;
        aq.params = params;
        return aq.getJson().pipe(map(function (jo) {
            return jo['data'];
        }));
    };
    MemberApiService.prototype.nandosLogin = function (session, brandId) {
        var url = "" + '/api/v1/store/login.json';
        this.aq.url = url;
        this.aq.method = 'post';
        var search = { type: 'crm', subtype: 'code', 'code': session, 'brandId': brandId };
        this.aq.params = search;
        // let body = {code: session, brandId: brandId };
        // this.aq.body = body;
        // this.aq.setHeaders({'Access-Control-Allow-Headers': ''});
        return this.aq.getJson().map(function (jo) {
            return jo['data'];
        });
    };
    MemberApiService.prototype.login = function (username, password, type, countryCode, brandId) {
        var _this = this;
        var url = '/api/v1/store/login.json?type=' + type;
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        var params = { 'username': username, 'password': password };
        if (countryCode) {
            params['countryCode'] = countryCode;
        }
        if (brandId) {
            params['brandId'] = brandId;
        }
        aq.params = params;
        return aq.getJson().pipe(map(function (jo) {
            console.log('jadd login jo:', jo);
            _this.memberService.member = Data.toData(Member, jo['data']);
            return _this.memberService.member;
        }));
    };
    MemberApiService.prototype.loginCourt = function (username, password, type, countryCode, courtId) {
        var _this = this;
        var url = '/api/v1/store/login.json?type=' + type;
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        var params = { 'username': username, 'password': password };
        if (countryCode) {
            params['countryCode'] = countryCode;
        }
        if (courtId) {
            params['courtId'] = courtId;
        }
        aq.params = params;
        return aq.getJson().pipe(map(function (jo) {
            console.log('jadd login jo:', jo);
            if (jo['data'].sessionId) {
                _this.configs.storage.set('crmId', jo['data'].sessionId);
                _this.memberService.crmId = jo['data'].sessionId;
            }
            _this.memberService.member = Data.toData(Member, jo['data']);
            return _this.memberService.member;
        }));
    };
    MemberApiService.prototype.FBloginOrRegistration = function (facebookId, token, join, storeId, brandId, marketing) {
        if (join === void 0) { join = false; }
        var url = '/api/v1/store/flogin.json';
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        var params = {
            facebookId: facebookId,
            token: token,
            join: join,
            brandId: brandId,
            marketing: marketing,
            storeId: storeId
        };
        if (join) {
            params['type'] = 'join';
        }
        aq.params = params;
        return aq.getJson().pipe(map(function (jo) { return Data.toData(Member, jo['data']); }));
    };
    MemberApiService.prototype.googleLogin = function (googleId, token) {
        var _this = this;
        var url = '/api/v1/store/glogin.json';
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        var params = { 'googleId': googleId, 'token': token };
        aq.params = params;
        return aq.getJson().pipe(map(function (jo) {
            _this.memberService.member = Data.toData(Member, jo['data']);
            return _this.memberService.member;
        }));
    };
    MemberApiService.prototype.alipayLogin = function (type, authCode) {
        var _this = this;
        var url = '/api/v1/store/alogin.json';
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        // type=alipayhkmini for now, maybe more in future
        var params = { 'type': type, 'authCode': authCode };
        aq.params = params;
        return aq.getJson().pipe(map(function (jo) {
            _this.memberService.member = Data.toData(Member, jo['data']);
            return _this.memberService.member;
        }));
    };
    MemberApiService.prototype.requestPassword = function (email, resetPageUrl) {
        var url = '/api/v1/store/password.json';
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        var params = { email: email, host: resetPageUrl, action: 'request' };
        aq.params = params;
        return aq.getJson().pipe(map(function (jo) { return jo; }));
    };
    MemberApiService.prototype.sendSms = function (countryCallingCodes, phone, store) {
        var url = '/api/v1/menu/member.json?verify=before';
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        var params = { countryCode: countryCallingCodes, phone: phone };
        if (store && store.brand && store.brand.crmId) {
            params['crmId'] = store.brand.crmId;
        }
        else if (store && store.crmId) {
            params['crmId'] = store.crmId;
        }
        aq.params = params;
        return aq.getJson().pipe(map(function (jo) { return jo; }));
    };
    MemberApiService.prototype.verifySms = function (smsCode, countryCallingCodes, phone) {
        var url = '/api/v1/menu/member/sms.json?action=verify';
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        var params = { countryCode: countryCallingCodes, code: smsCode, phone: phone };
        aq.params = params;
        return aq.getJson().pipe(map(function (jo) { return jo; }));
    };
    MemberApiService.prototype.requestResetCode = function (receivedAddress, type, countryCode, store, brand) {
        var url = '/api/v1/store/password.json';
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        var params = {};
        params['action'] = 'requestOtp';
        params['type'] = type;
        params[type] = receivedAddress;
        if (countryCode) {
            params['countryCode'] = countryCode;
        }
        if (store && store.brand && store.brand.crmId) {
            params['crmId'] = store.brand.crmId;
        }
        else if (store && store.crmId) {
            params['crmId'] = store.crmId;
        }
        else if (brand && !store) {
            params['crmId'] = brand.crmId;
        }
        aq.params = params;
        return aq.getJson().pipe(map(function (jo) { return jo; }));
    };
    MemberApiService.prototype.verifyResetCode = function (type, receivedAddress, token, countryCode) {
        var url = '/api/v1/store/password.json';
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        var params = {};
        params['action'] = 'verifyByOtp';
        params['type'] = type;
        params[type] = receivedAddress;
        params['token'] = token;
        if (countryCode) {
            params['countryCode'] = countryCode;
        }
        aq.params = params;
        return aq.getJson().pipe(map(function (jo) { return jo; }));
    };
    MemberApiService.prototype.resetPassword = function (password, confirmPassword, token) {
        var url = '/api/v1/store/password.json';
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        var params = {};
        params['action'] = 'resetByOtp';
        params['password'] = password;
        params['confirm'] = confirmPassword;
        params['token'] = token;
        aq.params = params;
        return aq.getJson().pipe(map(function (jo) { return jo; }));
    };
    MemberApiService.prototype.resetPasswordWithOldPassword = function (oldPassword, password, confirmPassword) {
        var url = '/api/v1/store/password.json';
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        var member = this.memberService.getMember();
        var params = {};
        params['action'] = 'resetInApp';
        params['original'] = oldPassword;
        params['password'] = password;
        params['confirm'] = confirmPassword;
        if (member) {
            params['session'] = member.sessionId;
        }
        aq.params = params;
        return aq.getJson().pipe(map(function (jo) { return jo; }));
    };
    MemberApiService.prototype.registeration = function (registerInfo) {
        var url = '/api/v1/menu/member.json';
        // var params = {
        //     email: email,
        //     password: password,
        //     token: token,
        //     storeId:storeId
        // };
        var params = registerInfo;
        var aq = this.aq;
        aq.url = url;
        aq.method = 'put';
        // aq.auth(true);
        aq.params = params;
        return aq.getJson().pipe(map(function (jo) { return Data.toData(Member, jo['data']); }));
    };
    MemberApiService.prototype.guestUpgradeMember = function (upgradeInfo) {
        var url = '/api/v1/menu/member.json';
        var params = upgradeInfo;
        var aq = this.aq;
        aq.url = url;
        aq.method = 'put';
        aq.auth(true);
        aq.params = params;
        return aq.getJson().pipe(map(function (jo) { return Data.toData(Member, jo['data']); }));
    };
    // pas = { email,phone , countryCode}
    MemberApiService.prototype.getIsRegister = function (brandId, storeId, pas) {
        var url = '/api/v1/login/check.json';
        var params = {};
        if (pas) {
            params = __assign({}, pas);
        }
        if (brandId) {
            params['brandId'] = brandId;
        }
        if (storeId) {
            params['storeId'] = storeId;
        }
        var aq = this.aq;
        aq.url = url;
        aq.method = 'put';
        // aq.auth(true);
        aq.params = params;
        return aq.getJson().pipe(map(function (jo) { return jo['data']; }));
    };
    MemberApiService.prototype.join = function (storeId, brandId, marketing, marketingOrderPlace) {
        if (marketingOrderPlace === void 0) { marketingOrderPlace = false; }
        var url = '/api/v1/reward/activity.json';
        var member = this.memberService.getMember();
        var headers = {};
        var params = {
            brandId: brandId,
            type: 'join',
            marketing: marketing,
            storeId: storeId,
            marketingOrderPlace: marketingOrderPlace
        };
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.params = params;
        if (member) {
            aq.auth(true);
        }
        return aq.getJson().pipe(map(function (jo) { return jo; }));
    };
    MemberApiService.prototype.editProfile = function (registerInfo) {
        var url = '/api/v1/menu/member.json';
        // var params = {
        //     email: email,
        //     password: password,
        //     token: token,
        //     storeId:storeId
        // };
        var params = registerInfo;
        var aq = this.aq;
        aq.url = url;
        aq.method = 'put';
        // aq.auth(true);
        aq.params = params;
        return aq.auth(true).getJson().pipe(map(function (jo) {
            var user = Data.toData(Member, jo['data']);
            return user;
        }));
    };
    MemberApiService.prototype.checkingMembership = function (brandId) {
        var _this = this;
        var url = '/api/v1/reward/membership.json?brandId=' + brandId;
        // let storeId = this.orderManager.store.id;
        // let mode = this.orderManager.mode;
        // let url = this.getOrderSesisonUrl(storeId,mode,'membership');
        // url = url + '&brandId=' + brandId;
        // todo change new api return val to old api val form
        var aq = this.aq;
        aq.url = url;
        aq.method = 'get';
        aq.auth(true);
        return aq.getJson().pipe(map(function (jo) {
            _this.memberService.hasMembership = jo['data'].length > 0;
            return jo['data'];
        }));
    };
    MemberApiService.prototype.getRedeemableRewards = function (brandId) {
        var url = "/api/v1/reward/reward.json?type=stamp&brandId=" + brandId;
        this.aq.url = url;
        this.aq.method = 'get';
        this.aq.auth(true);
        return this.aq.getJson().pipe(share(), map(function (jo) {
            return jo['data'];
        }));
    };
    MemberApiService.prototype.redeemReward = function (brandId, rewardId) {
        var url = "/api/v1/reward/redeemstamps.json?rewardId=" + rewardId + "&brandId=" + brandId;
        this.aq.url = url;
        this.aq.method = 'post';
        this.aq.auth(true);
        return this.aq.getJson().pipe(map(function (jo) {
            return jo['data'];
        }));
    };
    // current only for crystalJade
    // login with cardNo
    MemberApiService.prototype.loginWithCardNo = function (cardNo, brandId) {
        var url = "/api/v1/store/login.json";
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        var params = {};
        params['type'] = 'code';
        params['brandId'] = brandId;
        params['code'] = cardNo;
        aq.params = params;
        return aq.getJson().pipe(map(function (jo) {
            return jo['data'];
        }));
    };
    MemberApiService.prototype.guestLogin = function (session, brandId) {
        var _this = this;
        var url = '/api/v1/store/devicelogin.json';
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        var params = {};
        if (brandId) {
            params['brandId'] = brandId;
        }
        if (session) {
            params['deviceId'] = session;
        }
        aq.params = params;
        return aq.getJson().pipe(map(function (jo) {
            console.log('guest login result:', jo);
            jo['data']['sessionId'] = jo['sessionId'];
            _this.memberService.member = Data.toData(Member, jo['data']);
            return _this.memberService.member;
        }));
    };
    MemberApiService.prototype.putLottery = function (storeId, brandId, spot, rewardId, session, orderSessionId, batchId, orderId) {
        var url = '/api/v1/reward/lottery.json';
        var aq = this.aq;
        var params = {
            storeId: storeId,
            brandId: brandId,
            spot: spot,
            rewardId: rewardId,
            orderId: orderId
        };
        if (this.memberService.getMember()) {
            var member = this.memberService.getMember();
            if (member.membership) {
                params['memberId'] = member.membership.memberId;
                params['membershipId'] = member.membership.id;
            }
            else {
                params['memberId'] = member.id;
            }
        }
        else {
            params['memberId'] = 1;
            params['membershipId'] = 1;
        }
        params['session'] = session;
        if (orderSessionId.length > 0) {
            params['orderSessionId'] = orderSessionId;
        }
        aq.url = url;
        aq.method = 'put';
        aq.params = params;
        return aq.auth(true).getJson().map(function (jo) { return jo; });
    };
    // can member and non - member
    MemberApiService.prototype.playLottery = function (storeId, brandId, spot, session, orderTotal, orderSessionId, batchId, orderId, giveUpChance) {
        if (giveUpChance === void 0) { giveUpChance = false; }
        var url = '/api/v1/reward/lottery.json';
        var aq = this.aq;
        var params = {
            storeId: storeId,
            brandId: brandId,
            spot: spot,
            total: orderTotal
        };
        if (this.memberService.getMember()) {
            var member = this.memberService.getMember();
            if (member.membership) {
                params['memberId'] = member.membership.memberId;
                params['membershipId'] = member.membership.id;
            }
            else {
                params['memberId'] = member.id;
            }
        }
        else {
            params['memberId'] = 1;
            params['membershipId'] = 1;
        }
        params['session'] = session;
        if (orderSessionId.length > 0) {
            params['orderSessionId'] = orderSessionId;
        }
        if (batchId && batchId.length > 0) {
            params['batchId'] = batchId;
        }
        if (orderId.length > 0) {
            params['orderId'] = orderId;
        }
        if (giveUpChance) {
            params['giveUpChance'] = true;
        }
        aq.url = url;
        aq.method = 'get';
        aq.params = params;
        return aq.auth(true).getJson().map(function (jo) {
            var offer = Data.toData(Offer, jo['data']);
            return offer;
        });
    };
    MemberApiService.prototype.getBookmarks = function (brand) {
        var url = '/api/v1/store/bookmark.json';
        var params = {};
        // params["memberId"] = memebrId;
        params['brandId'] = brand.id;
        params['groupId'] = brand.groupId;
        params['type'] = 'Item';
        // params["source"] = "BYOD";
        // params["action"] = "getFav";
        params['locale'] = 'en';
        var aq = this.aq;
        aq.url = url;
        aq.method = 'get';
        aq.params = params;
        // aq.headers['sid'] = this.configs.getSessionId();
        return aq.auth(true).getJson().map(function (jo) {
            return jo['data'];
        });
    };
    MemberApiService.prototype.setBookmarkItem = function (itemId, brand) {
        var url = '/api/v1/store/bookmark.json';
        var params = {};
        // params["memberId"] = memebrId;
        params['brandId'] = brand.id;
        params['groupId'] = brand.groupId;
        params['type'] = 'Item';
        // params["source"] = "BYOD";
        // params["action"] = "createFav";
        params['contentId'] = itemId;
        params['locale'] = 'en';
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.params = params;
        // aq.headers['sid'] = this.configs.getSessionId();
        return aq.auth(true).getJson().map(function (jo) {
            return jo['data'];
        });
    };
    MemberApiService.prototype.deleteBookmarkItem = function (itemId, brand) {
        var url = '/api/v1/store/bookmark.json';
        var params = {};
        // params["memberId"] = memebrId;
        params['brandId'] = brand.id;
        params['groupId'] = brand.groupId;
        params['type'] = 'Item';
        // params["source"] = "BYOD";
        // params["action"] = "createFav";
        params['contentId'] = itemId;
        params['locale'] = 'en';
        params['favorite'] = false;
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.params = params;
        // aq.headers['sid'] = this.configs.getSessionId();
        return aq.auth(true).getJson().map(function (jo) {
            return jo['data'];
        });
    };
    MemberApiService.ngInjectableDef = i0.defineInjectable({ factory: function MemberApiService_Factory() { return new MemberApiService(i0.inject(i1.HttpClient), i0.inject(i2.ConfigService), i0.inject(i3.MemberService), i0.inject(i4.OrderManager)); }, token: MemberApiService, providedIn: "root" });
    return MemberApiService;
}(BaseService));
export { MemberApiService };
