<ion-content [ngClass]="{'isModalPopup': true}" class="content-signup-or-acc" (click)="dismissClicked()">
    <div class="signup-acc-height">
        <div class="div-signup-or-acc">
            <div class="div-login-signup" (click)="joinMembership();$event.stopPropagation();">
                <ion-icon class="icon-login-signup" src="assets/images/join_login.svg"></ion-icon>
                <div class="div-join-store">
                    <h6>{{"dialog.guest-login.has-logged-in" | translate}}</h6>
                    <h2>{{"dialog.guest-login.join-this-store" | translate}}</h2>
                    <div *ngIf="hasTAndC" class="has-tnc">
                       <h3> {{"dialog.guest-login.upon-joining-tips" | translate }}</h3>
                        <h3 class="tnc-link" (click)="justShowTC();$event.stopPropagation();">{{"pages.login.loginTC" | translate}}.</h3>
                    </div>
                </div>

                <div class="icon-arrow-rig">
                    <ion-icon name="ios-arrow-forward"></ion-icon>
                </div>
            </div>

            <div class="checkbox-row" (click)="$event.stopPropagation();">
                <ion-checkbox [(ngModel)]="marketing" name="marketing" mode="md"></ion-checkbox>
                <h6>{{'pages.terms-conditions.receive-brand-tnc2' | translate : TACKeyObj }}</h6>
            </div>

            <div class="div-cont-guest" (click)="logoutClicked();$event.stopPropagation();">
                <h4>{{"dialog.guest-login.logout" | translate}}</h4>
            </div>
        </div>
    </div>
</ion-content>