var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, Injector } from '@angular/core';
import { MobileBasePage } from 'src/app/core/base/mobile-base-page';
import { AddressDialog } from 'src/app/dialogs/address-dialog/address-dialog.component';
import { getSetLayout } from '../../utilities/layout-helper';
import { RouteTable } from 'src/app/core/route-table';
import { LoginModal } from 'src/app/dialogs/login-modal/login-modal.page';
var PageAction;
(function (PageAction) {
    PageAction[PageAction["ClickedMember"] = 1] = "ClickedMember";
    PageAction[PageAction["ClickedProfile"] = 2] = "ClickedProfile";
    PageAction[PageAction["ClickedMyOrder"] = 3] = "ClickedMyOrder";
    PageAction[PageAction["ClickedFavourite"] = 4] = "ClickedFavourite";
    PageAction[PageAction["ClickedCoupon"] = 5] = "ClickedCoupon";
    PageAction[PageAction["ClickedPromotion"] = 6] = "ClickedPromotion";
    PageAction[PageAction["ClickedHome"] = 7] = "ClickedHome";
})(PageAction || (PageAction = {}));
var MainSlideMenuComponent = /** @class */ (function (_super) {
    __extends(MainSlideMenuComponent, _super);
    function MainSlideMenuComponent(injector) {
        var _this = _super.call(this, injector) || this;
        _this.currentMode = 'dinein';
        _this.currentLanguage = 'zh';
        _this.pages = [];
        console.log('MainSlideMenuComponent', _this.memberService.getMember());
        _this.initPages();
        return _this;
    }
    MainSlideMenuComponent.prototype.changeLang = function () {
        console.log('changeLang');
    };
    MainSlideMenuComponent.prototype.logout = function () {
        var _this = this;
        console.log('logout');
        var confirm = this.genAlertButton('Confirm', function () {
            _this.toggleMenu();
            // logout
        });
        var cancel = this.genAlertButton('Cancel');
        this.showAlert(null, 'Are you sure Logout?', [cancel, confirm]);
    };
    MainSlideMenuComponent.prototype.login = function () {
        this.presentLoginModal({
            store: this.orderService.store,
            fromPage: 'ByodSplashPage',
            isRoute: false
        });
        this.toggleMenu();
    };
    MainSlideMenuComponent.prototype.presentLoginModal = function (params, callback) {
        return __awaiter(this, void 0, void 0, function () {
            var modal;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalController.create({
                            component: LoginModal,
                            componentProps: params,
                            showBackdrop: true,
                            backdropDismiss: false,
                            cssClass: 'modal-largescreen addressDialog-modal login-modal-splash'
                        })];
                    case 1:
                        modal = _a.sent();
                        modal.onDidDismiss().then(function (res) {
                            var data = res.data;
                            console.log("byod-splash dismiss popUpAdressModal", data);
                            if (data && data['close']) {
                                if (callback)
                                    callback();
                            }
                        });
                        modal.present().then(function () {
                            //  cb()
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    MainSlideMenuComponent.prototype.editAddress = function () {
        console.log('editAddress');
        this.popUpAdressModal({
            goToMenu: true,
            fromPage: 'ByodSplashPage',
            showModeSelect: true,
        });
        this.toggleMenu();
    };
    MainSlideMenuComponent.prototype.initPages = function () {
        this.pages = [
            // { title: 'MEMBER', index: 0, disabled: true, action: PageAction.ClickedMember, transalteStr: 'pages.main-slide-menu.member', onlyLoginShow: false , show: true},
            { title: 'PROFILE', index: 1, disabled: true, action: PageAction.ClickedProfile, transalteStr: 'pages.main-slide-menu.profile', onlyLoginShow: true, show: true },
            { title: 'MY CART', index: 2, disabled: false, action: PageAction.ClickedMyOrder, transalteStr: 'pages.main-slide-menu.my-cart', onlyLoginShow: false, show: true },
            // { title: 'FAVOURITE', index: 3, disabled: true, action: PageAction.ClickedFavourite, transalteStr: 'pages.main-slide-menu.favourite', onlyLoginShow: false , show: true},
            // { title: 'COUPON', index: 4, disabled: true, action: PageAction.ClickedCoupon, transalteStr: 'pages.main-slide-menu.coupon', onlyLoginShow: false , show: true},
            // { title: 'PROMOTION', index: 5, disabled: true, action: PageAction.ClickedPromotion, transalteStr: 'pages.main-slide-menu.promotion', onlyLoginShow: false , show: true},
            { title: 'HOME', index: 6, disabled: false, action: PageAction.ClickedHome, transalteStr: 'pages.main-slide-menu.home', onlyLoginShow: false, show: true }
        ];
    };
    MainSlideMenuComponent.prototype.isShowTab = function (boolean) {
        return boolean ? (this.memberService.getMember() && this.memberService.getMember()['method'] != 'device') : true;
    };
    MainSlideMenuComponent.prototype.ngOnInit = function () { };
    MainSlideMenuComponent.prototype.popUpAdressModal = function (params, callback) {
        return __awaiter(this, void 0, void 0, function () {
            var modal;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalController.create({
                            component: AddressDialog,
                            componentProps: params,
                            showBackdrop: true,
                            backdropDismiss: false,
                            cssClass: 'modal-largescreen addressDialog-modal'
                        })];
                    case 1:
                        modal = _a.sent();
                        modal.onDidDismiss().then(function (res) {
                            var data = res.data;
                            console.log('byod-splash dismiss popUpAdressModal', data);
                            if (data && data['close']) {
                                if (callback)
                                    callback();
                            }
                        });
                        modal.present().then(function () {
                            //  cb()
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    MainSlideMenuComponent.prototype.getAddressDisplay = function () {
        var address = this.addressManagerService.currentAddress;
        if (address.name && address.name.length > 0) {
            return address.name;
        }
        else {
            return address['street'];
        }
    };
    MainSlideMenuComponent.prototype.selectedMode = function (e) {
        var mode = e.detail.value;
        this.currentMode = mode;
        console.log('JJ: MainSlideMenuComponent -> selectedMode -> e', this.currentMode);
    };
    MainSlideMenuComponent.prototype.cartClicked = function () {
        if (false && !this.memberService.getMember() && (this.orderManager.mode === 'delivery' || this.orderManager.mode === 'takeaway')) {
            // beacuase of forcing login in blashpage so no need follow code
            console.log('not login and isDelivery ###', this.orderManager.getOrder());
            // if (this.orderManager.getOrder().orderitems.length > 0){
            //     // save the current Order
            //     this.configService.putPreference(this.orderManager.store.id + '.savedOrder', this.orderManager.getOrder());
            // }
            // popUp delivery-login within modal
            // this.popUpDeliveryLogin();
            // force to push delivery-login within route
            // this.pushByName('DeliveryLoginPage', {
            //     store: this.store,
            //     fromPage: 'CategoryListPage',
            //     isRoute: true
            // });
        }
        else {
            var page = getSetLayout(this.systemConfigService);
            if (page === 'ComboPage') {
                // new layout process
                if (this.orderService.isCourt ? this.multOrderManager.getOrders.length > 0 : this.orderManager.isEmpty()) {
                    var warning = this.t.instant('global.order-first');
                    var confirm_1 = this.t.instant('buttons.ok');
                    this.showAlert('', warning, [confirm_1]);
                }
                else {
                    this.pushByUrl((this.multOrderManager.enableMutlOrder ? RouteTable['MultiOrderCartPage'] : RouteTable['CartScrollPage']), { store: this.orderService.store });
                }
            }
            else {
                console.log('on cart clicked');
                this.googleAnalyticEventTrack('shopping cart');
                if (this.multOrderManager.enableMutlOrder && this.multOrderManager.getOrderCount() > 0) {
                    this.pushByUrl(RouteTable['MultiOrderCartPage']);
                }
                else if (!this.multOrderManager.enableMutlOrder && !this.orderManager.isEmpty()) {
                    this.pushByName('CartScrollPage', {}, { relativeTo: this.route.root.firstChild });
                }
                else {
                    var subtitle = this.t.instant('global.order-first');
                    var confirm_2 = this.t.instant('buttons.ok');
                    var alert_1 = this.alertController.create({
                        subHeader: subtitle,
                        buttons: [confirm_2]
                    });
                    alert_1.then(function (data) { return data.present(); });
                }
            }
        }
    };
    MainSlideMenuComponent.prototype.profileClicked = function () {
        if (this.systemConfigService.hideStampPage) {
            this.pushByName('ProfilePage');
        }
        else {
            this.pushByName('MemberRewardPage');
        }
    };
    MainSlideMenuComponent.prototype.openPage = function (page) {
        console.log('JJ: MainSlideMenuComponent -> openPage -> page', page);
        this.toggleMenu();
        var action = page.action;
        switch (action) {
            case PageAction.ClickedMember:
                break;
            case PageAction.ClickedProfile:
                this.profileClicked();
                break;
            case PageAction.ClickedMyOrder:
                this.cartClicked();
                break;
            case PageAction.ClickedFavourite:
                break;
            case PageAction.ClickedCoupon:
                break;
            case PageAction.ClickedPromotion:
                break;
            case PageAction.ClickedHome:
                this.backButtonClick();
                break;
        }
    };
    MainSlideMenuComponent.prototype.ionDidClose = function () {
        this.menuController.swipeEnable(false, this.MAIN_MENU);
    };
    MainSlideMenuComponent.prototype.ionDidOpen = function () {
        this.menuController.swipeEnable(true, this.MAIN_MENU);
    };
    return MainSlideMenuComponent;
}(MobileBasePage));
export { MainSlideMenuComponent };
