var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MobileBasePage } from '../../core/base/mobile-base-page';
import { Injector, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
var ExternalWebPage = /** @class */ (function (_super) {
    __extends(ExternalWebPage, _super);
    // constructor(public viewCtrl: ViewController, injector: Injector, params: NavParams,public startupService: StartupService
    function ExternalWebPage(injector, storage) {
        var _this = _super.call(this, injector) || this;
        _this.storage = storage;
        _this.body = '';
        console.log('ExternalWebPage');
        _this.body = _this.getNavParams('body');
        _this.url = _this.getNavParams('url');
        return _this;
    }
    ExternalWebPage.prototype.ngOnInit = function () {
        var _this = this;
        window.closeFrame = function (result) {
            console.log('external web result', result);
            _this.modalController.dismiss(result);
        };
        if (this.body) {
            this.secureFrame.nativeElement.contentWindow.document.write(this.body);
            this.secureFrame.nativeElement.contentWindow.document.close();
        }
        else if (this.url) {
            this.secureFrame.nativeElement.src = this.url;
        }
    };
    ExternalWebPage.prototype.dismiss = function (agree) {
        //this.secureFrame.nativeElement.src = window.location.origin + "/redirect?membercardid=1234";
        console.log('dismiss');
        if (agree) {
            this.modalController.dismiss();
        }
        else {
            this.modalController.dismiss();
        }
    };
    return ExternalWebPage;
}(MobileBasePage));
export { ExternalWebPage };
