/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i2 from "@ionic/angular";
import * as i3 from "./shared/components/main-slide-menu/main-slide-menu.component.ngfactory";
import * as i4 from "./shared/components/main-slide-menu/main-slide-menu.component";
import * as i5 from "@angular/common";
import * as i6 from "@angular/router";
import * as i7 from "./app.component";
import * as i8 from "./core/services/config.service";
import * as i9 from "./core/services/navigation.service";
import * as i10 from "@ngx-translate/core";
import * as i11 from "./core/services/session.service";
import * as i12 from "./core/services/check-version.service";
import * as i13 from "./core/services/browser.service";
import * as i14 from "./core/services/sdk-config-service";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { routerOutlet: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 7, "ion-app", [], null, null, null, i1.View_IonApp_0, i1.RenderType_IonApp)), i0.ɵdid(2, 49152, null, 0, i2.IonApp, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵeld(3, 0, null, 0, 1, "app-main-slide-menu", [], null, null, null, i3.View_MainSlideMenuComponent_0, i3.RenderType_MainSlideMenuComponent)), i0.ɵdid(4, 245760, null, 0, i4.MainSlideMenuComponent, [i0.Injector], null, null), (_l()(), i0.ɵeld(5, 16777216, [["outlet", 1]], 0, 3, "ion-router-outlet", [["id", "content"]], null, null, null, null, null)), i0.ɵdid(6, 278528, null, 0, i5.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵpod(7, { "wechat": 0, "fb": 1 }), i0.ɵdid(8, 212992, [[1, 4]], 0, i2.IonRouterOutlet, [i6.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], [8, null], i2.Config, i2.NavController, i5.Location, i0.ElementRef, i6.Router, i0.NgZone, i6.ActivatedRoute, [3, i2.IonRouterOutlet]], { swipeGesture: [0, "swipeGesture"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 4, 0); var currVal_0 = _ck(_v, 7, 0, _co.isWechat, _co.isFacebook); _ck(_v, 6, 0, currVal_0); var currVal_1 = false; _ck(_v, 8, 0, currVal_1); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 114688, null, 0, i7.AppComponent, [i8.ConfigService, i2.NavController, i9.NavigationService, i6.ActivatedRoute, i2.AlertController, i10.TranslateService, i2.MenuController, i11.SessionService, i6.Router, i2.Platform, i2.ModalController, i12.VersionCheckService, i2.ActionSheetController, i13.BrowserService, i14.SdkConfigService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i0.ɵccf("app-root", i7.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
