import {Injectable} from '@angular/core';
import {SdkBaseService} from './sdk-base-service';

export interface WeChatRegisterParams {
    appId: string
};

export interface WeChatPayParams {
    appId: string,
    partnerId: string,
    prepayId: string,
    packageValue: string,
    nonceStr: string,
    timeStamp: number,
    sign: string,
    wechat_android_debug?: boolean
};

@Injectable({providedIn: 'root'})
export class SdkWeChatPayService extends SdkBaseService {

    public registerApp(params: WeChatRegisterParams, callback) {
        if (!this.isAvailable()) {
            return;
        }

        this.callNative('registerApp', params, callback);
    }

    public requestPay(params: WeChatPayParams, callback) {

        if (!this.isAvailable()) {
            return;
        }

        this.callNative('requestPay', params, callback);

    }

    public getWeChatSdkVersion(params: any, callback) {

        if (!this.isAvailable()) {
            return;
        }

        this.callNative("getWeChatSdkVersion", params, callback);

    }

    protected getServiceName() {
        return 'WeChatPayService';
    }

}
