import {BrowserService} from './browser.service';
import {Charge, Data, Store, OrderManager} from 'aigens-ng-core';
import {AQuery} from '../base/aquery';
import {BaseService} from '../base/base-service';
import {ConfigService} from './config.service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import { OrderService } from './order.service';
import { SdkAlipayHKService } from './sdk-alipay-hk-service';
import { SdkWeChatPayService } from './sdk-wechat-pay-service';
import { SdkApplepayService } from './sdk-applepay-service';

declare var Stripe: any;
declare var window: any;
declare var ApplePaySession: any;

@Injectable({providedIn: 'root'})
export class PaymentService extends BaseService {

    aq: AQuery;
    stripe: any;
    stripeKey: string;
    hasActivePayment = false;
    supported: any = {};

    constructor(private http: HttpClient,
        private configService: ConfigService,
        private browserService: BrowserService,
        private orderManager: OrderManager,
        private orderService: OrderService,
        private sdkAlipayHKService: SdkAlipayHKService,
        private sdkWeChatPayService: SdkWeChatPayService,
        private sdkApplePayService: SdkApplepayService) {
        super();
        this.aq = new AQuery(http, configService);
        console.log('PaymentService');
    }


    preload(store: Store, callback: Function, force: boolean = false, charge?) {


        if (!store) {
            return;
        }
        if (!store.brand) {
            return;
        }
        if (!store.brand['payments']) {
            return;
        }
        let payments = store.brand['payments'];
        if (charge) {
            // sometimes only need load only one payment js
            payments = payments.filter(payment=> payment['method'] === charge['method']);
        }
        this.processPayments(payments, callback, store, force);
    }

    async processPayments(payments: any[], callback: Function, store: Store, force: boolean = false) {
        const loadedUrl = {};
        // payments.forEach(async payment => {
        for (let payment of payments) {
            const gateway = payment['gateway'];
            const param = {};
            let url: string;
            let callbackHandler: any;

            let isAlipayMini: boolean;
            try {
                isAlipayMini = await this.browserService.isAliMiniApp();
            } catch (err) {
                console.log(err);
            }

            if (gateway === 'stripe' && !isAlipayMini) {

                console.log('loading stripe');
                url = 'https://js.stripe.com/v3/';
                callbackHandler = () => {
                    console.log('script loaded');
                    this.prepare(store, callback, payment.key);
                };
            }

            if (gateway === 'wirecardV2' && payment['method'] === 'creditcard' && !isAlipayMini) {
                param['defer'] = '';
                // paymentHost: "https://wpp-test.wirecard.com.sg/"
                // let host = "https://wpp-test.wirecard.com";
                if (payment['paymentHost']) {
                    url = payment['paymentHost'] + '/loader/paymentPage.js';
                } else {
                    url = 'https://wpp-test.wirecard.com/loader/paymentPage.js';
                }

                callbackHandler = () => {
                    callback();
                };
                console.log('loading wirecardv2');
            }

            // enets-payment
            if (gateway === 'nets' && payment['method'] === 'netspay' && !isAlipayMini) {
                url = 'https://uat2.enets.sg/GW2/js/jquery-3.1.1.min.js';
                callbackHandler = () => {
                    callback();
                };
                console.log('loading netspay');
            }

            if (gateway === 'mpgs' && (payment['method'] === 'creditcard' || payment['method'] === 'session') && !isAlipayMini) {
                param['defer'] = '';

                let apiHost = payment['APIHost'];
                let version = payment['version'];
                url = (apiHost ? apiHost : 'https://ap-gateway.mastercard.com') + '/form/version/' + (version ? version : '48') + '/merchant/' + payment['merchantId'] + '/session.js';
                console.log('use mpgs host', apiHost ? apiHost : 'https://ap-gateway.mastercard.com');
                console.log('use mpgs version', version ? version : '48');
                callbackHandler = () => {
                    callback();
                };
                console.log('loading mpgs');

            }

            if (gateway === 'midTrans' && payment['method'] === 'creditcard' && !isAlipayMini) {
                /*   <script id="midtrans-script" type="text/javascript"
                   src="https://api.midtrans.com/v2/assets/js/midtrans-new-3ds.min.js"
                   data-environment="sandbox"
                   data-client-key="<INSERT YOUR CLIENT KEY HERE>"></script>*/
                param['id'] = 'midtrans-script';
                param['data-environment'] = payment['isSandbox'] ? 'sandbox' : 'production';
                param['data-client-key'] = payment['clientKey'];
                url = 'https://api.midtrans.com/v2/assets/js/midtrans-new-3ds.min.js';
                callbackHandler = () => {
                    callback();
                };

            }

            if (gateway === 'omise' && payment['method'] === 'creditcard' && !isAlipayMini) {

                console.log('loading stripe');
                url = 'https://cdn.omise.co/omise.js';
                callbackHandler = () => {
                    console.log('omise script loaded');
                };
            }

            if (gateway === 'wirecard' && (payment['method'] === 'creditcard' || payment['method'] === 'session')){
                param['defer'] = '';
                // var isPrd = this.configService.getConfig()['production'];
                console.log('payment host', payment);
                if (payment['paymentHost'])
                {
                    const paymentHost = payment['paymentHost'];
                    url = `${paymentHost}/engine/hpp/paymentPageLoader.js`;
                }else
                {
                    url = 'https://api.wirecard.com.sg/engine/hpp/paymentPageLoader.js';
                }
                
                callbackHandler = () => {
                    callback();
                };
                console.log('loading wirecard');
    
                }

            if (url && !loadedUrl[url]) {
                this.configService.loadScript(url, callbackHandler, param);
                loadedUrl[url] = true;
            } else if (force && callback) {
                callback();
            }

        }
    }

    courtPreload(p: any, callback: Function, store: any) {

        const payments = p;
        console.log('courtPreload payment', payments);
        this.processPayments(payments, callback, store);
    }

    getStripe() {
        return this.stripe;
    }


    prepare(store: Store, callback: Function, stripeKey?: string) {

        if (!store.pos) {
            return;
        }

        if (this.stripeKey) {
            this.checkPaymentMethodAvailable();
            return;
        }

        this.stripeKey = this.configService.get('stripe');
        if (stripeKey) {
            this.stripeKey = stripeKey;
        }

        console.log('stripe key...', this.stripeKey, stripeKey);

        const stripe = Stripe(this.stripeKey);

        const pos = store.pos;

        console.log('payments?', pos);

        this.stripe = stripe;

        this.checkPaymentMethodAvailable();


    }

    checkPaymentMethodAvailable() {
        const stripe = this.stripe;
        if (!stripe) {
            this.hasActivePayment = true;
            return;
        }

        const paymentRequest = stripe.paymentRequest({
            country: 'HK',
            currency: 'hkd',
            total: {
                label: 'Aigens',
                amount: 400
            }
        });
        paymentRequest.canMakePayment().then((result) => {
            console.log('can make payment', result);

            if (result) {
                this.hasActivePayment = true;
            } else {
                this.hasActivePayment = false;
            }
        });
    }

    listenToken(payName: string, store: Store, grandTotal: number, callback: Function) {

        let amount;
        if (store.currency === 'JPY') {
            amount = Math.floor(grandTotal * 1);
        } else {
            amount = Math.floor(grandTotal * 100);
        }
        const stripe = this.getStripe();

        const paymentRequest = stripe.paymentRequest({
            country: store.country.toUpperCase(),
            currency: store.currency.toLowerCase(),
            total: {
                label: payName,
                amount: amount
            }
        });

        this.listen(paymentRequest, callback);

        return paymentRequest;
    }

    payStripeCharge(token: string, charge: Charge): Observable<Charge> {

        const url = '/api/v1/pay/charge.json';

        const params = {};
        params['type'] = charge.type;
        params['token'] = token;
        params['amount'] = charge.amount;
        params['currency'] = charge.currency;
        params['groupId'] = '100';
        params['email'] = charge.email;
        params['subtype'] = charge.subtype;
        params['method'] = charge.method;

        if (charge.payeeId) {
            params['payeeId'] = charge.payeeId;
        }

        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.params = params;

        return aq.getJson().pipe(map(jo => Data.toData(Charge, jo['data'])));


    }

    canApplePay(): boolean {

        const aps = window.ApplePaySession;

        console.log('apple pay session', aps);

        if (aps) {
            if (aps.canMakePaymentsWithActiveCard) {
                return this.hasActivePayment;
            }
        }

        return false;
    }

    canAndroidPay(): boolean {

        if (this.browserService.ios) {
            return false;
        }

        if (window.PaymentRequest) {
            return this.hasActivePayment;
        }

        return false;
    }

    postApplePaySession(appleServerUrl: string, domain: string): Observable<any> {
        const url = this.configService.get('applePay'); // "https://pay-dot-aigensstoreapp.appspot.com/api/v1/pay/applesession.json";

        const body = {url: appleServerUrl, domain: domain};
        console.log(body);

        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.body = body;

        return aq.getJson().pipe(map(jo => jo));


    }

    private listen(paymentRequest, callback: Function) {

        const stripe = this.getStripe();

        paymentRequest.on('token', (ev) => {

            console.log('token!!', ev);

            callback(ev);


        });

        const elements = stripe.elements();
        const prButton = elements.create('paymentRequestButton', {
            paymentRequest: paymentRequest,
        });

        // Check the availability of the Payment Request API first.
        paymentRequest.canMakePayment().then((result) => {

            console.log('can make payment?', result);

            if (result) {
                this.supported = result;
                console.log('can make payment', result);
                prButton.mount('#payment-request-button');
                console.log('prButton', prButton);
            } else {
                // document.getElementById('payment-request-button').style.display = 'none';
                console.log('cannot pay');
            }
        });
    }

    // TODO cross check store gateway and supported methods
    async getPaymentMethods(payments: any[]) {
        console.log(this.browserService.browser);
        let methods = {};

        for (let payment of payments) {
            if (payment['id'] && payment['id'].indexOf('-sync-') !== -1) {
                console.log('pos sync payment, ignore', payment);
                continue;
            }
            console.log('checking payment', payments);
            let pos = payment['gateway'] === 'pos';
            if (pos && payment['method'] === 'cash') {
                //
            } else if (pos && payment['method'] !== 'delay') {
                continue;
            }
            // if (pos && payment["method"] === "delay" && !payment["label"]) continue;

            let takeaway = this.orderManager.mode === 'takeaway';
            let dinein = this.orderManager.mode === 'dinein';
            let pickup = takeaway || dinein;

            let isWeChat = this.browserService.isWeChat() || this.browserService.isWeChatHK();
            // if(isWeChat && payment["method"] != "wechat") continue;

            let isAlipay = this.browserService.isAlipay() || this.browserService.isAlipayHK();
            let isAlipayMini: boolean;
            try {
                isAlipayMini = await this.browserService.isAliMiniApp();
            } catch (err) {
                console.log(err);
            }
            // if(isAlipay && payment["method"] != "alipay") continue;

            if (payment['method'] === 'creditcard' && !isAlipayMini) {
                methods['creditcard'] = payment;
            }
            if (payment['method'] === 'amex' && payment['gateway'] === 'nets' && payment['sub'] === 'web') {
                methods['amex-netspay'] = payment;
            }

            // if (payment['method'] === 'netspay' && pickup && !isWeChat && !isAlipay) {
            if (payment['method'] === 'netspay' && !isWeChat && !isAlipay) {
                // need to change netspay to the same as wirecard(by ray)
                methods['netspay'] = payment;
            }

            if (payment['method'] === 'session' && !isAlipay && !isWeChat) {
                methods['session'] = payment;
            }

            if (payment['method'] === 'gopay' && !isAlipay && !isWeChat) {
                methods['gopay'] = payment;
            }

            if (payment['method'] === 'mpayment' && !isAlipay && !isWeChat) {
                methods['mpayment'] = payment;
            }
            if (payment['method'] === 'cod') {
                methods['cod'] = payment;
            }

            // if (payment['method'] === 'point') {
            //     methods['session'] = payment;
            // }

            if (payment['method'] === 'wechat' && !isAlipay) {
                let subMethod = payment['sub'];
                if (subMethod === 'mini') {
                    if (this.browserService.isWeChatMiniProgramWebview()) {
                        methods['wechat-mini'] = payment;
                    } else {
                        break;
                    }
                }
                let canAdd = (this.orderService.isStaff && subMethod === 'scan') || (!this.orderService.isStaff && subMethod !== 'scan' && isWeChat);
                if (canAdd) {
                    methods['wechat'] = payment;

                }

            } else if (payment['method'] === 'alipay' && !isWeChat) {
                let subMethod = payment['sub'];

                if (subMethod === 'mini' && isAlipayMini) {
                    methods['alipay-mini'] = payment;
                }
                else if (payment['gateway'] === 'techtrans' && !isAlipay) {
                    // Tech-Trans Alipay quirk, can not use outside Alipay Browser
                    continue;
                }
                else if ((this.orderService.isStaff && subMethod === 'scan') || (!this.orderService.isStaff && subMethod !== 'scan' && subMethod !== 'mini' && !isAlipayMini)) {
                    // new ,for alipay method but, gateway is eft-cn / eft-hk
                    let key = 'alipay.' + payment['gateway'];
                    methods[key] = payment;
                }
            }

            if (payment['method'] === 'alipay-app' && this.sdkAlipayHKService.isAvailable() && !isWeChat) {
                if (payment['gateway'] === 'alipay-cn') {
                    methods['alipay-app-cn'] = payment;

                } else if (payment['gateway'] === 'alipay-hk') {
                    methods['alipay-app-hk'] = payment;

                }
            }

            if (payment['method'] === 'wechat-app' && this.sdkWeChatPayService.isAvailable()) {
                if (payment['gateway'] === 'eft-cn') {
                    methods['wechat-app.eft-cn'] = payment;

                } else if (payment['gateway'] === 'eft-hk') {
                    methods['wechat-app.eft-hk'] = payment;

                }
            }

            if (payment['method'] === 'oepay' && !isAlipay && !isWeChat) {

                methods['oepay'] = payment;
            }

            if (((this.canApplePay() || this.sdkApplePayService.isAvailable()) && !isWeChat && !isAlipay) && payment['method'] === 'apple') {
                methods['apple'] = payment;

            } else if ((this.browserService.isSupportNativeGooglePay() || (this.canAndroidPay()) && !isWeChat && !isAlipay) && payment['method'] === 'google') {
                console.log('is android pay', payment);
                methods['android'] = payment;
            }

            if (payment['method'] === 'cash' && !isAlipayMini) {
                let canAdd = !this.orderManager.store.brand.hasFeature('staff-cash') || (this.orderService.isStaff && this.orderManager.store.brand.hasFeature('staff-cash'));
                if (canAdd) {
                    methods['cash'] = payment;

                }
            }

            if ((payment['method'] === 'delaypayment' || payment['method'] === 'delay') && !isAlipayMini) {
                methods['delay'] = payment;
            }
        }


        console.log('pay methods', methods);

        return methods;

    }


}
