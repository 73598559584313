var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnDestroy, OnInit } from '@angular/core';
import { MobileBasePage } from 'src/app/core/base/mobile-base-page';
var OrderHelpModal = /** @class */ (function (_super) {
    __extends(OrderHelpModal, _super);
    function OrderHelpModal(injector) {
        return _super.call(this, injector) || this;
    }
    OrderHelpModal.prototype.dismissClicked = function (params) {
        console.log('OrderHelpModal closing');
        this.modalController.dismiss(params);
    };
    return OrderHelpModal;
}(MobileBasePage));
export { OrderHelpModal };
