var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, Injector, ChangeDetectorRef } from '@angular/core';
import { MobileBasePage } from 'src/app/core/base/mobile-base-page';
import { Validators, FormBuilder, FormControl } from '@angular/forms';
import { Address } from 'aigens-ng-core';
import { StoreService } from 'src/app/core/services/store.service';
import { NavParams } from '@ionic/angular';
var SearchAddressPage = /** @class */ (function (_super) {
    __extends(SearchAddressPage, _super);
    function SearchAddressPage(injector, formBuilder, storeService, navParams) {
        var _this = _super.call(this, injector) || this;
        _this.formBuilder = formBuilder;
        _this.storeService = storeService;
        _this.navParams = navParams;
        _this.detectingGps = false;
        _this.componentMap = {};
        _this.displayMain = false;
        _this.showSearchAddressUI = false;
        _this.notBackToPickupSegment = false;
        // address list ui
        _this.addressArr = [];
        _this.pickupAddressGroups = [];
        _this.pickupArr = [];
        _this.isRemove = false;
        _this.segmentOption = 'pickup';
        _this.displayName = '';
        _this.hideLogin = false;
        _this.hideDeliveryTab = false;
        _this.outOfDistance = false; // save the new add address is valid
        _this.ref = injector.get(ChangeDetectorRef);
        _this.showSearchAddressUI = _this.navParams.get('showSearchAddressUI');
        _this.notBackToPickupSegment = _this.navParams.get('notBackToPickupSegment');
        console.log("showSearchAddressUI", _this.showSearchAddressUI);
        console.log("notBackToPickupSegment", _this.notBackToPickupSegment);
        _this.brandId = _this.navParams.get('brandId');
        _this.initAddressArr();
        return _this;
    }
    SearchAddressPage.prototype.ngOnInit = function () {
        this.country = this.navParams.get('country') || 'HK';
        this.countryName = this.showCountryName();
        this.enableMapSearch = this.country.toLowerCase() !== 'sg';
        console.log('map search?', this.enableMapSearch);
        if (this.showSearchAddressUI)
            this.getAddressField();
        if (!google) {
            console.log('no google map');
            return;
        }
        // this.getLocation();
        this.initAutoComplete();
        this.setStyle();
    };
    SearchAddressPage.prototype.initAddressArr = function () {
        this.addressArr = this.addressManagerService.getAddresses(null, true);
    };
    SearchAddressPage.prototype.ionViewDidEnter = function () {
        this.autoFocus();
    };
    SearchAddressPage.prototype.autoFocus = function () {
        var _this = this;
        setTimeout(function () {
            if (_this.country.toLowerCase() === 'sg') {
                // postal input
                var postal = document.getElementById('postal');
                if (postal)
                    postal.focus();
            }
            else {
                var address = document.getElementById('address');
                if (address)
                    address.focus();
            }
        }, 100);
    };
    SearchAddressPage.prototype.setStyle = function () {
        var countryInput = document.getElementById('countryInput');
        if (countryInput)
            countryInput.classList.add("country-input");
    };
    SearchAddressPage.prototype.getAddressField = function () {
        var _this = this;
        if (this.brandId) {
            this.loading(true);
            this.storeService.getStoreRegionsByBandId(this.brandId).subscribe(function (res) {
                _this.loading(false);
                if (res) {
                    // save the regions data
                    console.log('getAddressField', res);
                    if (res.form) {
                        var addressFields = res.form.addressFields;
                        _this.searchInfo = res.form.addressFields.find(function (field) { return field.input === "gmap"; });
                        console.log(_this.searchInfo);
                        _this.addressFields = addressFields.filter(function (field) { return field.name != null; });
                        _this.addressFields.forEach(function (field) {
                            if (field.editable === undefined) {
                                field.editable = true;
                            }
                            console.log(field.name, ':', field.editable);
                        });
                        _this.initValidationsFormByData(addressFields);
                    }
                    else {
                        // this.showAlert('', 'server return date not exsit form including addressFields to create the form');
                    }
                }
            }, function (err) {
                _this.loading(false);
                _this.showError(err);
                // todo  handle this condition
            });
        }
    };
    SearchAddressPage.prototype.clearAddress = function () {
        var address = document.getElementById('address');
        address.value = '';
    };
    SearchAddressPage.prototype.clearPostalCode = function () {
        this.postalCode = null;
    };
    SearchAddressPage.prototype.initValidationsFormByData = function (addressFields) {
        if (!addressFields)
            return;
        var formControlsConfig = {};
        var formMessage = {};
        addressFields && addressFields.map(function (field) {
            if (!field)
                return;
            var formControl, itemInputMessage = [];
            if (field.required) {
                formControl = new FormControl('', Validators.compose([Validators.required]));
                itemInputMessage.push({ type: 'required', message: '' });
            }
            else {
                formControl = new FormControl('');
            }
            if (field.name) {
                formControlsConfig[field.name] = formControl;
                if (itemInputMessage.length > 0) {
                    formMessage[field.name] = itemInputMessage;
                }
            }
        });
        console.log('formControlsConfig', formControlsConfig, formMessage);
        this.validations_form = this.formBuilder.group(formControlsConfig);
        this.validation_messages = formMessage;
    };
    /**SG address system */
    SearchAddressPage.prototype.postalCodeCheck = function (ev) {
        var _this = this;
        console.log('postalCodeCheck', ev && ev.detail && ev.detail.value);
        var postal = ev.detail.value;
        if (!postal)
            return;
        if (postal.length !== 6)
            return;
        this.loading(true);
        this.addressManagerService.updateFormByPostal(postal, this.country).subscribe(function (res) {
            console.log('updateFormByPostal', res);
            var pos = {
                lat: res.latitude,
                lng: res.longitude
            };
            _this.displayMain = true;
            if (!_this.map) {
                _this.initMap(pos);
            }
            else {
                _this.setCenter(pos);
            }
            _this.loading(false);
            if (res) {
                _this.setValidationsFormValue(res);
            }
        }, function (err) {
            _this.showError(err);
            _this.loading(false);
        });
    };
    SearchAddressPage.prototype.setValidationsFormValue = function (object) {
        this.validations_form_set_value = object;
        var val = this.validations_form.getRawValue();
        var newVal = {};
        console.log('form val', val);
        for (var key in val) {
            if (val.hasOwnProperty(key)) {
                var elment = object[key];
                if (!elment) {
                    newVal[key] = '';
                }
                else {
                    newVal[key] = elment;
                }
            }
        }
        console.log('newVal', newVal);
        this.validations_form.setValue(newVal);
    };
    SearchAddressPage.prototype.submitClicked = function (fromValue) {
        console.log('add address clicking', fromValue);
        if (this.checkBuildingIsValid()) {
            this.postAddress(fromValue);
        }
        else {
            this.popUpTooFarAlert(fromValue);
        }
    };
    SearchAddressPage.prototype.checkBuildingIsValid = function () {
        //
        // if (true){
        //   this.outOfDistance = true;
        //   return false;
        // }
        return true;
    };
    SearchAddressPage.prototype.popUpTooFarAlert = function (fromValue) {
        return __awaiter(this, void 0, void 0, function () {
            var alert;
            var _this = this;
            return __generator(this, function (_a) {
                alert = this.alertController.create({
                    header: this.t.instant('dialog.address-dialog.add-alert-title'),
                    message: this.t.instant('dialog.address-dialog.add-alert-message'),
                    buttons: [
                        {
                            text: this.t.instant('dialog.address-dialog.add-alert-btn-no'),
                            role: 'no',
                            cssClass: '',
                            handler: function (data) {
                                console.log('no clicking', data);
                            }
                        }, {
                            text: this.t.instant('dialog.address-dialog.add-alert-btn-add'),
                            role: 'add',
                            cssClass: '',
                            handler: function (data) {
                                _this.outOfDistance = false;
                                console.log('add clicking', data);
                                _this.postAddress(fromValue);
                            }
                        }
                    ]
                });
                alert.then(function (data) { return data.present(); });
                return [2 /*return*/];
            });
        });
    };
    // all
    SearchAddressPage.prototype.postAddress = function (formValue) {
        var _this = this;
        this.loading(true);
        formValue['geocode'] = true;
        formValue['type'] = 'shipping'; // for server. only one option
        formValue.unit = formValue.floor ? formValue.unit + ',' + formValue.floor : formValue.unit;
        delete formValue.floor;
        if (!formValue.name) {
            formValue.name = formValue.street;
        }
        this.addressManagerService.postAddress(formValue, this.country).subscribe(function (result) {
            _this.loading(false);
            if (result) {
                _this.showToast(_this.t.instant('dialog.address-dialog.address-added'));
                console.log('postAddress result', result);
                _this.updateAddress(result);
                // this.getAddress && this.getAddress();
                _this.addressManagerService.isAddAddress = true;
                // this.closeClicked();
                var position = _this.addressManagerService.getPositionByAddress(result);
                console.log("searching address position", position);
                if (_this.orderManager.store) {
                    if (result.id) {
                        // 如果店铺存在 就 call api to getCouriers to confirm 是否可以配送
                        _this.loading(true);
                        _this.storeService.getCouriers(_this.orderManager.store.id, _this.orderManager.store.country, result.id).subscribe(function (data) {
                            _this.loading(false);
                            console.log('SearchAddressPage postAddress getCouriers', data);
                            _this.addressManagerService.sameAddressCouriersData = data;
                            _this.handlingCouriers(data, true, true, function () {
                                // save the.addressManagerService.currentAddress
                                var address = _this.addressManagerService.findAddress(result.id);
                                _this.addressManagerService.saveCurrentAddress(address);
                            });
                        }, function (err) {
                            _this.loading(false);
                            _this.showError(err);
                        });
                    }
                }
                else {
                    if (position) {
                        var address = _this.addressManagerService.findAddress(result.id);
                        _this.addressManagerService.saveCurrentAddress(address);
                        _this.modalController.dismiss({ position: position, deliveryStoreOnlyShowOne: true, notBackToPickupSegment: _this.notBackToPickupSegment });
                    }
                }
            }
        }, function (err) {
            _this.loading(false);
            _this.showError(err);
        });
    };
    SearchAddressPage.prototype.updateAddress = function (result) {
        this.addressManagerService.addressArr.unshift(result);
        var newAddresses = this.addressManagerService.calAddressesDistance(this.addressManagerService.addressArr, this.orderManager.store);
        this.orderSessionService.setLocalAddress(newAddresses);
        this.configService.setLocal('addressArrOfStoreList', newAddresses);
        this.addressArr = newAddresses;
    };
    // show the country/region text
    SearchAddressPage.prototype.showCountryName = function () {
        console.log('ly: -> add-address.page.ts -> showCountryName() -> country:', this.country);
        var countryObj = {
            'HK': this.t.instant('dialog.address-dialog.hongkong'),
            'SG': this.t.instant('dialog.address-dialog.singapore'),
            'ID': this.t.instant('dialog.address-dialog.indonesia'),
        };
        if (this.country) {
            return countryObj[this.country] || this.country;
        }
        else {
            return countryObj['HK'];
        }
    };
    SearchAddressPage.prototype.getLocation = function () {
        var _this = this;
        if (!this.enableMapSearch) {
            return;
        }
        if (this.detectingGps) {
            return;
        }
        if (navigator.geolocation) {
            this.detectingGps = true;
            navigator.geolocation.getCurrentPosition(function (position) {
                _this.detectingGps = false;
                console.log('Current position', position);
                var pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
                _this.initMap(pos);
            });
        }
    };
    SearchAddressPage.prototype.initMap = function (pos) {
        this.map = new google.maps.Map(document.getElementById('map'), {
            center: pos,
            zoom: 13,
            fullscreenControl: false,
            disableDefaultUI: true
        });
        var circle = new google.maps.Circle({
            center: pos,
        });
        if (this.enableMapSearch) {
            this.autocomplete.setBounds(circle.getBounds());
        }
        this.map.setCenter(pos);
        this.marker = new google.maps.Marker({
            position: pos,
            map: this.map
        });
        this.searchPlaces(pos);
    };
    SearchAddressPage.prototype.searchPlaces = function (pos) {
        var geocoder = new google.maps.Geocoder;
        geocoder.geocode({ 'location': pos }, function (results, status) {
            if (status === 'OK') {
                console.log(results);
                if (results[0]) {
                }
                else {
                    console.log('no result found');
                }
            }
            else {
                console.log('Geocoder failed due to: ' + status);
            }
        });
    };
    SearchAddressPage.prototype.initAutoComplete = function () {
        var _this = this;
        if (!this.enableMapSearch) {
            return;
        }
        var options = {
            type: ['address'],
            componentRestrictions: { country: this.country.toLowerCase() }
        };
        var autocomplete = new google.maps.places.Autocomplete(document.getElementById('address'), options);
        autocomplete.addListener('place_changed', function () {
            _this.placeChanged();
        });
        this.autocomplete = autocomplete;
    };
    SearchAddressPage.prototype.placeChanged = function () {
        var _this = this;
        if (!this.displayMain) {
            this.displayMain = true;
        }
        this.place = this.autocomplete.getPlace();
        console.log(this.place);
        document.getElementById("map").style.display = "inline";
        if (!this.map) {
            this.initMap(this.place.geometry.location);
        }
        else {
            this.setCenter(this.place.geometry.location);
        }
        var components = [];
        components = this.place.address_components;
        this.componentMap = {};
        components.forEach(function (component) {
            _this.componentMap[component.types[0]] = component.long_name;
        });
        console.log(this.componentMap);
        this.validations_form.reset();
        var obj = {};
        this.addressFields.forEach(function (addressField) {
            obj[addressField['name']] = _this.componentMap[addressField['component']];
        });
        this.setValidationsFormValue(obj);
        this.detectChanges();
    };
    SearchAddressPage.prototype.setCenter = function (pos) {
        if (this.marker) {
            this.marker.setMap(null);
        }
        this.marker = new google.maps.Marker({
            position: pos,
            map: this.map
        });
        if (this.map) {
            this.map.setCenter(pos);
        }
    };
    SearchAddressPage.prototype.detectChanges = function () {
        var _this = this;
        setTimeout(function () {
            _this.ref.detectChanges();
        }, 10);
    };
    SearchAddressPage.prototype.closeClicked = function () {
        // modal
        if (this.showSearchAddressUI) {
            if (this.addressArr && this.addressArr.length > 0) {
                this.showSearchAddressUI = false;
            }
            else {
                this.modalController.dismiss({ notBackToPickupSegment: this.notBackToPickupSegment });
            }
        }
        else {
            if (!this.isRemove) {
                this.modalController.dismiss({ notBackToPickupSegment: this.notBackToPickupSegment, close: true });
            }
            else {
                this.isRemove = false;
            }
        }
    };
    SearchAddressPage.prototype.deleteHandle = function () {
        if (this.currentAddressId) {
            this.popUpRemoveAlert(this.currentAddressId);
        }
    };
    SearchAddressPage.prototype.popUpRemoveAlert = function (addressId) {
        return __awaiter(this, void 0, void 0, function () {
            var alert;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertController.create({
                            // header: 'Alert',
                            // subHeader: 'Subtitle',
                            message: 'Are you sure you want to remove this address?',
                            backdropDismiss: false,
                            buttons: [
                                {
                                    text: 'Keep',
                                    handler: function () {
                                        console.log('Keep clicked');
                                    }
                                }, {
                                    text: 'Remove',
                                    handler: function () {
                                        console.log('Remove clicked');
                                        _this.postRemoveAddress(addressId);
                                    }
                                }
                            ]
                        })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SearchAddressPage.prototype.postRemoveAddress = function (addressId) {
        var _this = this;
        this.loading(true);
        if (addressId)
            this.addressManagerService.deleteAddress(addressId).subscribe(function (result) {
                console.log('deleteAddress() result:', result);
                _this.loading(false);
                _this.addressArr = _this.addressManagerService.addressArr;
                // 修改本地 addressArr
                _this.orderSessionService.setLocalAddress(_this.addressManagerService.addressArr);
                // 修改本地 addressArrOfStoreList
                _this.configService.setLocal('addressArrOfStoreList', _this.addressManagerService.addressArr);
                _this.isRemove = false;
                if (_this.addressManagerService.currentAddress && addressId === _this.addressManagerService.currentAddress.id) {
                    console.log('remove the selecting address');
                    _this.addressManagerService.setIsChanged(true);
                    _this.currentAddressId = null;
                    _this.addressManagerService.currentAddress = null;
                }
            }, function (err) {
                _this.loading(false);
                return _this.showAlert(err.status, err['error']);
            });
    };
    SearchAddressPage.prototype.addNewAdress = function () {
        console.log('addNewAdress cliking');
        this.showSearchAddressUI = true;
        this.getAddressField();
    };
    SearchAddressPage.prototype.isAddressNull = function () {
        return !(!!this.addressManagerService.currentAddress);
    };
    SearchAddressPage.prototype.addressConfirmClicked = function () {
        var _this = this;
        var position = this.addressManagerService.getPositionByAddressId(this.currentAddressId);
        console.log("currentAddress position", position);
        // let paramsObj = {};
        // paramsObj = { addressId: this.currentAddressId }
        if (this.orderManager.store) {
            if (this.currentAddressId) {
                // 如果店铺存在 就 call api to getCouriers to confirm 是否可以配送
                this.loading(true);
                this.storeService.getCouriers(this.orderManager.store.id, this.orderManager.store.country, this.currentAddressId).subscribe(function (data) {
                    _this.loading(false);
                    console.log('SearchAddressPage addressConfirmClicked getCouriers', data);
                    _this.addressManagerService.sameAddressCouriersData = data;
                    _this.handlingCouriers(data, true, false, function () {
                        // save the.addressManagerService.currentAddress
                        var address = _this.addressManagerService.findAddress(_this.currentAddressId);
                        _this.addressManagerService.saveCurrentAddress(address);
                    });
                }, function (err) {
                    _this.loading(false);
                    _this.showError(err);
                });
            }
        }
        else {
            if (position) {
                // call api to get 可以配送的 store[]
                var address = this.addressManagerService.findAddress(this.currentAddressId);
                this.addressManagerService.saveCurrentAddress(address);
                this.modalController.dismiss({ position: position, deliveryStoreOnlyShowOne: true, notBackToPickupSegment: this.notBackToPickupSegment });
            }
        }
    };
    SearchAddressPage.prototype.handlingCouriers = function (data, changed, isDismiss_notCoverCurrentAddress, callback) {
        if (data && data[0] && data[0]['couriers'] && data[0]['couriers'].length > 0) {
            var rules = data[0]['couriers'];
            this.addressManagerService.setPriceRulesOfCurrentAddress(rules);
            // comfire btn click save the address is change
            this.addressManagerService.setIsChanged(changed);
            if (callback)
                callback();
            this.modalController.dismiss({ canDelivery: true });
        }
        else {
            // 
            this.showAlert('', this.t.instant('pages.cart.not-delivered'));
            if (this.orderManager.store && isDismiss_notCoverCurrentAddress) {
                this.modalController.dismiss({ canDelivery: false });
            }
        }
    };
    return SearchAddressPage;
}(MobileBasePage));
export { SearchAddressPage };
