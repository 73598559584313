<ion-header>


    <ion-title>Stamp Redeem</ion-title>
    <div (click)="dismiss()" slot="end">
      <ion-icon name="close"></ion-icon>
    </div>
  

  <!-- <ion-toolbar>
    <ion-title>Stamp Redeem</ion-title>
    <ion-buttons (click)="dismiss()">
      <ion-icon name="close"></ion-icon>
    </ion-buttons>
  </ion-toolbar> -->
</ion-header>

<ion-content class="theme">
  <div class="container">
    <div class="img-box">
      <img [src]="getImageSrc()">
  
    </div>

    <div class="reward-desc">
      <h2>{{reward.name}}</h2>
      <h3 *ngIf="reward && reward.desc">{{reward.desc}}</h3>
      <button *ngIf="stampStatus === 'canRedeem'" ion-button block class="cta-btn" (click)="redeemClick()">
        <ion-icon name="ribbon"></ion-icon>
        <span>Redeem Now</span>
      </button>
      <div *ngIf="stampStatus === 'noStampGiftPoint'" ion-button block class="notice-cannot-redeem">
        <ion-icon name="sad"></ion-icon>
        <span>You need more stamps to redeem</span>
      </div>
      <div *ngIf="stampStatus === 'redeemed'" ion-button block class="notice-redeemed">
          <!-- <img src ='assets/imgs/icon_tick.svg'>  -->
          <ion-icon name="checkmark"></ion-icon>
        <span>Redeemed</span>
      </div>


    </div>
  </div>
</ion-content>