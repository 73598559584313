import { Injectable, Injector } from '@angular/core';
import { BaseService } from '../base/base-service';
import { Order, OrderManager, Crm, MathUtils } from 'aigens-ng-core';
import { MemberService } from './member.service';

@Injectable({
    providedIn: 'root'
})
export class PointsService extends BaseService{
    orderManager: OrderManager;

    order: Order;
    pointRatio: number = 0.1;
    pointToMoneyRatio: number;
    redeemPointFactor: number;
    redeemPointMin: number;
    minChargeAfterPoint: number;
    pointsEarned: number=0;
    isPayable: boolean = false;

    constructor(public memberService: MemberService,private injector: Injector){
        super();
        this.orderManager = injector.get(OrderManager);
        this.order = this.orderManager.order;
        let crm: Crm = this.orderManager.store && this.orderManager.store.crm;
        if (crm) {
            this.pointRatio = crm.pointRatio;
            this.pointToMoneyRatio = crm.pointToMoneyRatio;
            this.redeemPointFactor = crm.redeemPointFactor;
            this.redeemPointMin = crm.redeemPointMin; // TODO use this para?
            this.minChargeAfterPoint = crm['minChargeAfterPoint'];
        }

    }
    init() {
        let crm: Crm = this.orderManager.store && this.orderManager.store.crm;
        if (crm) {
            this.pointRatio = crm.pointRatio;
            this.pointToMoneyRatio = crm.pointToMoneyRatio;
            this.redeemPointFactor = crm.redeemPointFactor;
            this.redeemPointMin = crm.redeemPointMin; // TODO use this para?
            this.minChargeAfterPoint = crm['minChargeAfterPoint'];
        }
    }

    getPointsPayment(){
        console.log('get order payments');
        return this.order.payments;
    }

    clearPointsPayment(){
        this.orderManager.order.payments = [];
    }

    /**TODO: set points payment for byod order */
    setPointsPayment(total){
        this.orderManager.order.payments = [];
        let pointsPayment = {
            type: 'points',
            payment: true,
            detail: {
                deduct: total * 100
            }
        };
        this.orderManager.order.payments.push(pointsPayment);
    }

    minCharge():number {
        let result = 0;
        if (this.minChargeAfterPoint) {
            result = this.minChargeAfterPoint;
        }
        return result;
    }

    getusedPointsToMoney(usedPoints=this.orderManager.order.usedPoints_toMoney): number{
        let pointsMoneyTotal = 0;
        pointsMoneyTotal = MathUtils.round(usedPoints / this.pointToMoneyRatio, 2);
        return pointsMoneyTotal;
    }

    earnPointsCal(){
        this.setPoints();
        let netAmount = this.orderManager.getNetAmount();
        let pointsMoney = this.getusedPointsToMoney(this.orderManager.order.usedPoints_toMoney); 
        let temp = Math.floor((netAmount - this.orderManager.order.tax - this.orderManager.order.serviceCharge - pointsMoney) / this.getCrmPointRatio());
        this.pointsEarned = temp >= 0 ? temp : 0;
    }

    getCrmPointRatio() {
        return this.pointRatio;
    }

    setPoints(){
        this.orderManager.pointsFactor = 0;
        if (!this.orderManager.order.usedPoints_toMoney) {
            this.orderManager.order.usedPoints_toMoney = 0;
        }
        let hasPoint = this.memberService.member && this.memberService.member.membership && this.memberService.member.membership.usablePoints;
        this.orderManager.order.usablePoints = hasPoint ? this.memberService.member.membership.usablePoints : 0;
    }

    payableTotal(total?: number,usedPoints?: number): number {
        let outstandingBal;
        if (total) {
            outstandingBal = total;
        } else {
            outstandingBal = this.orderManager.order.grandTotal;
        }
        let used = 0;
        if (usedPoints) {
            used = usedPoints;
        } else {
            used = this.orderManager.order.usedPoints_toMoney;
        }
        let pointsMoney = this.getusedPointsToMoney(used);
        outstandingBal -= pointsMoney;
        return MathUtils.round(outstandingBal, 2);
    }
}
