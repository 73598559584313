var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { BaseService } from '../base/base-service';
import { LoadingController } from '@ionic/angular';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
var LoadingService = /** @class */ (function (_super) {
    __extends(LoadingService, _super);
    function LoadingService(loadingController) {
        var _this = _super.call(this) || this;
        _this.loadingController = loadingController;
        _this.lastLoadingStatus = false;
        _this.existingLoading = false; // 唯一指示当前是否有loading的变量(大坑：由于继承和派生的原因 basepage中的变量不是唯一的)
        return _this;
    }
    LoadingService.prototype.loading = function (show, message, refresher) {
        if (message === void 0) { message = null; }
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        console.log('loading, show', show);
                        this.lastLoadingStatus = show;
                        if (!show) return [3 /*break*/, 2];
                        // if (this.refresher || this.loader) {
                        // not use 异步赋值的变量来判断是否存在 loading
                        if (refresher || this.existingLoading) {
                            return [2 /*return*/];
                        }
                        /*
                        this.loader = this.loadingController.create({
                            //content: 'Please wait...'
                        });*/
                        this.setExistingLoading(true);
                        _a = this;
                        return [4 /*yield*/, this.makeLoader(message)];
                    case 1:
                        _a.loader = _b.sent();
                        if (this.lastLoadingStatus) {
                            this.loader.present();
                        }
                        console.log('loading showed');
                        return [3 /*break*/, 5];
                    case 2:
                        if (!this.loader) return [3 /*break*/, 4];
                        // this.loader.dismiss();
                        return [4 /*yield*/, this.loader.dismiss()];
                    case 3:
                        // this.loader.dismiss();
                        _b.sent();
                        this.loader = null;
                        this.setExistingLoading(false);
                        _b.label = 4;
                    case 4:
                        if (refresher) {
                            refresher.complete();
                            refresher = null;
                        }
                        console.log('loading dismissed');
                        _b.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    LoadingService.prototype.setExistingLoading = function (bool) {
        this.existingLoading = bool;
    };
    LoadingService.prototype.makeLoader = function (message) {
        if (message === void 0) { message = null; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(message == null || message.length === 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.loadingController.create({})];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2: return [4 /*yield*/, this.loadingController.create({
                            message: message
                        })];
                    case 3: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    LoadingService.ngInjectableDef = i0.defineInjectable({ factory: function LoadingService_Factory() { return new LoadingService(i0.inject(i1.LoadingController)); }, token: LoadingService, providedIn: "root" });
    return LoadingService;
}(BaseService));
export { LoadingService };
