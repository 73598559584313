/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pickupTime.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/forms";
import * as i5 from "@angular/common";
import * as i6 from "./pickupTime";
var styles_PickupTimeDialog = [i0.styles];
var RenderType_PickupTimeDialog = i1.ɵcrt({ encapsulation: 0, styles: styles_PickupTimeDialog, data: {} });
export { RenderType_PickupTimeDialog as RenderType_PickupTimeDialog };
function View_PickupTimeDialog_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "picker-time"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "picker-text-time"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Time"])), (_l()(), i1.ɵeld(3, 0, null, null, 6, "ion-datetime", [["displayFormat", "HH:mm"], ["pickerFormat", "HH mm"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "ionChange"], [null, "ionBlur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ionBlur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4)._handleBlurEvent($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("ionChange" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4)._handleChangeEvent($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.myDate = $event) !== false);
        ad = (pd_2 && ad);
    } if (("ionChange" === en)) {
        var pd_3 = (_co.updateMyDate($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_IonDatetime_0, i2.RenderType_IonDatetime)), i1.ɵdid(4, 16384, null, 0, i3.SelectValueAccessor, [i1.ElementRef], null, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.SelectValueAccessor]), i1.ɵdid(6, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(8, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), i1.ɵdid(9, 49152, null, 0, i3.IonDatetime, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { displayFormat: [0, "displayFormat"], pickerFormat: [1, "pickerFormat"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "ion-icon", [["name", "md-arrow-dropdown"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(11, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.myDate; _ck(_v, 6, 0, currVal_7); var currVal_8 = "HH:mm"; var currVal_9 = "HH mm"; _ck(_v, 9, 0, currVal_8, currVal_9); var currVal_10 = "md-arrow-dropdown"; _ck(_v, 11, 0, currVal_10); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 8).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 8).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 8).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 8).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 8).ngClassValid; var currVal_5 = i1.ɵnov(_v, 8).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 8).ngClassPending; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_PickupTimeDialog_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "picker-time"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "picker-text-time"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Time"])), (_l()(), i1.ɵeld(3, 0, null, null, 6, "ion-datetime", [["displayFormat", "HH:mm"], ["pickerFormat", "HH mm"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "ionChange"], [null, "ionBlur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ionBlur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4)._handleBlurEvent($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("ionChange" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4)._handleChangeEvent($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.myDate = $event) !== false);
        ad = (pd_2 && ad);
    } if (("ionChange" === en)) {
        var pd_3 = (_co.updateMyDate($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_IonDatetime_0, i2.RenderType_IonDatetime)), i1.ɵdid(4, 16384, null, 0, i3.SelectValueAccessor, [i1.ElementRef], null, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.SelectValueAccessor]), i1.ɵdid(6, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(8, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), i1.ɵdid(9, 49152, null, 0, i3.IonDatetime, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { displayFormat: [0, "displayFormat"], min: [1, "min"], pickerFormat: [2, "pickerFormat"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "ion-icon", [["name", "md-arrow-dropdown"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(11, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.myDate; _ck(_v, 6, 0, currVal_7); var currVal_8 = "HH:mm"; var currVal_9 = i1.ɵinlineInterpolate(1, "", _co.nowMin, ""); var currVal_10 = "HH mm"; _ck(_v, 9, 0, currVal_8, currVal_9, currVal_10); var currVal_11 = "md-arrow-dropdown"; _ck(_v, 11, 0, currVal_11); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 8).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 8).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 8).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 8).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 8).ngClassValid; var currVal_5 = i1.ɵnov(_v, 8).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 8).ngClassPending; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_PickupTimeDialog_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { ionOutlet: 1 }), i1.ɵqud(402653184, 2, { myInput: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 52, "ion-content", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(3, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 50, "div", [["class", "custom-dialog-pickup-wrapper variation-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 49, "div", [["class", "custom-dialog-pickup"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 48, "div", [["class", "content-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [["class", "text-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["When do you want your meal?"])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "btn-now"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirmClicked("now") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Now"])), (_l()(), i1.ɵeld(13, 0, null, null, 41, "div", [["class", "input-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 4, "div", [["class", "text-or"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "span", [["class", "deco-line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["OR"])), (_l()(), i1.ɵeld(18, 0, null, null, 0, "span", [["class", "deco-line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 32, "div", [["class", "picker-box"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 27, "div", [["class", "picker-date"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 8, "div", [["class", "picker-inner-wrapper"]], null, null, null, null, null)), i1.ɵdid(22, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(23, { "picked": 0 }), (_l()(), i1.ɵeld(24, 0, null, null, 5, "div", [["no-lines", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.pickDate("0") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 1, "p", [["class", "text-month"]], null, null, null, null, null)), (_l()(), i1.ɵted(26, null, ["", ""])), (_l()(), i1.ɵeld(27, 0, null, null, 1, "p", [["class", "text-date"]], null, null, null, null, null)), (_l()(), i1.ɵted(28, null, ["", ""])), (_l()(), i1.ɵeld(29, 0, null, null, 0, "span", [["class", "picked-deco-line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 8, "div", [["class", "picker-inner-wrapper"]], null, null, null, null, null)), i1.ɵdid(31, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(32, { "picked": 0 }), (_l()(), i1.ɵeld(33, 0, null, null, 5, "div", [["no-lines", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.pickDate("1") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(34, 0, null, null, 1, "p", [["class", "text-month"]], null, null, null, null, null)), (_l()(), i1.ɵted(35, null, ["", ""])), (_l()(), i1.ɵeld(36, 0, null, null, 1, "p", [["class", "text-date"]], null, null, null, null, null)), (_l()(), i1.ɵted(37, null, ["", ""])), (_l()(), i1.ɵeld(38, 0, null, null, 0, "span", [["class", "picked-deco-line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(39, 0, null, null, 8, "div", [["class", "picker-inner-wrapper"]], null, null, null, null, null)), i1.ɵdid(40, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(41, { "picked": 0 }), (_l()(), i1.ɵeld(42, 0, null, null, 5, "div", [["no-lines", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.pickDate("2") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(43, 0, null, null, 1, "p", [["class", "text-month"]], null, null, null, null, null)), (_l()(), i1.ɵted(44, null, ["", ""])), (_l()(), i1.ɵeld(45, 0, null, null, 1, "p", [["class", "text-date"]], null, null, null, null, null)), (_l()(), i1.ɵted(46, null, ["", ""])), (_l()(), i1.ɵeld(47, 0, null, null, 0, "span", [["class", "picked-deco-line"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PickupTimeDialog_1)), i1.ɵdid(49, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PickupTimeDialog_2)), i1.ɵdid(51, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(52, 0, null, null, 2, "div", [["class", "btn-box"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.confirmClicked();
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(53, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Confirm "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "picker-inner-wrapper"; var currVal_1 = _ck(_v, 23, 0, (_co.chooseOtp == _co.todayOpt)); _ck(_v, 22, 0, currVal_0, currVal_1); var currVal_4 = "picker-inner-wrapper"; var currVal_5 = _ck(_v, 32, 0, (_co.chooseOtp == _co.tmrOtp)); _ck(_v, 31, 0, currVal_4, currVal_5); var currVal_8 = "picker-inner-wrapper"; var currVal_9 = _ck(_v, 41, 0, (_co.chooseOtp == _co.dayAfterTmrOpt)); _ck(_v, 40, 0, currVal_8, currVal_9); var currVal_12 = !_co.isTodayPicker; _ck(_v, 49, 0, currVal_12); var currVal_13 = _co.isTodayPicker; _ck(_v, 51, 0, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.dateDisplayMap["0month"]; _ck(_v, 26, 0, currVal_2); var currVal_3 = _co.dateDisplayMap["0day"]; _ck(_v, 28, 0, currVal_3); var currVal_6 = _co.dateDisplayMap["1month"]; _ck(_v, 35, 0, currVal_6); var currVal_7 = _co.dateDisplayMap["1day"]; _ck(_v, 37, 0, currVal_7); var currVal_10 = _co.dateDisplayMap["2month"]; _ck(_v, 44, 0, currVal_10); var currVal_11 = _co.dateDisplayMap["2day"]; _ck(_v, 46, 0, currVal_11); }); }
export function View_PickupTimeDialog_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "page-pickup-time", [], null, null, null, View_PickupTimeDialog_0, RenderType_PickupTimeDialog)), i1.ɵdid(1, 245760, null, 0, i6.PickupTimeDialog, [i1.Injector], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PickupTimeDialogNgFactory = i1.ɵccf("page-pickup-time", i6.PickupTimeDialog, View_PickupTimeDialog_Host_0, {}, {}, []);
export { PickupTimeDialogNgFactory as PickupTimeDialogNgFactory };
