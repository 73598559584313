var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnInit } from '@angular/core';
import { Data } from 'aigens-ng-core';
import { MobileBasePage } from '../../core/base/mobile-base-page';
import { NavParams } from '@ionic/angular';
import { isAvailableReward } from '../../shared/utilities/checkFormatTool';
var RewardListDialog = /** @class */ (function (_super) {
    __extends(RewardListDialog, _super);
    function RewardListDialog(injector, navParams) {
        var _this = _super.call(this, injector) || this;
        _this.injector = injector;
        _this.navParams = navParams;
        _this.rewards = [];
        _this.isBounce = false;
        _this.rewards = _this.navParams.get('rewards');
        return _this;
    }
    RewardListDialog.prototype.ngOnInit = function () {
        console.log('ngOnInit VariationPage');
    };
    RewardListDialog.prototype.dismissClicked = function () {
        this.modalController.dismiss(null);
    };
    RewardListDialog.prototype.getImage = function (reward) {
        return Data.getImage(reward, 'default', 100, false);
    };
    RewardListDialog.prototype.bounce = function () {
        var _this = this;
        this.isBounce = true;
        setTimeout(function () {
            _this.isBounce = false;
        }, 1000);
    };
    RewardListDialog.prototype.isAvailableReward = function (reward) {
        return isAvailableReward(reward);
    };
    RewardListDialog.prototype.onRewardClick = function (reward) {
        if (isAvailableReward(reward)) {
            this.modalController.dismiss(reward);
            this.bounce();
        }
    };
    RewardListDialog.prototype.canRedeem = function (reward) {
        return reward.cost < this.memberService.member.membership.stamps;
    };
    return RewardListDialog;
}(MobileBasePage));
export { RewardListDialog };
