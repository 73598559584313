<!--
  Generated template for the Passcode page.

  See http://ionicframework.com/docs/components/#navigation for more info on
  Ionic pages and navigation.
-->



<ion-content (click)="$event.stopPropagation()">
        <div class="custom-dialog-pickup-wrapper variation-wrapper">
      
          <div class="custom-dialog-pickup" (click)="$event.stopPropagation()">
            <div class="content-container">
              <div class="text-box">
                <p>When do you want your meal?</p>
                <div (click)="confirmClicked('now')" class="btn-now">
                  <p>Now</p>
                </div>
              </div>

              <div class="input-box">
                <div class="text-or">
                  <span class="deco-line"></span>
                  <p>OR</p>
                  <span class="deco-line"></span>
                </div>
        
                <div class="picker-box" (click)="$event.stopPropagation()">
                  <!-- <p class="c-ali">Order for later</p> -->
        
                  <div class="picker-date">
                    <div class="picker-inner-wrapper" [ngClass]="{'picked': chooseOtp == todayOpt}">
                      <div no-lines (click)="pickDate('0')">
                        <!-- <ion-label>{{todayOptDisplay}} - Choose</ion-label> -->
                        <p class="text-month">{{dateDisplayMap['0month']}}</p>
                        <p class="text-date">{{dateDisplayMap['0day']}}</p>
                        <span class="picked-deco-line"></span>
                      </div>
                  
                    </div>
                  
                    <div class="picker-inner-wrapper" [ngClass]="{'picked': chooseOtp == tmrOtp}">
                      <div no-lines (click)="pickDate('1')">
                        <!-- <ion-label>{{todayOptDisplay}} - Choose</ion-label> -->
                        <p class="text-month">{{dateDisplayMap['1month']}}</p>
                        <p class="text-date">{{dateDisplayMap['1day']}}</p>
                        <span class="picked-deco-line"></span>
                      </div>
                  
                    </div>
                  
                    <div class="picker-inner-wrapper" [ngClass]="{'picked': chooseOtp == dayAfterTmrOpt}">
                      <div no-lines (click)="pickDate('2')">
                        <!-- <ion-label>{{todayOptDisplay}} - Choose</ion-label> -->
                        <p class="text-month">{{dateDisplayMap['2month']}}</p>
                        <p class="text-date">{{dateDisplayMap['2day']}}</p>
                        <span class="picked-deco-line"></span>
                      </div>
                  
                    </div>
                  </div>
        
                  <div class="picker-time" *ngIf="!isTodayPicker">
                    <p class="picker-text-time">Time</p>
                    <ion-datetime displayFormat="HH:mm" pickerFormat="HH mm" [(ngModel)]="myDate"
                    (ionChange)="updateMyDate($event)"></ion-datetime>
                    <ion-icon name="md-arrow-dropdown"></ion-icon>
                  </div>
        
                  <div class="picker-time" *ngIf="isTodayPicker">
                    <p class="picker-text-time">Time</p>
                    <ion-datetime displayFormat="HH:mm" pickerFormat="HH mm" [(ngModel)]="myDate"
                    (ionChange)="updateMyDate($event)" min="{{nowMin}}"></ion-datetime>
                    <ion-icon name="md-arrow-dropdown"></ion-icon>
                  </div>
      
                </div>
                <div (click)="confirmClicked();$event.stopPropagation()" class="btn-box">
                  <p> Confirm </p>
                </div>  
              </div>
             
            </div>
      
      
           
          </div>

        </div>
      
      
      </ion-content>