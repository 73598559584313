var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnDestroy, OnInit } from '@angular/core';
import { MobileBasePage } from 'src/app/core/base/mobile-base-page';
import { MemberService } from 'src/app/core/services/member.service';
import { StoreService } from 'src/app/core/services/store.service';
import { NavParams } from '@ionic/angular';
import { TermsConditionsPage } from '../terms-conditions/terms-conditions';
import { getTandCUrl } from 'src/app/shared/utilities/checkFormatTool';
var JoinOrLogoutModal = /** @class */ (function (_super) {
    __extends(JoinOrLogoutModal, _super);
    function JoinOrLogoutModal(injector, memberService, storeService) {
        var _this = _super.call(this, injector) || this;
        _this.memberService = memberService;
        _this.storeService = storeService;
        _this.marketing = true;
        _this.hasTAndC = true;
        _this.navParams = injector.get(NavParams);
        _this.brand = _this.navParams.get('brand');
        _this.store = _this.navParams.get('store');
        if (_this.store) {
            _this.TACKeyObj = _this.store;
        }
        var url = getTandCUrl(_this.brand, 'regTc');
        _this.hasTAndC = !!url;
        return _this;
    }
    JoinOrLogoutModal.prototype.dismissClicked = function (params) {
        console.log('delivery login modal closing');
        this.modalController.dismiss(params);
    };
    JoinOrLogoutModal.prototype.joinMembership = function () {
        var _this = this;
        console.log('joinMembership');
        this.loading(true);
        this.memberApiService.join(this.store.id, this.brand.id, this.marketing).subscribe(function (result) {
            console.log('JoinOrLogoutModal joinMembership result', result);
            _this.loading(false);
            _this.showToast(_this.t.instant('global.join-membership-toast'), 2000, 'bottom');
            _this.dismissClicked({ joinMembership: true });
            return;
        }, function (err) {
            _this.loading(false);
            _this.showError(err);
            _this.dismissClicked({ joinMembership: false });
            // logout
            _this.memberService.clear();
            _this.orderSessionService.clearLocalSession();
        });
    };
    JoinOrLogoutModal.prototype.justShowTC = function () {
        _super.prototype.openTermsModal.call(this, this.brand, function () { }, TermsConditionsPage, false, false, false, false, true, true);
    };
    JoinOrLogoutModal.prototype.logoutClicked = function () {
        console.log("JoinOrLogoutModal logoutClicked");
        this.logout();
    };
    JoinOrLogoutModal.prototype.logout = function () {
        var _this = this;
        this.showAlert('', this.t.instant('pages.profile.alert-title'), [{
                text: this.t.instant('buttons.no'),
                handler: function () {
                    console.log('cancel');
                }
            }, {
                text: this.t.instant('buttons.yes'),
                handler: function () {
                    _this.memberService.clear();
                    _this.orderSessionService.clearLocalSession();
                    _this.configService.setLocal('termsAgree', null);
                    _this.addressManagerService.clearAll();
                    _this.dismissClicked({});
                }
            }]);
    };
    return JoinOrLogoutModal;
}(MobileBasePage));
export { JoinOrLogoutModal };
