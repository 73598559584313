import { Component, Injector, ChangeDetectorRef } from '@angular/core';
import { BasePage } from '../../core/base/base-page';
import { Store, ModifierBuilder, OrderItemBuilder } from 'aigens-ng-core';
import { Item, Data } from 'aigens-ng-core';
import { InventoryManager } from 'aigens-ng-core';
import { MobileBasePage } from '../../core/base/mobile-base-page';
import { Category } from 'aigens-ng-core';
import { OrderItem } from 'aigens-ng-core';
import { ItemGroup } from 'aigens-ng-core';
import { NavParams } from '@ionic/angular';

@Component({
    selector: 'page-item-filter',
    templateUrl: 'item-filter.html',
    styleUrls: ['item-filter.scss']
})
export class ItemFilterDialog extends MobileBasePage {

    group: ItemGroup;
    itemMap: any;
    store: Store;
    filters: any[] = [];
    selectedFilter: any;
    selectedIndex: number;
    selectedFilterTags: string[] = [];
    // levelSetTag = {};
    selectedCount = 0;
    filterCount = 0;
    item: Item;
    filteredItem: Item;
    // tags: any = {};
    orderItem: OrderItem;
    builder: ModifierBuilder;
    confirm: boolean = false;
    modifiableItems: Item[];
    currentItem: Item;
    tmps: Item[];
    // mmap = {};
    // tagTree = {};
    // currentTree = {};
    absentItemsId: string[] = [];
    currentIndex = 0;
    itemsCount = {};

    contentContainerHeight: number;

    items: Item[];
    originalItems: Item[];
    tags: any = {};
    itemClicked: Item;

    constructor(private injector: Injector, public navParams: NavParams) {

        super(injector);
        this.store = this.navParams.get('store');
        this.group = this.navParams.get('group');
        this.itemMap = this.navParams.get('itemMap');
        this.item = this.navParams.get('item');
        if (!this.store) {
            this.store = this.orderManager.store;
        }

        console.log(this.item);

        this.init();
    }

    init() {

        console.log('group', this.group);

        this.originalItems = [this.item].concat(this.item.variations);
        this.items = [this.item].concat(this.item.variations);

        if (this.group && (this.group['filters'] || this.group['filters2'])) {

            this.filters = [];
            if (this.group['filters'] && this.group['filters'].length > 0) {
                let filter = {
                    question: this.group['filter'],
                    tags: this.group['filters']
                };
                this.filters.push(filter);
            }

            if (this.group['filters2'] && this.group['filters2'].length > 0) {
                let filter = {
                    question: this.group['filter2'],
                    tags: this.group['filters2']
                };
                this.filters.push(filter);
            }
        }

        console.log('filters:', this.filters);
        // this.selectFilter(0);

        if (this.filters.length == 0) {
            this.selectedFilterTags = [];
        } else {
            //add Badges
            if (this.store.menu && this.store.menu.badges) {
                let badges = this.store.menu.badges;
                console.log(badges);
                badges.forEach(badge => {
                    this.tags[badge.tag] = badge;
                });
            }
        }

    }

    isFilterSelected(filter) {
        if (!this.selectedFilterTags) return false;
        return filter.tags.some(t => {
            let i = this.selectedFilterTags.indexOf(t);
            return (i >= 0);
        });
    }

    selectTag(tag: string, filter: any) {
        if (!this.isAvailableTag(tag)) return;

        console.log('filter:', tag, filter);
        if (!this.selectedFilterTags) {
            this.items = [];
            this.selectedFilterTags = [];
        }
        let index = this.selectedFilterTags.indexOf(tag);
        //deselect
        if (index >= 0) {
            this.selectedFilterTags.splice(index, 1);
        } else { //select

            filter.tags.forEach(t => {
                let i = this.selectedFilterTags.indexOf(t);
                if (i >= 0) this.selectedFilterTags.splice(i, 1);
            });

            this.selectedFilterTags.push(tag);
        }

        if (this.selectedFilterTags.length == 0) {
            this.selectedFilterTags = null;
            this.items = [];
            return;
        }

        let result: Item[] = this.originalItems;

        for (let tag of this.selectedFilterTags) {
            result = result.filter(item => { return item.filters && item.filters.indexOf(tag) >= 0 });
        }

        this.items = result;
        console.log('filtered item', this.items);

        // if(this.currentIndex==0&&this.filters.length>1){
        //     this.selectFilter(1);
        // }

        if (this.items.length == 1) {
            this.showConfirm();
        }

    }

    showConfirm() {
        if (!this.selectedFilterTags) return false;
        if (this.selectedFilterTags.length == this.filters.length) {
            this.filteredItem = this.items[0];
            return true;
        }
    }

    // selectFilter(index: number) {
    //     this.selectedIndex = index
    //     this.selectedFilter = this.filters[index];
    //     this.currentIndex = index;
    //     this.scrollToX(this.currentIndex);
    // }

    isAvailableTag(tag) {
        //check if any selectable item with specific tag and with filtering tags in other groups

        if (this.selectedFilterTags && this.selectedFilterTags.indexOf(tag) >= 0) return true;

        let availableItems = this.originalItems.filter(item => {

            if (item.filters) {
                return item.filters.indexOf(tag) >= 0;
            }
        });

        if (this.selectedFilterTags && availableItems && availableItems.length > 0) {
            for (let filteredTag of this.selectedFilterTags) {
                if (!this.ifInSameFilterGroup(filteredTag, tag)) {
                    availableItems = availableItems.filter(item => {
                        return item.filters.indexOf(filteredTag) >= 0
                    });
                    if (!availableItems || availableItems.length == 0) break;
                }
            }
        }

        return availableItems && availableItems.length > 0;
    }

    isSelectedTag(tag): boolean {
        if (!this.selectedFilterTags) return false;

        if (this.selectedFilterTags.indexOf(tag) >= 0)
            return true;
        return false;
    }

    ifInSameFilterGroup(tag1: string, tag2: string) {
        for (let filter of this.filters) {
            if (filter.tags.indexOf(tag1) >= 0 && filter.tags.indexOf(tag2) >= 0) return true;
        }
        return false;
    }

    ionViewDidLoad() {
        console.log('ionViewDidLoad ItemFilterPage');
        // 100: offset top ; 64: padding-bottom offset ; 12: margin
        // this.contentContainerHeight = screen.height - document.getElementById('content-top').offsetTop - 236;
        // this.contentContainerHeight = this.filterCount * 188 +64 ;

    }

    isActiveTag(tag) {
        return this.selectedFilterTags && this.selectedFilterTags.indexOf(tag) >= 0;
    }


    confirmClicked() {
        if (this.isSoldout(this.filteredItem)) {
            this.showAlert('Soldout', this.filteredItem.name);
            return;
        }
        this.modalController.dismiss(this.filteredItem);
    }

    dismissClicked() {
        this.modalController.dismiss(null);

    }


}
