<ion-button appThrottleClick (throttleClick)="onBackButtonClick()" class="back-btn AQA-back-btn">
    <ion-icon name="arrow-back"></ion-icon>
    <ng-container *ngIf="!forceHideText && !hideBackText">
        <ng-container *ngIf="overwriteTranslateKey">
            <p *ngIf="!darkText">{{overwriteTranslateKey | translate}}</p>
            <p ion-text color="" *ngIf="darkText">{{overwriteTranslateKey | translate}}</p>
        </ng-container>
        <ng-container *ngIf="!overwriteTranslateKey">
            <p *ngIf="!darkText">{{"global.previous" | translate}}</p>
            <p ion-text color="" *ngIf="darkText">{{"global.previous" | translate}}</p>
        </ng-container>
    </ng-container>
</ion-button>
