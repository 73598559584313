var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnInit } from '@angular/core';
import { BasePage } from '../../core/base/base-page';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
/*
  Generated class for the SelectTable page.

  See http://ionicframework.com/docs/v2/components/#navigation for more info on
  Ionic pages and navigation.
*/
var SelectTablePage = /** @class */ (function (_super) {
    __extends(SelectTablePage, _super);
    function SelectTablePage(injector, t, formBuilder) {
        var _this = _super.call(this, injector) || this;
        _this.injector = injector;
        _this.t = t;
        _this.formBuilder = formBuilder;
        _this.data = {};
        _this.store = _this.getNavParams('store');
        _this.data = {};
        // this.rooms = ["204B", "104E", "215A", "106E", "213A", "208A"];
        // this.rooms = ["128F", "108F"];
        _this.setupValidation();
        return _this;
    }
    SelectTablePage.prototype.ngOnInit = function () {
        console.log('ngOnInit SelectTablePage');
    };
    SelectTablePage.prototype.setupValidation = function () {
        var rules = {};
        if (this.rooms) {
            rules['room'] = ['', Validators.required];
        }
        rules['table'] = ['', Validators.nullValidator];
        this.formGroup = this.formBuilder.group(rules);
    };
    SelectTablePage.prototype.confirmClicked = function () {
        this.formGroup.updateValueAndValidity();
        if (this.formGroup.invalid) {
            this.showAlert('', this.t.instant('dialog.select-table.invalid-input'));
            return;
        }
        console.log(this.data);
        this.modalController.dismiss(this.data);
    };
    SelectTablePage.prototype.formClicked = function () {
        console.log('form?');
        return false;
    };
    SelectTablePage.prototype.dismissClicked = function () {
        this.modalController.dismiss(null);
        console.log('dismiss?');
    };
    return SelectTablePage;
}(BasePage));
export { SelectTablePage };
