import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from '../services/navigation.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../services/navigation.service";
var PaymentGuard = /** @class */ (function () {
    function PaymentGuard(router, route, navigationService) {
        this.router = router;
        this.route = route;
        this.navigationService = navigationService;
    }
    // TODO: check if store is provided in route params?
    PaymentGuard.prototype.canActivate = function (next, state) {
        var t = this.navigationService.getNavigationParams()['payment'];
        console.log('payment guard', t);
        if (!t) {
            // TODO: not allowed to enter target root. go to parent route?
            this.router.navigate([this.navigationService.getRootPageUrl()], { replaceUrl: true });
        }
        return !!t;
    };
    PaymentGuard.ngInjectableDef = i0.defineInjectable({ factory: function PaymentGuard_Factory() { return new PaymentGuard(i0.inject(i1.Router), i0.inject(i1.ActivatedRoute), i0.inject(i2.NavigationService)); }, token: PaymentGuard, providedIn: "root" });
    return PaymentGuard;
}());
export { PaymentGuard };
