/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./order-help-modal.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/common";
import * as i6 from "./order-help-modal.page";
var styles_OrderHelpModal = [i0.styles];
var RenderType_OrderHelpModal = i1.ɵcrt({ encapsulation: 0, styles: styles_OrderHelpModal, data: {} });
export { RenderType_OrderHelpModal as RenderType_OrderHelpModal };
function View_OrderHelpModal_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ion-icon", [["name", "call"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(2, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_1 = "call"; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("tel:" + _co.orderService.store["fullPhone"]); _ck(_v, 0, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("pages.byod-summary.call-restaurant")); _ck(_v, 3, 0, currVal_2); }); }
function View_OrderHelpModal_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ion-icon", [["name", "call"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(2, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_1 = "call"; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("tel:" + ((_co.orderService == null) ? null : ((_co.orderService.store == null) ? null : ((_co.orderService.store.location == null) ? null : _co.orderService.store.location.phone)))); _ck(_v, 0, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("pages.byod-summary.call-restaurant")); _ck(_v, 3, 0, currVal_2); }); }
export function View_OrderHelpModal_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { ionOutlet: 1 }), (_l()(), i1.ɵeld(1, 0, null, null, 12, "ion-content", [["class", "content-signup-or-acc"]], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(2, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), i1.ɵdid(3, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "isModalPopup": 0 }), (_l()(), i1.ɵeld(5, 0, null, 0, 2, "div", [["class", "div-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, 0, 5, "div", [["class", "div-choices"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "choice-button"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrderHelpModal_1)), i1.ɵdid(11, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrderHelpModal_2)), i1.ɵdid(13, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "content-signup-or-acc"; var currVal_1 = _ck(_v, 4, 0, true); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_3 = (((_co.orderService == null) ? null : _co.orderService.store) && _co.orderService.store["fullPhone"]); _ck(_v, 11, 0, currVal_3); var currVal_4 = !(((_co.orderService == null) ? null : _co.orderService.store) && _co.orderService.store["fullPhone"]); _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("pages.byod-summary.order-help-title")); _ck(_v, 6, 0, currVal_2); }); }
export function View_OrderHelpModal_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "order-help-modal", [], null, null, null, View_OrderHelpModal_0, RenderType_OrderHelpModal)), i1.ɵdid(1, 245760, null, 0, i6.OrderHelpModal, [i1.Injector], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OrderHelpModalNgFactory = i1.ɵccf("order-help-modal", i6.OrderHelpModal, View_OrderHelpModal_Host_0, {}, {}, []);
export { OrderHelpModalNgFactory as OrderHelpModalNgFactory };
