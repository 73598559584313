var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import { BaseService } from '../base/base-service';
import { OrderManager, MathUtils } from 'aigens-ng-core';
import { MemberService } from './member.service';
import * as i0 from "@angular/core";
import * as i1 from "./member.service";
var PointsService = /** @class */ (function (_super) {
    __extends(PointsService, _super);
    function PointsService(memberService, injector) {
        var _this = _super.call(this) || this;
        _this.memberService = memberService;
        _this.injector = injector;
        _this.pointRatio = 0.1;
        _this.pointsEarned = 0;
        _this.isPayable = false;
        _this.orderManager = injector.get(OrderManager);
        _this.order = _this.orderManager.order;
        var crm = _this.orderManager.store && _this.orderManager.store.crm;
        if (crm) {
            _this.pointRatio = crm.pointRatio;
            _this.pointToMoneyRatio = crm.pointToMoneyRatio;
            _this.redeemPointFactor = crm.redeemPointFactor;
            _this.redeemPointMin = crm.redeemPointMin; // TODO use this para?
            _this.minChargeAfterPoint = crm['minChargeAfterPoint'];
        }
        return _this;
    }
    PointsService.prototype.init = function () {
        var crm = this.orderManager.store && this.orderManager.store.crm;
        if (crm) {
            this.pointRatio = crm.pointRatio;
            this.pointToMoneyRatio = crm.pointToMoneyRatio;
            this.redeemPointFactor = crm.redeemPointFactor;
            this.redeemPointMin = crm.redeemPointMin; // TODO use this para?
            this.minChargeAfterPoint = crm['minChargeAfterPoint'];
        }
    };
    PointsService.prototype.getPointsPayment = function () {
        console.log('get order payments');
        return this.order.payments;
    };
    PointsService.prototype.clearPointsPayment = function () {
        this.orderManager.order.payments = [];
    };
    /**TODO: set points payment for byod order */
    PointsService.prototype.setPointsPayment = function (total) {
        this.orderManager.order.payments = [];
        var pointsPayment = {
            type: 'points',
            payment: true,
            detail: {
                deduct: total * 100
            }
        };
        this.orderManager.order.payments.push(pointsPayment);
    };
    PointsService.prototype.minCharge = function () {
        var result = 0;
        if (this.minChargeAfterPoint) {
            result = this.minChargeAfterPoint;
        }
        return result;
    };
    PointsService.prototype.getusedPointsToMoney = function (usedPoints) {
        if (usedPoints === void 0) { usedPoints = this.orderManager.order.usedPoints_toMoney; }
        var pointsMoneyTotal = 0;
        pointsMoneyTotal = MathUtils.round(usedPoints / this.pointToMoneyRatio, 2);
        return pointsMoneyTotal;
    };
    PointsService.prototype.earnPointsCal = function () {
        this.setPoints();
        var netAmount = this.orderManager.getNetAmount();
        var pointsMoney = this.getusedPointsToMoney(this.orderManager.order.usedPoints_toMoney);
        var temp = Math.floor((netAmount - this.orderManager.order.tax - this.orderManager.order.serviceCharge - pointsMoney) / this.getCrmPointRatio());
        this.pointsEarned = temp >= 0 ? temp : 0;
    };
    PointsService.prototype.getCrmPointRatio = function () {
        return this.pointRatio;
    };
    PointsService.prototype.setPoints = function () {
        this.orderManager.pointsFactor = 0;
        if (!this.orderManager.order.usedPoints_toMoney) {
            this.orderManager.order.usedPoints_toMoney = 0;
        }
        var hasPoint = this.memberService.member && this.memberService.member.membership && this.memberService.member.membership.usablePoints;
        this.orderManager.order.usablePoints = hasPoint ? this.memberService.member.membership.usablePoints : 0;
    };
    PointsService.prototype.payableTotal = function (total, usedPoints) {
        var outstandingBal;
        if (total) {
            outstandingBal = total;
        }
        else {
            outstandingBal = this.orderManager.order.grandTotal;
        }
        var used = 0;
        if (usedPoints) {
            used = usedPoints;
        }
        else {
            used = this.orderManager.order.usedPoints_toMoney;
        }
        var pointsMoney = this.getusedPointsToMoney(used);
        outstandingBal -= pointsMoney;
        return MathUtils.round(outstandingBal, 2);
    };
    PointsService.ngInjectableDef = i0.defineInjectable({ factory: function PointsService_Factory() { return new PointsService(i0.inject(i1.MemberService), i0.inject(i0.INJECTOR)); }, token: PointsService, providedIn: "root" });
    return PointsService;
}(BaseService));
export { PointsService };
