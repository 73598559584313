var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { BrowserService } from './browser.service';
import { Charge, Data, OrderManager } from 'aigens-ng-core';
import { AQuery } from '../base/aquery';
import { BaseService } from '../base/base-service';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { OrderService } from './order.service';
import { SdkAlipayHKService } from './sdk-alipay-hk-service';
import { SdkWeChatPayService } from './sdk-wechat-pay-service';
import { SdkApplepayService } from './sdk-applepay-service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./config.service";
import * as i3 from "./browser.service";
import * as i4 from "aigens-ng-core/dist/manager/order-manager";
import * as i5 from "./order.service";
import * as i6 from "./sdk-alipay-hk-service";
import * as i7 from "./sdk-wechat-pay-service";
import * as i8 from "./sdk-applepay-service";
var PaymentService = /** @class */ (function (_super) {
    __extends(PaymentService, _super);
    function PaymentService(http, configService, browserService, orderManager, orderService, sdkAlipayHKService, sdkWeChatPayService, sdkApplePayService) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.configService = configService;
        _this.browserService = browserService;
        _this.orderManager = orderManager;
        _this.orderService = orderService;
        _this.sdkAlipayHKService = sdkAlipayHKService;
        _this.sdkWeChatPayService = sdkWeChatPayService;
        _this.sdkApplePayService = sdkApplePayService;
        _this.hasActivePayment = false;
        _this.supported = {};
        _this.aq = new AQuery(http, configService);
        console.log('PaymentService');
        return _this;
    }
    PaymentService.prototype.preload = function (store, callback, force, charge) {
        if (force === void 0) { force = false; }
        if (!store) {
            return;
        }
        if (!store.brand) {
            return;
        }
        if (!store.brand['payments']) {
            return;
        }
        var payments = store.brand['payments'];
        if (charge) {
            // sometimes only need load only one payment js
            payments = payments.filter(function (payment) { return payment['method'] === charge['method']; });
        }
        this.processPayments(payments, callback, store, force);
    };
    PaymentService.prototype.processPayments = function (payments, callback, store, force) {
        if (force === void 0) { force = false; }
        return __awaiter(this, void 0, void 0, function () {
            var loadedUrl, _loop_1, this_1, _i, payments_1, payment;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loadedUrl = {};
                        _loop_1 = function (payment) {
                            var gateway, param, url, callbackHandler, isAlipayMini, err_1, apiHost, version, paymentHost;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        gateway = payment['gateway'];
                                        param = {};
                                        url = void 0;
                                        callbackHandler = void 0;
                                        isAlipayMini = void 0;
                                        _a.label = 1;
                                    case 1:
                                        _a.trys.push([1, 3, , 4]);
                                        return [4 /*yield*/, this_1.browserService.isAliMiniApp()];
                                    case 2:
                                        isAlipayMini = _a.sent();
                                        return [3 /*break*/, 4];
                                    case 3:
                                        err_1 = _a.sent();
                                        console.log(err_1);
                                        return [3 /*break*/, 4];
                                    case 4:
                                        if (gateway === 'stripe' && !isAlipayMini) {
                                            console.log('loading stripe');
                                            url = 'https://js.stripe.com/v3/';
                                            callbackHandler = function () {
                                                console.log('script loaded');
                                                _this.prepare(store, callback, payment.key);
                                            };
                                        }
                                        if (gateway === 'wirecardV2' && payment['method'] === 'creditcard' && !isAlipayMini) {
                                            param['defer'] = '';
                                            // paymentHost: "https://wpp-test.wirecard.com.sg/"
                                            // let host = "https://wpp-test.wirecard.com";
                                            if (payment['paymentHost']) {
                                                url = payment['paymentHost'] + '/loader/paymentPage.js';
                                            }
                                            else {
                                                url = 'https://wpp-test.wirecard.com/loader/paymentPage.js';
                                            }
                                            callbackHandler = function () {
                                                callback();
                                            };
                                            console.log('loading wirecardv2');
                                        }
                                        // enets-payment
                                        if (gateway === 'nets' && payment['method'] === 'netspay' && !isAlipayMini) {
                                            url = 'https://uat2.enets.sg/GW2/js/jquery-3.1.1.min.js';
                                            callbackHandler = function () {
                                                callback();
                                            };
                                            console.log('loading netspay');
                                        }
                                        if (gateway === 'mpgs' && (payment['method'] === 'creditcard' || payment['method'] === 'session') && !isAlipayMini) {
                                            param['defer'] = '';
                                            apiHost = payment['APIHost'];
                                            version = payment['version'];
                                            url = (apiHost ? apiHost : 'https://ap-gateway.mastercard.com') + '/form/version/' + (version ? version : '48') + '/merchant/' + payment['merchantId'] + '/session.js';
                                            console.log('use mpgs host', apiHost ? apiHost : 'https://ap-gateway.mastercard.com');
                                            console.log('use mpgs version', version ? version : '48');
                                            callbackHandler = function () {
                                                callback();
                                            };
                                            console.log('loading mpgs');
                                        }
                                        if (gateway === 'midTrans' && payment['method'] === 'creditcard' && !isAlipayMini) {
                                            /*   <script id="midtrans-script" type="text/javascript"
                                               src="https://api.midtrans.com/v2/assets/js/midtrans-new-3ds.min.js"
                                               data-environment="sandbox"
                                               data-client-key="<INSERT YOUR CLIENT KEY HERE>"></script>*/
                                            param['id'] = 'midtrans-script';
                                            param['data-environment'] = payment['isSandbox'] ? 'sandbox' : 'production';
                                            param['data-client-key'] = payment['clientKey'];
                                            url = 'https://api.midtrans.com/v2/assets/js/midtrans-new-3ds.min.js';
                                            callbackHandler = function () {
                                                callback();
                                            };
                                        }
                                        if (gateway === 'omise' && payment['method'] === 'creditcard' && !isAlipayMini) {
                                            console.log('loading stripe');
                                            url = 'https://cdn.omise.co/omise.js';
                                            callbackHandler = function () {
                                                console.log('omise script loaded');
                                            };
                                        }
                                        if (gateway === 'wirecard' && (payment['method'] === 'creditcard' || payment['method'] === 'session')) {
                                            param['defer'] = '';
                                            // var isPrd = this.configService.getConfig()['production'];
                                            console.log('payment host', payment);
                                            if (payment['paymentHost']) {
                                                paymentHost = payment['paymentHost'];
                                                url = paymentHost + "/engine/hpp/paymentPageLoader.js";
                                            }
                                            else {
                                                url = 'https://api.wirecard.com.sg/engine/hpp/paymentPageLoader.js';
                                            }
                                            callbackHandler = function () {
                                                callback();
                                            };
                                            console.log('loading wirecard');
                                        }
                                        if (url && !loadedUrl[url]) {
                                            this_1.configService.loadScript(url, callbackHandler, param);
                                            loadedUrl[url] = true;
                                        }
                                        else if (force && callback) {
                                            callback();
                                        }
                                        return [2 /*return*/];
                                }
                            });
                        };
                        this_1 = this;
                        _i = 0, payments_1 = payments;
                        _a.label = 1;
                    case 1:
                        if (!(_i < payments_1.length)) return [3 /*break*/, 4];
                        payment = payments_1[_i];
                        return [5 /*yield**/, _loop_1(payment)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    PaymentService.prototype.courtPreload = function (p, callback, store) {
        var payments = p;
        console.log('courtPreload payment', payments);
        this.processPayments(payments, callback, store);
    };
    PaymentService.prototype.getStripe = function () {
        return this.stripe;
    };
    PaymentService.prototype.prepare = function (store, callback, stripeKey) {
        if (!store.pos) {
            return;
        }
        if (this.stripeKey) {
            this.checkPaymentMethodAvailable();
            return;
        }
        this.stripeKey = this.configService.get('stripe');
        if (stripeKey) {
            this.stripeKey = stripeKey;
        }
        console.log('stripe key...', this.stripeKey, stripeKey);
        var stripe = Stripe(this.stripeKey);
        var pos = store.pos;
        console.log('payments?', pos);
        this.stripe = stripe;
        this.checkPaymentMethodAvailable();
    };
    PaymentService.prototype.checkPaymentMethodAvailable = function () {
        var _this = this;
        var stripe = this.stripe;
        if (!stripe) {
            this.hasActivePayment = true;
            return;
        }
        var paymentRequest = stripe.paymentRequest({
            country: 'HK',
            currency: 'hkd',
            total: {
                label: 'Aigens',
                amount: 400
            }
        });
        paymentRequest.canMakePayment().then(function (result) {
            console.log('can make payment', result);
            if (result) {
                _this.hasActivePayment = true;
            }
            else {
                _this.hasActivePayment = false;
            }
        });
    };
    PaymentService.prototype.listenToken = function (payName, store, grandTotal, callback) {
        var amount;
        if (store.currency === 'JPY') {
            amount = Math.floor(grandTotal * 1);
        }
        else {
            amount = Math.floor(grandTotal * 100);
        }
        var stripe = this.getStripe();
        var paymentRequest = stripe.paymentRequest({
            country: store.country.toUpperCase(),
            currency: store.currency.toLowerCase(),
            total: {
                label: payName,
                amount: amount
            }
        });
        this.listen(paymentRequest, callback);
        return paymentRequest;
    };
    PaymentService.prototype.payStripeCharge = function (token, charge) {
        var url = '/api/v1/pay/charge.json';
        var params = {};
        params['type'] = charge.type;
        params['token'] = token;
        params['amount'] = charge.amount;
        params['currency'] = charge.currency;
        params['groupId'] = '100';
        params['email'] = charge.email;
        params['subtype'] = charge.subtype;
        params['method'] = charge.method;
        if (charge.payeeId) {
            params['payeeId'] = charge.payeeId;
        }
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.params = params;
        return aq.getJson().pipe(map(function (jo) { return Data.toData(Charge, jo['data']); }));
    };
    PaymentService.prototype.canApplePay = function () {
        var aps = window.ApplePaySession;
        console.log('apple pay session', aps);
        if (aps) {
            if (aps.canMakePaymentsWithActiveCard) {
                return this.hasActivePayment;
            }
        }
        return false;
    };
    PaymentService.prototype.canAndroidPay = function () {
        if (this.browserService.ios) {
            return false;
        }
        if (window.PaymentRequest) {
            return this.hasActivePayment;
        }
        return false;
    };
    PaymentService.prototype.postApplePaySession = function (appleServerUrl, domain) {
        var url = this.configService.get('applePay'); // "https://pay-dot-aigensstoreapp.appspot.com/api/v1/pay/applesession.json";
        var body = { url: appleServerUrl, domain: domain };
        console.log(body);
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.body = body;
        return aq.getJson().pipe(map(function (jo) { return jo; }));
    };
    PaymentService.prototype.listen = function (paymentRequest, callback) {
        var _this = this;
        var stripe = this.getStripe();
        paymentRequest.on('token', function (ev) {
            console.log('token!!', ev);
            callback(ev);
        });
        var elements = stripe.elements();
        var prButton = elements.create('paymentRequestButton', {
            paymentRequest: paymentRequest,
        });
        // Check the availability of the Payment Request API first.
        paymentRequest.canMakePayment().then(function (result) {
            console.log('can make payment?', result);
            if (result) {
                _this.supported = result;
                console.log('can make payment', result);
                prButton.mount('#payment-request-button');
                console.log('prButton', prButton);
            }
            else {
                // document.getElementById('payment-request-button').style.display = 'none';
                console.log('cannot pay');
            }
        });
    };
    // TODO cross check store gateway and supported methods
    PaymentService.prototype.getPaymentMethods = function (payments) {
        return __awaiter(this, void 0, void 0, function () {
            var methods, _i, payments_2, payment, pos, takeaway, dinein, pickup, isWeChat, isAlipay, isAlipayMini, err_2, subMethod, canAdd, subMethod, key, canAdd;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log(this.browserService.browser);
                        methods = {};
                        _i = 0, payments_2 = payments;
                        _a.label = 1;
                    case 1:
                        if (!(_i < payments_2.length)) return [3 /*break*/, 7];
                        payment = payments_2[_i];
                        if (payment['id'] && payment['id'].indexOf('-sync-') !== -1) {
                            console.log('pos sync payment, ignore', payment);
                            return [3 /*break*/, 6];
                        }
                        console.log('checking payment', payments);
                        pos = payment['gateway'] === 'pos';
                        if (pos && payment['method'] === 'cash') {
                            //
                        }
                        else if (pos && payment['method'] !== 'delay') {
                            return [3 /*break*/, 6];
                        }
                        takeaway = this.orderManager.mode === 'takeaway';
                        dinein = this.orderManager.mode === 'dinein';
                        pickup = takeaway || dinein;
                        isWeChat = this.browserService.isWeChat() || this.browserService.isWeChatHK();
                        isAlipay = this.browserService.isAlipay() || this.browserService.isAlipayHK();
                        isAlipayMini = void 0;
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.browserService.isAliMiniApp()];
                    case 3:
                        isAlipayMini = _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        err_2 = _a.sent();
                        console.log(err_2);
                        return [3 /*break*/, 5];
                    case 5:
                        // if(isAlipay && payment["method"] != "alipay") continue;
                        if (payment['method'] === 'creditcard' && !isAlipayMini) {
                            methods['creditcard'] = payment;
                        }
                        if (payment['method'] === 'amex' && payment['gateway'] === 'nets' && payment['sub'] === 'web') {
                            methods['amex-netspay'] = payment;
                        }
                        // if (payment['method'] === 'netspay' && pickup && !isWeChat && !isAlipay) {
                        if (payment['method'] === 'netspay' && !isWeChat && !isAlipay) {
                            // need to change netspay to the same as wirecard(by ray)
                            methods['netspay'] = payment;
                        }
                        if (payment['method'] === 'session' && !isAlipay && !isWeChat) {
                            methods['session'] = payment;
                        }
                        if (payment['method'] === 'gopay' && !isAlipay && !isWeChat) {
                            methods['gopay'] = payment;
                        }
                        if (payment['method'] === 'mpayment' && !isAlipay && !isWeChat) {
                            methods['mpayment'] = payment;
                        }
                        if (payment['method'] === 'cod') {
                            methods['cod'] = payment;
                        }
                        // if (payment['method'] === 'point') {
                        //     methods['session'] = payment;
                        // }
                        if (payment['method'] === 'wechat' && !isAlipay) {
                            subMethod = payment['sub'];
                            if (subMethod === 'mini') {
                                if (this.browserService.isWeChatMiniProgramWebview()) {
                                    methods['wechat-mini'] = payment;
                                }
                                else {
                                    return [3 /*break*/, 7];
                                }
                            }
                            canAdd = (this.orderService.isStaff && subMethod === 'scan') || (!this.orderService.isStaff && subMethod !== 'scan' && isWeChat);
                            if (canAdd) {
                                methods['wechat'] = payment;
                            }
                        }
                        else if (payment['method'] === 'alipay' && !isWeChat) {
                            subMethod = payment['sub'];
                            if (subMethod === 'mini' && isAlipayMini) {
                                methods['alipay-mini'] = payment;
                            }
                            else if (payment['gateway'] === 'techtrans' && !isAlipay) {
                                // Tech-Trans Alipay quirk, can not use outside Alipay Browser
                                return [3 /*break*/, 6];
                            }
                            else if ((this.orderService.isStaff && subMethod === 'scan') || (!this.orderService.isStaff && subMethod !== 'scan' && subMethod !== 'mini' && !isAlipayMini)) {
                                key = 'alipay.' + payment['gateway'];
                                methods[key] = payment;
                            }
                        }
                        if (payment['method'] === 'alipay-app' && this.sdkAlipayHKService.isAvailable() && !isWeChat) {
                            if (payment['gateway'] === 'alipay-cn') {
                                methods['alipay-app-cn'] = payment;
                            }
                            else if (payment['gateway'] === 'alipay-hk') {
                                methods['alipay-app-hk'] = payment;
                            }
                        }
                        if (payment['method'] === 'wechat-app' && this.sdkWeChatPayService.isAvailable()) {
                            if (payment['gateway'] === 'eft-cn') {
                                methods['wechat-app.eft-cn'] = payment;
                            }
                            else if (payment['gateway'] === 'eft-hk') {
                                methods['wechat-app.eft-hk'] = payment;
                            }
                        }
                        if (payment['method'] === 'oepay' && !isAlipay && !isWeChat) {
                            methods['oepay'] = payment;
                        }
                        if (((this.canApplePay() || this.sdkApplePayService.isAvailable()) && !isWeChat && !isAlipay) && payment['method'] === 'apple') {
                            methods['apple'] = payment;
                        }
                        else if ((this.browserService.isSupportNativeGooglePay() || (this.canAndroidPay()) && !isWeChat && !isAlipay) && payment['method'] === 'google') {
                            console.log('is android pay', payment);
                            methods['android'] = payment;
                        }
                        if (payment['method'] === 'cash' && !isAlipayMini) {
                            canAdd = !this.orderManager.store.brand.hasFeature('staff-cash') || (this.orderService.isStaff && this.orderManager.store.brand.hasFeature('staff-cash'));
                            if (canAdd) {
                                methods['cash'] = payment;
                            }
                        }
                        if ((payment['method'] === 'delaypayment' || payment['method'] === 'delay') && !isAlipayMini) {
                            methods['delay'] = payment;
                        }
                        _a.label = 6;
                    case 6:
                        _i++;
                        return [3 /*break*/, 1];
                    case 7:
                        console.log('pay methods', methods);
                        return [2 /*return*/, methods];
                }
            });
        });
    };
    PaymentService.ngInjectableDef = i0.defineInjectable({ factory: function PaymentService_Factory() { return new PaymentService(i0.inject(i1.HttpClient), i0.inject(i2.ConfigService), i0.inject(i3.BrowserService), i0.inject(i4.OrderManager), i0.inject(i5.OrderService), i0.inject(i6.SdkAlipayHKService), i0.inject(i7.SdkWeChatPayService), i0.inject(i8.SdkApplepayService)); }, token: PaymentService, providedIn: "root" });
    return PaymentService;
}(BaseService));
export { PaymentService };
