import { HashLocationStrategy, LocationStrategy, PlatformLocation } from '@angular/common';
import { MyLocationStrategy } from './core/my-location-strategy';
import { createTranslateLoader } from './shared/utilities/createTranslateLoader';
import { mdTransitionAnimation } from '@ionic/core/dist/collection/utils/transition/md.transition.js';
// Sentry.init({
//     dsn: 'https://1c9736d1514449289de33e062f465270@sentry.io/1851883',
//     release: version
// });
//
// @Injectable()
// export class SentryErrorHandler implements ErrorHandler {
//     constructor() {
//     }
//
//     handleError(error) {
//         const eventId = Sentry.captureException(error.originalError || error);
//         // Sentry.showReportDialog({eventId});
//     }
// }
export function transitionAnimation(foo, el, opts) {
    return Promise.resolve(mdTransitionAnimation(el, opts));
}
export function getLocationStrategy(_platformLocation, href) {
    if (navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1) {
        return new MyLocationStrategy(_platformLocation, href);
    }
    else {
        return new HashLocationStrategy(_platformLocation, href);
    }
}
var ɵ0 = createTranslateLoader;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
