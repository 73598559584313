var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import { Store, ModifierBuilder } from 'aigens-ng-core';
import { Item } from 'aigens-ng-core';
import { MobileBasePage } from '../../core/base/mobile-base-page';
import { NavParams } from '@ionic/angular';
var ItemFilterDialog = /** @class */ (function (_super) {
    __extends(ItemFilterDialog, _super);
    function ItemFilterDialog(injector, navParams) {
        var _this = _super.call(this, injector) || this;
        _this.injector = injector;
        _this.navParams = navParams;
        _this.filters = [];
        _this.selectedFilterTags = [];
        // levelSetTag = {};
        _this.selectedCount = 0;
        _this.filterCount = 0;
        _this.confirm = false;
        // mmap = {};
        // tagTree = {};
        // currentTree = {};
        _this.absentItemsId = [];
        _this.currentIndex = 0;
        _this.itemsCount = {};
        _this.tags = {};
        _this.store = _this.navParams.get('store');
        _this.group = _this.navParams.get('group');
        _this.itemMap = _this.navParams.get('itemMap');
        _this.item = _this.navParams.get('item');
        if (!_this.store) {
            _this.store = _this.orderManager.store;
        }
        console.log(_this.item);
        _this.init();
        return _this;
    }
    ItemFilterDialog.prototype.init = function () {
        var _this = this;
        console.log('group', this.group);
        this.originalItems = [this.item].concat(this.item.variations);
        this.items = [this.item].concat(this.item.variations);
        if (this.group && (this.group['filters'] || this.group['filters2'])) {
            this.filters = [];
            if (this.group['filters'] && this.group['filters'].length > 0) {
                var filter = {
                    question: this.group['filter'],
                    tags: this.group['filters']
                };
                this.filters.push(filter);
            }
            if (this.group['filters2'] && this.group['filters2'].length > 0) {
                var filter = {
                    question: this.group['filter2'],
                    tags: this.group['filters2']
                };
                this.filters.push(filter);
            }
        }
        console.log('filters:', this.filters);
        // this.selectFilter(0);
        if (this.filters.length == 0) {
            this.selectedFilterTags = [];
        }
        else {
            //add Badges
            if (this.store.menu && this.store.menu.badges) {
                var badges = this.store.menu.badges;
                console.log(badges);
                badges.forEach(function (badge) {
                    _this.tags[badge.tag] = badge;
                });
            }
        }
    };
    ItemFilterDialog.prototype.isFilterSelected = function (filter) {
        var _this = this;
        if (!this.selectedFilterTags)
            return false;
        return filter.tags.some(function (t) {
            var i = _this.selectedFilterTags.indexOf(t);
            return (i >= 0);
        });
    };
    ItemFilterDialog.prototype.selectTag = function (tag, filter) {
        var _this = this;
        if (!this.isAvailableTag(tag))
            return;
        console.log('filter:', tag, filter);
        if (!this.selectedFilterTags) {
            this.items = [];
            this.selectedFilterTags = [];
        }
        var index = this.selectedFilterTags.indexOf(tag);
        //deselect
        if (index >= 0) {
            this.selectedFilterTags.splice(index, 1);
        }
        else { //select
            filter.tags.forEach(function (t) {
                var i = _this.selectedFilterTags.indexOf(t);
                if (i >= 0)
                    _this.selectedFilterTags.splice(i, 1);
            });
            this.selectedFilterTags.push(tag);
        }
        if (this.selectedFilterTags.length == 0) {
            this.selectedFilterTags = null;
            this.items = [];
            return;
        }
        var result = this.originalItems;
        var _loop_1 = function (tag_1) {
            result = result.filter(function (item) { return item.filters && item.filters.indexOf(tag_1) >= 0; });
        };
        for (var _i = 0, _a = this.selectedFilterTags; _i < _a.length; _i++) {
            var tag_1 = _a[_i];
            _loop_1(tag_1);
        }
        this.items = result;
        console.log('filtered item', this.items);
        // if(this.currentIndex==0&&this.filters.length>1){
        //     this.selectFilter(1);
        // }
        if (this.items.length == 1) {
            this.showConfirm();
        }
    };
    ItemFilterDialog.prototype.showConfirm = function () {
        if (!this.selectedFilterTags)
            return false;
        if (this.selectedFilterTags.length == this.filters.length) {
            this.filteredItem = this.items[0];
            return true;
        }
    };
    // selectFilter(index: number) {
    //     this.selectedIndex = index
    //     this.selectedFilter = this.filters[index];
    //     this.currentIndex = index;
    //     this.scrollToX(this.currentIndex);
    // }
    ItemFilterDialog.prototype.isAvailableTag = function (tag) {
        //check if any selectable item with specific tag and with filtering tags in other groups
        if (this.selectedFilterTags && this.selectedFilterTags.indexOf(tag) >= 0)
            return true;
        var availableItems = this.originalItems.filter(function (item) {
            if (item.filters) {
                return item.filters.indexOf(tag) >= 0;
            }
        });
        if (this.selectedFilterTags && availableItems && availableItems.length > 0) {
            var _loop_2 = function (filteredTag) {
                if (!this_1.ifInSameFilterGroup(filteredTag, tag)) {
                    availableItems = availableItems.filter(function (item) {
                        return item.filters.indexOf(filteredTag) >= 0;
                    });
                    if (!availableItems || availableItems.length == 0)
                        return "break";
                }
            };
            var this_1 = this;
            for (var _i = 0, _a = this.selectedFilterTags; _i < _a.length; _i++) {
                var filteredTag = _a[_i];
                var state_1 = _loop_2(filteredTag);
                if (state_1 === "break")
                    break;
            }
        }
        return availableItems && availableItems.length > 0;
    };
    ItemFilterDialog.prototype.isSelectedTag = function (tag) {
        if (!this.selectedFilterTags)
            return false;
        if (this.selectedFilterTags.indexOf(tag) >= 0)
            return true;
        return false;
    };
    ItemFilterDialog.prototype.ifInSameFilterGroup = function (tag1, tag2) {
        for (var _i = 0, _a = this.filters; _i < _a.length; _i++) {
            var filter = _a[_i];
            if (filter.tags.indexOf(tag1) >= 0 && filter.tags.indexOf(tag2) >= 0)
                return true;
        }
        return false;
    };
    ItemFilterDialog.prototype.ionViewDidLoad = function () {
        console.log('ionViewDidLoad ItemFilterPage');
        // 100: offset top ; 64: padding-bottom offset ; 12: margin
        // this.contentContainerHeight = screen.height - document.getElementById('content-top').offsetTop - 236;
        // this.contentContainerHeight = this.filterCount * 188 +64 ;
    };
    ItemFilterDialog.prototype.isActiveTag = function (tag) {
        return this.selectedFilterTags && this.selectedFilterTags.indexOf(tag) >= 0;
    };
    ItemFilterDialog.prototype.confirmClicked = function () {
        if (this.isSoldout(this.filteredItem)) {
            this.showAlert('Soldout', this.filteredItem.name);
            return;
        }
        this.modalController.dismiss(this.filteredItem);
    };
    ItemFilterDialog.prototype.dismissClicked = function () {
        this.modalController.dismiss(null);
    };
    return ItemFilterDialog;
}(MobileBasePage));
export { ItemFilterDialog };
