<div (click)="dismiss()" [ngStyle]="{'height': '100vh'}">
  <div class="filter-wrapper fix-bottom widthMax" (click)="$event.stopPropagation();">

    <div class="list-header-wrapper">
      <div class="list-header">
        <ion-button fill="clear" (click)="dismiss()">
          <!-- <ion-icon slot="icon-only" name="md-close" color="dark"></ion-icon> -->
          <ion-icon name="ios-arrow-down" color="dark"></ion-icon>
        </ion-button>
      </div>
    </div>
    <div class="contact-wrapper">
      <ng-container *ngFor="let group of filterGroups">
        <ion-list-header class="type-label">
          <ion-label >{{group.name}}</ion-label>
        </ion-list-header>
        <ion-list class="type-list">

          <ion-item *ngFor="let sub of group['subs'];let i = index;">
            <ion-label class="filter-name">{{sub.name}}</ion-label>
            <ion-checkbox (ionChange)="addKeywords($event,sub)" [value]="sub.name" [checked]="sub.checked" slot="end" class="filter-check">
            </ion-checkbox>
          </ion-item>
        </ion-list>
      </ng-container>
    </div>
  </div>
</div>