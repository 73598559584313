import { Routes } from '@angular/router';
import { PaymentGuard } from './core/guards/payment.guard';
var ɵ0 = { preload: true }, ɵ1 = { isIsolate: true }, ɵ2 = { isIsolate: true }, ɵ3 = { preload: true }, ɵ4 = { preload: true }, ɵ5 = { preload: true }, ɵ6 = { preload: true };
/**
 *  conclusion: use loadChildren for Lazy Load Module, use component for Module Load when init?
 *  if use component, must include the page in declarations in app.module.ts
 *
 *
 * */
var routes = [
    // Deprecated
    {
        path: 'code/:code',
        loadChildren: './pages/code/code-page.module#CodePageModule'
    },
    // Deprecated End
    {
        path: 'brand/:brandId/status/:status',
        loadChildren: './routes/summary/order-status/order-status.module#OrderStatusModule'
    },
    {
        path: 'court-store-list/:courtId/status/:status',
        loadChildren: './routes/summary/order-status/order-status.module#OrderStatusModule'
    },
    {
        path: 'store/:storeId/spot/:spot/key/:key/mode/:mode/session/:session/status/:status',
        loadChildren: './routes/summary/order-status/order-status.module#OrderStatusModule'
    },
    {
        path: 'store/:storeId/spot/:spot/key/:key/mode/:mode/status/:status',
        loadChildren: './routes/summary/order-status/order-status.module#OrderStatusModule'
    },
    {
        path: 'directory/brand/:brandId',
        loadChildren: './pages/store-list/store-list-page.module#StoreListModule',
    },
    {
        path: 'directory/group/:groupId',
        loadChildren: './pages/store-list/store-list-page.module#StoreListModule',
    },
    {
        path: 'court/:courtId/status/:status',
        loadChildren: './routes/summary/order-status/order-status.module#OrderStatusModule',
    },
    {
        path: 'court/:courtId',
        loadChildren: './pages/byod-brand-splash/byod-brand-splash.module#ByodBrandSplashModule',
        data: ɵ0
        // loadChildren: './pages/brand-store-list/brand-store-list.module#BrandStoreListModule',
    },
    {
        path: 'court/:courtId/order-status',
        loadChildren: './routes/summary/order-status/order-status.module#OrderStatusModule',
    },
    {
        path: 'store/:storeId/spot/:spot/key/:key/status/:status',
        loadChildren: './routes/summary/order-status/order-status.module#OrderStatusModule'
    },
    {
        path: 'store/:storeId/status/:status',
        loadChildren: './routes/summary/order-status/order-status.module#OrderStatusModule'
    },
    {
        path: 'order-status/:status',
        loadChildren: './routes/summary/order-status/order-status.module#OrderStatusModule'
    },
    {
        path: 'order-status',
        loadChildren: './routes/summary/order-status/order-status.module#OrderStatusModule'
    },
    {
        path: 'store/:storeId/mode/:mode/status/:status/reDirectOrderId/:payMethod',
        loadChildren: './routes/summary/order-status/order-status.module#OrderStatusModule'
    },
    {
        path: 'store/:storeId/mode/:mode/status/:status',
        loadChildren: './routes/summary/order-status/order-status.module#OrderStatusModule'
    },
    {
        path: 'store/:storeId/mode/:mode/status/:status/reDirectOrderId/:payMethod',
        loadChildren: './routes/summary/order-status/order-status.module#OrderStatusModule'
    },
    {
        path: 'store/:storeId/mode/:mode/session/:session/status/:status',
        loadChildren: './routes/summary/order-status/order-status.module#OrderStatusModule'
    },
    {
        path: 'brand/:brandId/membership',
        loadChildren: './routes/member/login/login.module#LoginModule'
    },
    {
        path: 'brand/:brandId',
        loadChildren: './pages/byod-brand-splash/byod-brand-splash.module#ByodBrandSplashModule'
    },
    {
        path: 'history/:sessionId',
        data: ɵ1,
        loadChildren: './routes/summary/byod-summary/byod-summary.module#ByodSummaryModule'
    },
    {
        path: 'order/:orderId',
        data: ɵ2,
        loadChildren: './routes/summary/byod-summary/byod-summary.module#ByodSummaryModule'
    },
    {
        path: 'byod-summary',
        loadChildren: './routes/summary/byod-summary/byod-summary.module#ByodSummaryModule'
    },
    {
        path: 'storeId/:storeId/type/:type/session/:sessionId',
        loadChildren: './pages/byod-splash/byod-splash.module#ByodSplashModule',
        data: ɵ3
    }, {
        path: 'store/:store/spot/:spot/key/:key/mode/:mode',
        loadChildren: './pages/byod-splash/byod-splash.module#ByodSplashModule',
        data: ɵ4
    }, {
        path: 'store/:store/spot/:spot/key/:key',
        loadChildren: './pages/byod-splash/byod-splash.module#ByodSplashModule',
        data: ɵ5
    }, {
        path: 'store/:store/spot/:spot',
        loadChildren: './pages/byod-splash/byod-splash.module#ByodSplashModule',
        data: ɵ6
    },
    // {
    //     path: 'store/:store',
    //     loadChildren: './pages/byod-splash/byod-splash.module#ByodSplashModule',
    //     data: { preload: true }
    // },
    {
        path: 'bkHistory/:sessionId',
        loadChildren: './routes/summary/byod-summary/byod-summary.module#ByodSummaryModule'
    },
    {
        path: 'bk',
        loadChildren: './pages/delivery-information/delivery-information.module#DeliveryInformationModule'
    },
    {
        path: 'bk/:sessionId',
        loadChildren: './pages/delivery-information/delivery-information.module#DeliveryInformationModule'
    },
    /**
     *  for MGPS 3D Secure Verification
     * */
    {
        path: 'redirect',
        loadChildren: './pages/external-web-close/external-web-close.module#ExternalWebCloseModule'
    },
    {
        path: 'close/:status',
        loadChildren: './pages/close/close.module#CloseModule'
    },
    {
        path: 'home#/close/:status',
        redirectTo: 'close/:status'
    },
    /**
     * Routes below are verified ok
     * */
    {
        path: 'court-store-list/:courtId',
        loadChildren: './pages/brand-store-list/brand-store-list.module#BrandStoreListModule',
    },
    {
        path: 'store/:store/mode/:mode',
        loadChildren: './pages/byod-splash/byod-splash.module#ByodSplashModule',
    },
    {
        path: 'test',
        // canActivate: [HomeGuardGuard],
        loadChildren: './pages/test/test.module#TestPageModule'
    },
    {
        path: 'limited-access',
        loadChildren: './pages/limited-access/limited-access.module#LimitedAccessPageModule'
    },
    {
        path: 'store/:storeId/spot/:spot/wirecard',
        canActivate: [PaymentGuard],
        loadChildren: './routes/cart/payment/wirecard-credit-card/wirecard-credit-card.module#WirecardCreditCardModule'
    },
    {
        path: 'store/:storeId/spot/:spot/enets',
        canActivate: [PaymentGuard],
        loadChildren: './routes/cart/payment/enets-payment/enets-payment.module#EnetsPaymentModule'
    },
    {
        path: '',
        redirectTo: 'test',
        pathMatch: 'full'
    }
    // {path: 'blank', loadChildren: './blank/blank.module#BlankPageModule'},
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
