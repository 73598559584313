import * as i0 from "@angular/core";
var BaseService = /** @class */ (function () {
    function BaseService() {
    }
    BaseService.prototype.getOrderSesisonUrl = function (storeId, mode, fields) {
        if (fields === void 0) { fields = 'membership'; }
        var url = '/api/v1/menu/session.json?';
        url = url + 'storeId=' + storeId + '&mode=' + mode + '&fields=' + fields;
        return url;
    };
    BaseService.prototype.getQueryVariable = function (variable) {
        var query = window.location.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (pair[0] === variable) {
                return pair[1];
            }
        }
        return (false);
    };
    BaseService.ngInjectableDef = i0.defineInjectable({ factory: function BaseService_Factory() { return new BaseService(); }, token: BaseService, providedIn: "root" });
    return BaseService;
}());
export { BaseService };
