var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnInit } from '@angular/core';
import { MobileBasePage } from '../../core/base/mobile-base-page';
import { NavParams } from '@ionic/angular';
import { isAvailableOffer } from '../../shared/utilities/checkFormatTool';
var OfferListDialog = /** @class */ (function (_super) {
    __extends(OfferListDialog, _super);
    function OfferListDialog(injector, navParams) {
        var _this = _super.call(this, injector) || this;
        _this.injector = injector;
        _this.navParams = navParams;
        _this.offers = [];
        _this.isBounce = false;
        _this.offer_map_promotion = {};
        _this.offers = _this.navParams.get('offers');
        return _this;
    }
    OfferListDialog.prototype.ngOnInit = function () {
        console.log('ngOnInit VariationPage');
        this.initOffer_map_promotion();
    };
    // offer(some logic copy form ng-core)
    OfferListDialog.prototype.initOffer_map_promotion = function () {
        var _this = this;
        this.offers.map(function (offer) {
            var ok = true;
            if (!offer) {
                ok = false;
            }
            else {
                // 复制一个新 order
                var order = JSON.parse(JSON.stringify(_this.orderManager.order));
                order.offers = [];
                order.offers.push(offer);
                // 使用当前offer 组建新的order 通过ng-core 判断是否满足offer 对应的discount的所有conditions
                // let promo = this.findPromotion(offer);
                // offer.reward && offer.reward.discount && offer.reward.discount.conditions.forEach(c => {
                //     if (!c.isSatisfied(order, promo)) ok = false;
                // });
                ok = offer.reward && offer.reward.isReawardSatisfied(order); //"d21829bc-3a9e-4db8-ac31-da2446e602fa"
            }
            _this.offer_map_promotion[offer.id] = ok;
        });
    };
    OfferListDialog.prototype.dismissClicked = function () {
        this.modalController.dismiss(null);
    };
    OfferListDialog.prototype.bounce = function () {
        var _this = this;
        this.isBounce = true;
        setTimeout(function () {
            _this.isBounce = false;
        }, 1000);
    };
    OfferListDialog.prototype.isAvailableReward = function (offer) {
        var isMeeting = this.offerIsMeetingConditions(offer);
        return isAvailableOffer(offer) && isMeeting;
    };
    OfferListDialog.prototype.offerIsMeetingConditions = function (offer) {
        return offer && offer.id && this.offer_map_promotion[offer.id];
    };
    // findPromotion(offer) {
    //     let offer_map_promotion = this.orderManager.order && this.orderManager.order.promotions && this.orderManager.order.promotions.filter(pro => offer && offer.reward && offer.reward.discountId === pro.id)[0];
    //     return offer_map_promotion;
    // }
    OfferListDialog.prototype.clickReward = function (offer) {
        if (this.isAvailableReward(offer)) {
            this.modalController.dismiss(offer);
            this.bounce();
        }
    };
    return OfferListDialog;
}(MobileBasePage));
export { OfferListDialog };
