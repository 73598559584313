var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Address } from 'aigens-ng-core';
import { ConfigService } from './config.service';
import { AQuery } from '../base/aquery';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BaseService } from '../base/base-service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./config.service";
var OrderSessionService = /** @class */ (function (_super) {
    __extends(OrderSessionService, _super);
    function OrderSessionService(http, configs) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.configs = configs;
        _this.aq = new AQuery(http, configs);
        _this.addressArr = [];
        return _this;
    }
    /***ABOUT getOrderSesison——API https://docs.google.com/document/d/1MW6fddUSM2SXjeXBo395wK3tCPvTllU0uZEYpPvjDLA/edit** */
    OrderSessionService.prototype.getOrderSession = function (storeId, mode, fields, spot, session) {
        if (mode === void 0) { mode = 'byod'; }
        if (fields === void 0) { fields = []; }
        if (fields.length === 0)
            return;
        var fv;
        var url = '/api/v1/menu/session.json?';
        fields.map(function (str) {
            fv = fv ? fv + ',' + str : str;
        });
        var params = {
            storeId: storeId, mode: mode, fields: fv, spot: spot, session: session
        };
        for (var name_1 in params) {
            if (!params[name_1])
                delete params[name_1];
        }
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.params = params;
        aq.auth(true);
        return aq.getJson().pipe(map(function (jo) {
            return jo['data'];
        }));
    };
    OrderSessionService.prototype.getLocalOrderSession = function () {
        return this.configs.getLocal('orderSession');
    };
    OrderSessionService.prototype.getLocalAddresses = function (storesList) {
        var orderSession = this.getLocalOrderSession();
        var addressArrOfStoreList = this.configs.getLocal('addressArrOfStoreList');
        var addresses;
        if (storesList) {
            // store list cant call session.json to get addresses so save the addresses in local'addressArrOfStoreList' 
            if (addressArrOfStoreList) {
                return addressArrOfStoreList || [];
            }
        }
        else {
            // 有 orderSession就读 orderSession里的地址
            if (orderSession && orderSession.member && orderSession.member.addresses) {
                addresses = orderSession.member.addresses;
            }
            else {
                return addressArrOfStoreList || [];
            }
        }
        return addresses || [];
    };
    OrderSessionService.prototype.setLocalAddress = function (address) {
        var orderSession = this.getLocalOrderSession();
        if (orderSession && orderSession.member) {
            orderSession.member.addresses = address;
            this.configs.setLocal('orderSession', orderSession);
        }
    };
    OrderSessionService.prototype.getLocalMembership = function () {
        var orderSession = this.getLocalOrderSession();
        var membership;
        if (orderSession && orderSession.member) {
            membership = orderSession.membership;
        }
        return membership;
    };
    OrderSessionService.prototype.setLocalMembershipOffers = function (offers) {
        var orderSession = this.getLocalOrderSession();
        if (offers && orderSession && orderSession.membership) {
            orderSession.membership.offers = offers;
            this.configs.setLocal('orderSession', orderSession);
            return true;
        }
        return false;
    };
    OrderSessionService.prototype.clearLocalSession = function () {
        this.configs.setLocal('orderSession', null);
    };
    OrderSessionService.ngInjectableDef = i0.defineInjectable({ factory: function OrderSessionService_Factory() { return new OrderSessionService(i0.inject(i1.HttpClient), i0.inject(i2.ConfigService)); }, token: OrderSessionService, providedIn: "root" });
    return OrderSessionService;
}(BaseService));
export { OrderSessionService };
