var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SdkBaseService } from './sdk-base-service';
import * as i0 from "@angular/core";
;
;
var SdkWeChatPayService = /** @class */ (function (_super) {
    __extends(SdkWeChatPayService, _super);
    function SdkWeChatPayService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SdkWeChatPayService.prototype.registerApp = function (params, callback) {
        if (!this.isAvailable()) {
            return;
        }
        this.callNative('registerApp', params, callback);
    };
    SdkWeChatPayService.prototype.requestPay = function (params, callback) {
        if (!this.isAvailable()) {
            return;
        }
        this.callNative('requestPay', params, callback);
    };
    SdkWeChatPayService.prototype.getWeChatSdkVersion = function (params, callback) {
        if (!this.isAvailable()) {
            return;
        }
        this.callNative("getWeChatSdkVersion", params, callback);
    };
    SdkWeChatPayService.prototype.getServiceName = function () {
        return 'WeChatPayService';
    };
    SdkWeChatPayService.ngInjectableDef = i0.defineInjectable({ factory: function SdkWeChatPayService_Factory() { return new SdkWeChatPayService(); }, token: SdkWeChatPayService, providedIn: "root" });
    return SdkWeChatPayService;
}(SdkBaseService));
export { SdkWeChatPayService };
